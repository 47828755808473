import { render, staticRenderFns } from "./Sim.vue?vue&type=template&id=687d379a&scoped=true&"
import script from "./Sim.vue?vue&type=script&lang=js&"
export * from "./Sim.vue?vue&type=script&lang=js&"
import style0 from "./Sim.vue?vue&type=style&index=0&id=687d379a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "687d379a",
  null
  
)

export default component.exports