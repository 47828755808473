<template>
  <span>{{ formattedDate }}</span>
</template>

<script>

import * as moment from "moment";

export default {
  name: "CurrentTimeComponent",
  mixins: [],
  components: {},
  props: {
    exerciseDate: {
      type: String,
      // required: true
      default: "2020-05-25 13:55:00",
    },
    exercise: {
      type: Object,
      required: true
    },
    sendTime: {
      type: Number,
      default: 0
    }
  },
  data: function() {
    return {};
  },
  computed: {
    formattedDate: function() {
      const that = this;
      return moment(that.exerciseDate)
        .add(that.sendTime, "seconds")
        .format("LLLL");
    },
  },
};
</script>
