<template>
    <BaseSimWidget id="scribeWidget">
        <template v-slot:title>
            <i class="fas fa-pen-fancy"></i>
            Scribe
        </template>

        <b-tabs v-model="tabIndex" id="" align="right" pills>
            <b-tab>
                <template #title>
                    <i class="fas fa-list"></i>
                </template>

                <br>
                <ScribeForm :exercise="exercise" />

                <hr>

                <ScribeList :exercise="exercise" />
            </b-tab>
            <b-tab>
                <template #title>
                    <i class="fas fa-question"></i>
                </template>

                <p>Help will be here.</p>
            </b-tab>
        </b-tabs>
    </BaseSimWidget>
</template>

<script>
// import {
//     HTTP
// } from '@/util/http-common';
// import HandleErrors from '@/util/handle-errors';

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "ScribeSimWidget",
    mixins: [WidgetMixin],
    props: {
        exercise: {
            type: Object,
            required: true,
            default: Object,
        },
        fullScreen: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        return {
            show: true,
            fullWidth: false,
            title: "Scribe",
            tabIndex: 0
        };
    },
    computed: {},
    created: function () {},
    mounted: function () {},
    methods: {},
};
</script>
