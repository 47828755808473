<template>
    <div>
        <Inject
            v-for="item in itemList"
            :key="item._id"
            :input="item"
            :exercise="exercise"
            v-on:removefromexercise="$emit('removefromexercise', $event)"
            v-on:editinexercise="$emit('editinexercise', $event)"
        />
    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "InjectList",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            socketEventName: ""
        };
    },
    computed: {},
    created: function () {},
    mounted: function () {
        const that = this;

        that.find();

        that.socketIOSubscribe({
            name: `${that.exercise._id}-inject-list`
        });

        // that.subscribe(`${that.exercise._id}-inject-remove`, function (data) {
        //     that.find();
        // });
    },
    methods: {
        find: async function () {
            const that = this;
            that.itemList =  await that.$http.get(`/inject?exercise=${that.exercise._id}`).then((response) => response.data);
        },
    },
    // beforeRouteLeave(to, from, next) {
    //     // this.sockets.unsubscribe(this.socketEventName);
    //     // this.sockets.unsubscribe(`${this.exercise._id}-inject-remove`);
    // },
};
</script>
