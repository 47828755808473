<template>
    <div class="row">
        <div class="col" v-if="exercise._id && id && copy">
            <b-button block variant="outline-primary" type="submit">
                Add to Exercise
                <!-- Update -->
            </b-button>
        </div>

        <div class="col" v-if="(id && !copy)">
            <b-button block variant="outline-primary" type="submit">
                Save
            </b-button>
        </div>

        <div class="col" v-if="(!exercise._id && id && copy)">
            <b-button block variant="outline-primary" type="submit">
                Save New
            </b-button>
        </div>

        <div class="col" v-if="(!id && !copy)">
            <b-button block variant="outline-primary" type="submit">
                Create
            </b-button>
        </div>

        <div class="col" v-if="exercise._id || id">
            <b-button block variant="outline-warning" type="reset">
                Cancel
            </b-button>
        </div>

        <div class="col" v-if="!exercise._id && !id">
            <b-button block variant="outline-warning" type="reset">
                Clear
            </b-button>
        </div>
    </div>
</template>

<script>

export default {
    name: "FormButtonOptionsComponent",
    mixins: [],
    components: {},
    props: {
        id: {
            type: String,
            required: false,
            default: null
        },
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
        copy: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        return {};
    },
    computed: {},
    created: function () {},
    mounted: function () {},
    methods: {},
};
</script>
