<template>
  <b-card class="my-2 tw-border">
    <b-card-text class="text-right" v-if="chat.role == $store.state.sim.role.role && chat.status == 'open'">
      <b-button @click="end()"> End Conversation </b-button>
      <hr />
    </b-card-text>
    <b-card-text class="small text-muted">
      <b-badge class="tw-p-4 tw-mr-1" v-for="role in chat.roles" :key="role">
        <RoleInfo :role-id="role" />
      </b-badge>
    </b-card-text>
    <b-card-text>
      <ChatEntryForm
        v-if="chat.status == 'open'"
        :exercise="exercise"
        :chat="chat"
      />
      <ChatEntryList
        :chat="chat"
        :chat-entries="chatEntries"
        :exercise="exercise"
      />
    </b-card-text>
  </b-card>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "Chat",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: true,
      default: Object,
    },
    chat: {
      type: Object,
      required: true,
    },
    chatEntries: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    // sortedItems() {
    //   const that = this;
    //   return _.sortBy(that.items, [
    //     function(o) {
    //       return -o.sendTime;
    //     },
    //   ]);
    // },
  },
  watch: {
    id: function (val) {
      if (val) {
        this.init();
      }
    },
  },
  created: function () {
    this.init();
  },
  mounted: function () {},
  methods: {
    init() {},
    canShow() {
      return 1;
    },
    async end() {
      const that = this;

      await that.$http.patch(`/chat/${that.chat._id}`, {status: 'closed', actionTime: that.exercise.currentTime});
    }
  },
};
</script>
