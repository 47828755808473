<template>
  <BaseSimWidget :id="`${type}Widget`">
    <template v-slot:title>
      <i class="fas fa-tv" v-if="type == 'broadcast'"></i>
      <i class="fas fa-hashtag" v-else></i>
      {{ type == 'broadcast' ? 'Broadcast' : 'Social Media'}}
    </template>

    <b-tabs v-model="tabIndex" id="" align="right" pills v-if="type in widgets">
      <template #tabs-end v-if="!fullScreen">
        <!-- <b-nav-item>
          <i class="fas fa-chevron-down"></i>
        </b-nav-item> -->
        <b-nav-item>
          <i class="fas fa-expand-arrows-alt" @click.prevent="goFullScreen()"></i>
        </b-nav-item>
      </template>

      <b-tab :disabled="!isExerciseController() && !hasPermission(widgets[type].slug) && !hasPermission(widgets['social-media'].slug)">
          <template #title>
            <i class="fas fa-briefcase"></i>
          </template>

          <BroadcastLibrary
            :type="type"
            :exercise="exercise"
            v-on:editinexercise="editInExercise($event)"
            v-on:selectbroadcast="selectBroadcast($event)"
            v-on:addtoexercise="addItemToExercise($event)"
        />
      </b-tab>

      <b-tab>
          <template #title>
            <i class="fas fa-inbox"></i>
          </template>

          <BroadcastList
            inbox="in"
            :type="type"
            :exercise="exercise"
            v-on:editinexercise="editInExercise($event)"
            v-on:selectbroadcast="selectBroadcast($event)"
        />
      </b-tab>
      <b-tab :disabled="!isExerciseController() && !hasPermission(widgets[type].slug) && !hasPermission(widgets['social-media'].slug)">
          <template #title>
            <i class="fas fa-paper-plane"></i>
          </template>

          <BroadcastList
            inbox="out"
            :type="type"
            :exercise="exercise"
            v-on:editinexercise="editInExercise($event)"
            v-on:selectbroadcast="selectBroadcast($event)"
        />
      </b-tab>
      <b-tab :disabled="!isExerciseController() && !hasPermission(widgets[type].slug) && !hasPermission(widgets['social-media'].slug)">
          <template #title>
            <i class="fas fa-plus"></i>
          </template>

          <BroadcastForm
            :exercise="exercise"
            :type="type"
            :copy="copy"
            :id="id"
            v-on:form="formFinished($event)"
        />
      </b-tab>

      <b-tab>
          <template #title>
            <i class="fas fa-question"></i>
          </template>

          <p>Help will be here.</p>
      </b-tab>

    </b-tabs>
    
  </BaseSimWidget>
</template>

<script>
import {
    HTTP
} from '@/util/http-common';
import HandleErrors from '@/util/handle-errors';

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "BroadcastSimWidget",
  mixins: [WidgetMixin],
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object
    },
    fullScreen: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'broadcast'
    }
  },
  data: function() {
    return {
      show: true,
      fullWidth: false,
      title: "Sample Page",
      addBroadcast: false,
      newBroadcast: false,
      id: null,
      copy: false,
      tabIndex: 1
    };
  },
  computed: {
  },
  created: function() {},
  mounted: function() {},
  methods: {
    addItemToExercise: function (item) {
      this.id = item._id;
      this.copy = true;
      this.tabIndex = 3;
    },
    formFinished: function() {
        this.id = null;
        this.copy = false;
        this.tabIndex = 2;
    },
    editInExercise: function(item) {
        this.id = item._id;
        this.copy = false;
        this.tabIndex = 3;
    },
    goFullScreen() {
      this.$emit('setfullscreenmodalwidget', 'BroadcastSimWidget');
    }
  }
};
</script>
