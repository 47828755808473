<template>
    <h4>
        <b-progress :value="currentTime" :max="maxTime" show-progress :variant="variant" height="1.5rem"></b-progress>
    </h4>
</template>

<script>
import * as moment from "moment";

import SocketIOMixin from '@/util/socketio';
export default {
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: true
        },
        currentTime: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            maxTime: 100,
            variant: 'secondary'
        }
    },
    created() {
        this.recalculate();
    },
    watch: {
        currentTime() {
            this.recalculate();
        }
    },
    methods: {
        recalculate() {
            const that = this;

            if(that.exercise.startSimDate && that.exercise.endSimDate) {
                that.maxTime = moment(that.exercise.endSimDate).diff(that.exercise.startSimDate, 'seconds', true);

                let percent = (that.currentTime / that.maxTime) * 100;
                if(percent >= 85) {
                    that.variant = 'warning';
                } else if(percent > 100) {
                    that.variant = 'danger';
                } else {
                    that.variant = 'secondary';
                }
            }
        }
    }
};
</script>
