<template>
    <div>
        <!-- <FormAction :id="id" :copy="form._id ? true : false" /> -->
        <b-form @submit="onSubmit" @reset="onReset">
            <b-form-group
                id="value-group"
                label="Value:"
                label-for="value"
                v-if="['percent', 'count-up', 'count-down', 'out-of', 'used/capacity'].indexOf(statusBoard.type) >= 0"
            >
                <b-form-input
                    id="value"
                    v-model="form.value"
                    required
                    placeholder="Enter Value"
                ></b-form-input>
            </b-form-group>

            <b-form-group
                id="capacity-group"
                label="Capacity:"
                label-for="capacity"
                v-if="['out-of', 'used/capacity'].indexOf(statusBoard.type) >= 0"
            >
                <b-form-input
                    id="capacity"
                    v-model="form.capacity"
                    required
                    placeholder="Enter Capacity"
                ></b-form-input>
            </b-form-group>

            <b-form-group
                id="comment-group"
                label="Comment:"
                label-for="comment"
            >
                <b-form-textarea
                    id="comment"
                    v-model="form.comment"
                    placeholder="Enter Role Comment"
                    rows="6"
                ></b-form-textarea>
            </b-form-group>

            <FormButtonOptions :exercise="exercise" :id="form._id" :copy="form._id ? true : false" />
        </b-form>
    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import * as _ from "lodash";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "StatusBoardCountForm",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
        statusBoard: {
            type: Object,
            required: true
        },
        item: {
            type: Object,
            required: false,
            default: Object,
        }
    },
    data: function () {
        return {
            form: {
                
            }
        };
    },
    created: function () {
        this.init();
    },
    mounted: function () {},
    methods: {
        async init() {
            this.form = _.extend({}, this.item);
        },
        onSubmit: async function (evt) {
            evt.preventDefault();

            const that = this;
            const data = _.clone(that.form);

            data.exercise = that.exercise._id;
            data.statusBoard = that.statusBoard._id;

            if(data._id) {
                await that.$http.patch(`/status-board-status/${data._id}`, {
                    status: 'deleted',
                    actionTime: that.exercise.currentTime
                });
            }

            data._id = undefined;
            data.sendTime = that.exercise.currentTime;
            await that.$http.post(`/status-board-status`, data);

            that.$emit('donestatus', {});
        },
        onReset: function (evt) {
            evt.preventDefault();
            this.$emit('donestatus', {});
        },
    },
};
</script>
