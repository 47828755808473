<template>
  <div class="tw-h-full tw-w-full tw-mt-3">
    <b-container fluid>
      <b-row>
        <b-col>
          <h2>{{ widget.title }}</h2>
        </b-col>
        <b-col class="text-right" cols="auto">
          <!-- <b-button
            :to="{
              name: 'library-form-create',
              params: { widget: widget.slug, form: 'create' },
            }"
            variant="outline-primary"
            class="px-5"
          >
            Create
          </b-button> -->

          <b-button
                v-if="isDebugVisible()"
                variant="danger"
                outline
                @click="words()"
                class="tw-my-1"
                title="Words"
            >
                <i class="fas fa-file-word"></i>
            </b-button>

            <b-button
                v-if="isDebugVisible()"
                variant="danger"
                outline
                @click="paragraph()"
                class="tw-my-1"
                title="Paragraph"
            >
                <i class="fas fa-paragraph"></i>
            </b-button>

            <b-button
                v-if="isDebugVisible()"
                variant="danger"
                outline
                @click="paragraphs()"
                class="tw-my-1"
                title="Paragraphs"
            >
                <i class="fas fa-align-left"></i>
            </b-button>

          <b-button
            @click="showForm({})"
            variant="outline-primary"
            class="px-5"
          >
            Create
          </b-button>

        </b-col>
      </b-row>
    </b-container>
    <hr />

    <b-container fluid>
      <b-row no-gutters>
        <b-col>
          <component
            :is="listComponent"
            v-on:details="showDetails($event)"
            v-on:edit="showForm($event, false)"
            v-on:duplicate="showForm($event, true)"
        ></component>
        </b-col>
        <b-col class="p-2">
          <component
            v-if="form && formComponent"
            :id="id"
            :copy="duplicate"
            :is="formComponent"
            v-on:form="hideForm($event)"
          ></component>
          <!-- <hr /> -->
          <component
            v-if="id && detailComponent"
            :id="id"
            :is="detailComponent"
            view="details"
          ></component>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { HTTP } from "@/util/http-common.js";
import HandleErrors from "@/util/handle-errors";

import * as faker from 'faker';

export default {
  mixins: [],
  components: {},
  data: function() {
    return {
      exercise: null,
      id: null,
      form: false,
      copy: false,
      duplicate: false,
      listComponent: null,
      detailComponent: null,
      formComponent: null,
      widget: null,
      faker: null
    };
  },
  created() {
    this.init(this.$route);
    this.faker = faker;
  },
  mounted() {},
  methods: {
    init(route) {
      this.widget = _.find(this.widgets, (widget) => {return widget.slug == route.params.widget});
      this.listComponent = `${this.widget.base}Library`;
      this.hideForm({});
      this.hideDetails();


    //   this.id = route.params.id;
    //   this.form = Boolean(route.params.form);
    //   this.copy = route.params.form == 'copy' ? true : false;

    //   if (this.form) {
    //     this.formComponent = `${this.widget.base}Form`;
    //   } else {
    //     this.formComponent = null;
    //   }
    //   if (this.id && !this.form) {
    //     this.detailComponent = `${this.widget.base}`;
    //   } else {
    //     this.detailComponent = null;
    //   }
    },
    showForm(item, duplicate = false) {
        this.detailComponent = null;
        this.id = item._id;
        this.duplicate = duplicate;

        this.form = true;
        this.formComponent = `${this.widget.base}Form`;
    },
    hideForm(item) {
        this.form = false;
        this.formComponent = null;

        if(item._id) {
            this.showDetails(item);
        }
    },
    showDetails(item) {
        this.hideForm({});
        this.detailComponent = `${this.widget.base}`;
        this.id = item._id;
    },
    hideDetails() {
        this.hideForm({});
        this.detailComponent = null;
    },
    words() {
        navigator.clipboard.writeText(faker.lorem.words());
    },
    paragraphs() {
        navigator.clipboard.writeText(faker.lorem.paragraphs());
    },
    paragraph() {
        navigator.clipboard.writeText(faker.lorem.paragraph());
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.init(to);
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.init(to);
    next();
  },
};
</script>