<template>
  <b-container fluid class="m-3 pb-2 tw-border-2">

    <b-row v-if="!myReadOnly">
      <b-col class="text-right p-3">
        <b-btn @click="words()" variant="info" class="mr-2">Words</b-btn>
        <b-btn @click="paragraph()" variant="info" class="mr-2">Paragraph</b-btn>
        <b-btn
          variant="primary"
          id="create-template"
          v-if="!myData.templateName"
        >
          Create Template
        </b-btn>
        <b-btn
          variant="primary"
          v-if="myData.templateName"
          disabled
        >
          Template
        </b-btn>

        <b-btn v-if="!inlineEdit" @click="clearPost()" variant="warning" class="mr-2">Clear</b-btn>
        <b-btn @click="deletePost()" variant="danger" class="mr-2">
          <i class="fas fa-trash"></i>
        </b-btn>
        <b-btn v-if="!inlineEdit" @click="closePost()" variant="danger" class="mr-2">Close Post</b-btn>
        <b-btn v-if="!inlineEdit" @click="closePost()" variant="danger" class="mr-2">New Post</b-btn>
        <b-btn @click="savePost()" class="mr-2">Save</b-btn>
        <b-btn v-if="inlineEdit" @click="finishEditPost()">Save &amp; Finish</b-btn>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col class="text-right p-3">
        <b-btn
          variant="primary"
          v-if="myData.templateName"
          disabled
        >
          Template
        </b-btn>

        <b-btn @click="deletePost()" variant="danger" class="mr-2">
          <i class="fas fa-trash"></i>
        </b-btn>
        <b-btn @click="editPost()" variant="secondary" class="mr-2">
          <i class="fas fa-edit"></i>
        </b-btn>
      </b-col>
    </b-row>
      <b-row>
        <b-col class="text-left">
          <div class="text-left" :id="editorID"></div>
        </b-col>
      </b-row>
      <b-row v-if="!myReadOnly">
        <b-col>
          <b-form-tags
            input-id="tags"
            v-model="form.tags"
            class="mb-2"
            style="background-color: inherit"
            placeholder="Enter new tags separated by space, comma or semicolon"
            separator=" "
        ></b-form-tags>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <span class="tw-italic tw-text-gray-400" v-if="myData.data.tags">{{ myData.data.tags.join(', ') }}</span>
        </b-col>
        <b-col class="text-right tw-italic tw-text-gray-400">
          {{ formatDate(myData.updatedAt) }}
        </b-col>
      </b-row>
      <b-row v-if="!myReadOnly">
        <b-col class="text-right tw-italic tw-text-gray-400" v-if="myData.updatedAt">
          saved {{ timeSince }}
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col>
          {{ myData }}
        </b-col>
      </b-row> -->
      <b-row>
        <b-col>

          <b-popover target="create-template" title="" triggers="click" placement="bottomleft">
            <b-container fluid>
              <b-row class="my-2">
                <b-col>
                  <b-form-input id="input-large" size="lg" v-model="templateName" placeholder="Template Name"></b-form-input>
                </b-col>
              </b-row>
              <b-row class="my-2">
                <b-col>
                  <b-button block @click="finishEditPost()" :disabled="!templateName.trim()">
                    Create
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </b-popover>

        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { HTTP } from "@/util/http-common.js";
import HandleErrors from "@/util/handle-errors";

import * as _ from "lodash";
import * as faker from 'faker';
import * as moment from 'moment';

// import * as faker from '@faker-js/faker';
// const faker = require('@faker-js/faker');
// import { faker } from "https://cdn.skypack.dev/@faker-js/faker@v6.0.0"

import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import RawTool from '@editorjs/raw';
import SimpleImage from '@editorjs/simple-image';
import Checklist from '@editorjs/checklist';
import List from '@editorjs/list';
import Quote from '@editorjs/quote';
import Marker from '@editorjs/marker';
import Table from '@editorjs/table';
import CodeTool from '@editorjs/code';
import Delimiter from '@editorjs/delimiter';
import InlineCode from '@editorjs/inline-code';

export default {
  mixins: [],
  components: {},
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    inputData: {
      type: Object,
      required: false,
      default: Object
    },
    id: {
      type: String,
      required: false,
      default: ""
    },
  },
  data: function () {
    return {
      editorOption: {
        modules: { toolbar: true },
        placeholder: "Enter media description",
      },
      form: {},
      showForm: false,
      endless: [],
      items: [],
      keyword: "",
      tags: [],
      item: null,
      editor: null,
      editorID: null,
      myID: null,
      myData: null,
      myReadOnly: true,
      inlineEdit: false,
      templateName: ''
    };
  },
  computed: {
    timeSince() {
      return moment(this.myData.updatedAt).fromNow();
    }
  },
  async created() {
    this.editorID = `editor-${Date.now()}`;
    this.myID = this.id;
    this.myData = this.inputData;
    this.myReadOnly = this.readOnly ? true : false;
  },
  mounted() {
    const that = this;

    that.editor = new EditorJS({
      /**
       * Id of Element that should contain Editor instance
       */
      holder: that.editorID,
      data: {},
      tools: {
        header: Header,
        raw: RawTool,
        image: SimpleImage,
        checklist: {
          class: Checklist,
          inlineToolbar: true,
        },
         list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: 'unordered'
          }
        },
        quote: {
          class: Quote,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+O',
          config: {
            quotePlaceholder: 'Enter a quote',
            captionPlaceholder: 'Quote\'s author',
          },
        },
        Marker: {
          class: Marker,
          shortcut: 'CMD+SHIFT+M',
        },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
          },
        },
        code: CodeTool,
        delimiter: Delimiter,
        inlineCode: {
          class: InlineCode,
          shortcut: 'CMD+SHIFT+M',
        },
      },
      onChange: (api, event) => {
        if(event.type == 'block-changed') {
          console.log('saving post...')
          that.savePost();
        }
      }
    });

    setTimeout(() => {
      that.init();
    }, 2000);
  },
  methods: {
    init() {
      if(this.myID) {
        this.get();
      } else if(this.inputData.data) {
        this.setData(this.inputData.data.blocks);
        this.myID = this.inputData._id ? this.inputData._id : null;
      }
      // if(this.readOnly) {
      //   this.editor.readOnly.toggle();
      // } else {
        
      //   // this.editor.blocks.insert('header', {
      //   //   text: faker.company.companyName(),
      //   //   level: 1
      //   // });
      //   // this.editor.blocks.insert('paragraph', {text: faker.lorem.paragraph(3)});
      //   // // this.editor.blocks.insert('header', {text: faker.company.companyName(), level: 2});
      //   // // this.editor.blocks.insert('paragraph', {text: faker.lorem.paragraph(3)});
      //   // // this.editor.blocks.insert('header', {text: faker.company.companyName(), level: 2});
      //   // // this.editor.blocks.insert('paragraph', {text: faker.lorem.paragraph(3)});
      //   // this.editor.blocks.insert('image', {
      //   //   // url: "http://loremflickr.com/640/480/people",
      //   //   url: faker.image.dataUri(),
      //   //   caption: faker.lorem.words()
      //   // });
      //   // this.editor.blocks.insert('code', {code: "SELECT * FROM users;"});
      //   // this.editor.blocks.insert('delimiter', {});


      // }

      // console.log(faker)
    },
    async get() {
      const that = this;
      const response = await that.$http.get(`/editorjs/${that.id}`);
      that.setData(response.data);
    },
    words() {
      navigator.clipboard.writeText(faker.lorem.words());
    },
    paragraph() {
      navigator.clipboard.writeText(faker.lorem.paragraphs());
    },
    setData(blocks) {
      const that = this;
      _.each(blocks, (block) => {
        that.editor.blocks.insert(block.type, block.data);
      });
      this.editor.readOnly.toggle();
    },
    async savePost() {
      const that = this;
      console.log('savePost');
      that.editor.save().then(async (outputData) => {
        console.log('editor.save')
        // console.log('Article data: ', outputData)
        // console.log('Article data: ', JSON.stringify(outputData));

        outputData.tags = that.form.tags;
        outputData.templateName = that.templateName;

        let response = null;
        if(that.myID) {
          response = await that.$http.patch(`/editorjs/${that.myID}`, outputData);
        } else {
          response = await that.$http.post('/editorjs', outputData);
        }

        that.myID = response.data._id;
        that.myData = response.data;
        that.templateName = '';
      }).catch((error) => {
        console.log('Saving failed: ', error)
      });
    },
    editPost() {
      this.myReadOnly = false;
      this.inlineEdit = true;
      this.editor.readOnly.toggle();
      this.form.tags = this.myData.data.tags;
      console.log(this.myReadOnly);
    },
    finishEditPost() {
      this.savePost();
      this.myReadOnly = true;
      this.inlineEdit = false;
      this.editor.readOnly.toggle();
    },
    clearPost() {
      this.editor.blocks.clear();
      // this.myID = null;
      // this.myData = {};
      this.form.tags = [];
    },
    closePost() {
      this.$emit('itemadded', this.myData);
      this.clearPost();
    },
    async deletePost() {
      if (!confirm("Are you sure you want to delete post?")) {
        return;
      }

      const that = this;
      await that.$http.delete(`/editorjs/${that.myID}`);

      that.$notify({
        text: "Post deleted forever.",
        duration: 5000,
        type: "danger",
      });

      this.$emit('itemdeleted', this.myID);
    },
    cancelForm() {
      this.item = null;
      this.form = {};
    },
    filterTag(tag) {
      const that = this;
      const index = _.indexOf(that.tags, tag);

      if (index >= 0) {
        that.tags = _.remove(that.tags, function (t) {
          return t !== tag;
        });
      } else {
        that.tags.push(tag);
      }

      that.find();
    },
    formatDate(input) {
      return moment(input).format('LLLL');
    }
  },
};
</script>
