<template>
  <!-- <div>
    
    <b-table striped hover :items="items" :fields="fields" :td-class="['text-truncate', 'text-success']">
      <template v-slot:cell(name)="data">
        <b-button
          variant="link"
          :to="{ name: 'exercise-builder', params: { exercise_id: data.item._id } }"
        >
          {{ data.value }}
        </b-button>
      </template>

      <template v-slot:cell(description)="data">
        <div class="d-inline-block text-truncate cell-width">
          {{ data.value }}
        </div>
      </template>

      <template v-slot:cell(actions)="data">
        <b-button
          squared
          variant="outline-secondary"
          :to="{ name: 'exercise-builder', params: { exercise_id: data.item._id } }"
        >
          <i class="fas fa-pen"></i>
        </b-button>
      </template>
    </b-table>

    <img class="tw-w-16 md:tw-w-32" src="https://images.unsplash.com/photo-1515711660811-48832a4c6f69?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=448&q=80">
  </div> -->

  <b-card class="tw-shadow-md">
    <b-card-text>
      <b-container fluid>
        <b-row no-gutters>
          <b-col>
            <h3>Exercise Templates</h3>
          </b-col>
          <b-col class="text-right">
            <b-button id="create-exercise-template-popover" size="lg">
              Create Template
            </b-button>
          </b-col>
        </b-row>
      </b-container>

      <b-popover target="create-exercise-template-popover" title="" triggers="click" placement="bottomleft">
        <b-container fluid>
          <b-row class="my-2">
            <b-col>
              <b-form-input id="input-large" size="lg" v-model="name" placeholder="Template Name"></b-form-input>
            </b-col>
          </b-row>
          <b-row class="my-2">
            <b-col>
              <b-button block @click="create()" :disabled="!name.trim()">
                Create
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-popover>
    </b-card-text>

    <b-card-text>
      <ExerciseTemplateTable />
    </b-card-text>
  </b-card>
</template>
<script>
import { HTTP } from "@/util/http-common.js";
import HandleErrors from "@/util/handle-errors";

export default {
  mixins: [],
  components: {},
  data() {
    return {
      fields: ["name", "status", "exercises", "actions"],
      name: "",
    };
  },
  mounted() {
    this.find();
  },
  methods: {
    create() {
      const that = this;

      if (!that.name.trim()) {
        return;
      }

      that.$http.post("/exercise/", {
        name: that.name,
      })
        .then((response) => {
          that.$notify({
            text: `Exercise template '${that.name}' created.`,
            duration: 10000,
            type: "success",
          });

          that.$router.push({
            name: "exercise-template-update",
            params: { id: response.data._id },
          });
        })
        .catch((error) => {
          HandleErrors.HandleErrors(error);
        });
    },
    init() {
      const that = this;

      that.$http.get("/exercise-init")
        .then(() => {
          that.find();
        })
        .catch((error) => {
          HandleErrors.HandleErrors(error);
        });
    },
    find() {
      const that = this;

      that.$http.get("/exercise/?templates=true")
        .then((response) => {
          that.items = response.data;
        })
        .catch((error) => {
          HandleErrors.HandleErrors(error);
        });
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}

.cell-width {
  width: 150px;
}
</style>
