<template>
    <div class="mt-2">
        <Inventory
            v-for="item in sortedItemList"
            :key="item._id"
            :input="item"
            :exercise="exercise"
            v-on:removefromexercise="$emit('removefromexercise', $event)"
            v-on:editinexercise="$emit('editinexercise', $event)"
        />
    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "InventoryList",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            socketEventName: ""
        };
    },
    computed: {},
    created: function () {},
    mounted: function () {
        const that = this;

        // that.listenExerciseList(that.exercise, 'inventory');

        that.find();

        that.socketIOSubscribe({
            name: `${that.exercise._id}-inventory-list`
        });

        that.subscribe(
            `${that.exercise._id}-remove-inventory-list`,
            function (data) {
                that.find();
            }
        );
    },
    methods: {
        find: async function () {
            const that = this;

            that.itemList = await that.$http.get(`/inventory`, {
                params: {
                    exercise: that.exercise._id
                }
            }).then((response) => response.data);
        },
    },
    beforeRouteLeave(to, from, next) {
        // this.sockets.unsubscribe(this.socketEventName);
        // this.sockets.unsubscribe(`${this.exercise._id}-remove-inventory-list`);
    },
};
</script>
