<template>
    <!-- <div>
    <b-list-group>
      <b-list-group-item v-for="item in sortedItems" :key="item._id">
        <StatusBoardListItem :item="item" :exercise="exercise" />
      </b-list-group-item>
    </b-list-group>
  </div> -->

    <div class="mt-2">
        <div class="mb-3">
            <b-form-input
                id="inject-search"
                size="lg"
                placeholder="Search Status Board"
                v-model="keyword"
                @keyup="find()"
            ></b-form-input>
        </div>
        <sort-inject :inject-sort="sortByVal" v-on:sort="setSort($event)"></sort-inject>
        <StatusBoard
            v-for="item in sortedBySort"
            :key="item._id"
            :input="item"
            :exercise="exercise"
            v-on:details="$emit('details', $event)"
            v-on:edit="$emit('edit', $event)"
            v-on:duplicate="$emit('duplicate', $event)"
            v-on:addtoexercise="$emit('addtoexercise', $event)"
            view="summary"
        />
    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import * as _ from "lodash";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "StatusBoardLibrary",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
    },
    data: function () {
        return {
            socketEventName: "",
            keyword: ''
        };
    },
    computed: {
        sortedItems() {
            const that = this;
            return _.sortBy(that.itemList, [
                function (o) {
                    return o.sendTime;
                },
            ]);
        },
    },
    created() {
        const that = this;

        // that.listenLibraryList('status-board')

        that.find();

        that.socketIOSubscribeLibraryHelper({
            name: `status-board`
        });

        // that.find();

        // that.socketEventName = `${that.customer._id}-status-board-library`;
        // that.subscribe(that.socketEventName, function (data) {
        //     let index = _.findIndex(item in itemList, function (o) {
        //         return o._id == data._id;
        //     });

        //     if (index >= 0) {
        //         item in itemList[index] = data;
        //     } else {
        //         item in itemList.push(data);
        //     }
        // });
    },
    destroyed() {
        // this.unsubscribe(this.socketEventName);
    },
    mounted() {},
    methods: {
        find: function () {
            const that = this;
            that.$http.get("/status-board", {params: {keyword: that.keyword}})
                .then((response) => {
                    that.itemList = response.data;
                })
                .catch((error) => {
                    HandleErrors.HandleErrors(error);
                });
        },
    },
    // beforeRouteLeave(to, from, next) {
    //     this.sockets.unsubscribe(this.socketEventName);
    // },
};
</script>
