<template>
  <b-card class="my-2" v-if="item.chat == chat._id && isItemVisible(exercise, $store.state.sim.role, item)" :bg-variant="bgVariant()">
    <b-card-text class="small text-muted">
      <b-badge class="tw-mr-2">
        <RoleInfo :role-id="item.role" />
      </b-badge>
      <b-badge class="tw-mr-2">
        <UserFullName :user-id="item.user" />
      </b-badge>

      <i
          v-if="$store.state.user.user._id == item.user"
          class="fas fa-times tw-cursor-pointer float-right"
          title="Delete"
          @click="remove(item)"
        ></i>
        <!-- <ShowSendTimeBadge
          class="float-right mr-2"
          :exercise="exercise"
          :item="item"
        /> -->
    </b-card-text>
    <b-card-text>
      {{ item.message }}
    </b-card-text>
    <b-card-text class="small text-muted">
      <FormatDateTime :exercise="exercise" :sendTime="item.sendTime" />
    </b-card-text>
  </b-card>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "ChatEntry",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
    chat: {
      type: Object,
      required: true,
    },
    exercise: {
      type: Object,
      required: true,
      default: Object,
    }
  },
  data: function() {
    return {};
  },
  computed: {},
  watch: {},
  created: function() {},
  mounted: function() {},
  methods: {
    bgVariant: function() {
      return this.item.role == this.$store.getters['sim/getRoleID']
        ? "light"
        : "default";
    },
    textVariant: function(input) {
      return "default";
    },
    canShow() {
        if(this.item.chat !== this.chat._id) {
            return false;
        }

        if(this.item.status !== 'sent') {
            return false;
        }

        return true;
    },
    remove: async function() {
      const that = this;

      await that.$http.patch(`/chat-entries/${that.item._id}`, {
        status: "deleted",
        actionTime: that.exercise.currentTime,
      });

      that.$notify({
        text: "Deleted.",
        duration: 10000,
        type: "success",
      });
    },
  },
};
</script>
