<template>
    <BaseItemWidget>
        <FormAction :id="id" :copy="copy" />
        <b-form @submit="onSubmit" @reset="onReset">
            <b-form-group
                id="service-group"
                label="Service:"
                label-for="service"
            >
                <b-form-input
                    id="service"
                    v-model="form.service"
                    required
                    placeholder="Enter Service"
                ></b-form-input>
            </b-form-group>

            <b-form-group
                id="description-group"
                label="Description:"
                label-for="description"
            >
                <b-form-textarea
                    id="description"
                    v-model="form.description"
                    placeholder="Enter Service Description"
                    rows="6"
                ></b-form-textarea>
            </b-form-group>

            <div class="row">
                <div class="col">
                    <b-form-group
                        id="personnel-group"
                        label="Personnel:"
                        label-for="personnel"
                    >
                        <b-form-input
                            id="personnel"
                            v-model="form.personnel"
                            required
                            placeholder="Enter Personnel"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group
                        id="teams-group"
                        label="Teams:"
                        label-for="teams"
                    >
                        <b-form-input
                            id="teams"
                            v-model="form.teams"
                            required
                            placeholder="Enter Teams"
                        ></b-form-input>
                    </b-form-group>
                </div>
            </div>

            <b-form-group
                id="send-time-group"
                label="Send Time:"
                label-for="send-time"
            >
                <b-form-input
                    id="send-time"
                    v-model="form.formattedSendTime"
                    placeholder="now"
                    aria-describedby="send-time-input-live-help"
                ></b-form-input>
                <b-form-text id="send-time-input-live-help">
                    Default value is blank now (current time, or 0 seconds,
                    which is on exercise start). Otherwise, use the format
                    HH:MM:SS, MM:SS, SS
                </b-form-text>
            </b-form-group>

            <!-- <b-form-group id="exercise-group" v-if="exercise">
        <b-form-checkbox v-model="form.exercise" :value="exercise._id" unchecked-value="" class="mb-3">
          Add to {{ exercise.name }}
        </b-form-checkbox>
      </b-form-group>

      <div class="row">
        <div class="col">
          <b-button block variant="outline-primary" type="submit">Save</b-button>
        </div>
        <div class="col">
          <b-button block variant="outline-warning" type="reset">Clear</b-button>
        </div>
      </div> -->

            <FormButtonOptions :exercise="exercise" :id="id" :copy="copy" />
        </b-form>
    </BaseItemWidget>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "ServiceFormComponent",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
        id: {
            type: String,
            required: false,
            default: null,
        },
        input: {
            type: Object,
            required: false,
            default: Object,
        },
        copy: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        return {
            item: null,
            form: {
                service: null,
                description: null,
                personnel: null,
                teams: null,
                exercise: null,
            },
            formattedSendTime: "",
            formattedSendTimePlaceholder: "now",
            roles: [],
        };
    },
    computed: {
        ...mapState("sample", ["sample"]),
    },
    watch: {
        id: function (val) {
            if (val) {
                this.init();
            } else {
                this.item = {};
                this.form = {};
            }
        },
        input: function (val) {
            this.item = this.input;
            this.form = _.extend({}, this.input);
        },
    },
    created: function () {
        if (this.id) {
            this.init();
        }
    },
    mounted: function () {},
    methods: {
        init() {
            const that = this;
            that.$http.get(`/service/${that.id}`).then((response) => {
                that.item = response.data;

                that.form = _.extend({}, that.item);
                that.form._id = that.copy ? undefined : that.form._id;
                that.form.formattedSendTime = that.secondsToFormattedSendTime(that.form.sendTime);
            });
        },
        onSubmit: function (evt) {
            evt.preventDefault();

            const that = this;
            const data = _.clone(that.form);

            data.exercise = that.exercise._id ? that.exercise._id : undefined;

            data.sendTime = that.formattedToSecondsSendTime(that.form.formattedSendTime);

            let request = null;
            let url = `/service`;

            if (data._id) {
                request = that.$http.patch(`${url}/${data._id}`, data);
            } else {
                request = that.$http.post(url, data);
            }

            request
                .then((response) => {
                    // If exercise, redirect to exercise builder.
                    // if (that.exercise._id) {
                    //     return that.$router.push({
                    //         name: "exercise-builder-widget",
                    //         params: {
                    //             exercise_id: that.exercise._id,
                    //             widget: "Service",
                    //         },
                    //     });
                    // }

                    // that.$router.push({
                    //     name: "library-detail",
                    //     params: { widget: "service", id: response.data._id },
                    // });

                    that.$bvToast.toast("Service created", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                    });

                    that.$emit('form', response.data);

                    // that.onReset(evt);
                })
                .catch((error) => {
                    HandleErrors.HandleErrors(error);
                });
        },
        onReset: function (evt) {
            evt.preventDefault();
            this.$emit('form', (this.item) ? this.item : {});
            // const that = this;

            // if (that.exercise._id) {
            //     return that.$router.push({
            //         name: "exercise-builder-widget",
            //         params: {
            //             exercise_id: that.exercise._id,
            //             widget: "Service",
            //         },
            //     });
            // }
            // _.each(that.form, (item, key) => {
            //     if (key === "tags") {
            //         that.form[key] = [];
            //     } else {
            //         that.form[key] = null;
            //     }
            // });
        },
    },
};
</script>
