<template>
    <BaseItemWidget>
        <FormAction :id="id" :copy="copy" />
        <b-form @submit="onSubmit" @reset="onReset">
            <!-- <b-form-group id="title-group" label="Title:" label-for="title">
                <b-form-input
                    id="title"
                    v-model="form.title"
                    required
                    placeholder="Enter title"
                ></b-form-input>
            </b-form-group> -->

            <b-form-group id="task-group" label="Task:" label-for="task">
                <b-form-textarea
                    id="task"
                    v-model="form.task"
                    placeholder="Enter Task"
                    rows="6"
                ></b-form-textarea>
            </b-form-group>

            <b-form-group id="role-group" label="Roles:" label-for="roles" v-if="!form._id">
                <multiselect
                    v-model="form.toRole"
                    :multiple="false"
                    :options="roles"
                    track-by="_id"
                    label="roleSimcellName"
                ></multiselect>
            </b-form-group>

            <b-form-group id="priority-group" label="Priority:" label-for="priority">
                <multiselect
                    v-model="form.priority"
                    :multiple="false"
                    :options="priorities"
                ></multiselect>
            </b-form-group>

            <FormButtonOptions :exercise="exercise" :id="id" :copy="copy" />
        </b-form>
    </BaseItemWidget>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import * as _ from "lodash";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "TaskForm",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
        id: {
            type: String,
            required: false,
            default: null,
        },
        input: {
            type: Object,
            required: false,
            default: Object,
        },
        copy: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        return {
            item: null,
            form: {
                priority: 'low'
            },
            formattedSendTime: "",
            formattedSendTimePlaceholder: "now",
            roles: [],
            priorities: ['low', 'medium', 'high', 'urgent', 'flash']
        };
    },
    watch: {
        // exercise: function (val) {
        //     if (val._id) {
        //         this.form.exercise = true;
        //         this.find();
        //     } else {
        //         this.form.exercise = false;
        //     }
        // },
        id: function (val) {
            if (val) {
                this.init();
            } else {
                this.item = {};
                this.form = {};
            }
        },
        input: function (val) {
            // if (val._id) {
            //     this.item = this.input;
            //     this.form = _.extend({}, this.input);
            // }
            this.item = this.input;
            this.form = _.extend({}, this.input);
        },
    },
    computed: {
        ...mapState("sample", ["sample"]),
    },
    created: function () {
        //console.log(this.$options.name);
        this.find();

        if (this.id) {
            this.init();
        } else if (this.input) {
            this.item = this.input;
            this.form = _.extend({}, this.input);
        }
    },
    mounted: function () {},
    methods: {
        init() {
            const that = this;
            that.$http.get(`/exercise-mission-task/${that.id}`)
                .then((response) => {
                    that.item = response.data;

                    let form = _.extend({}, that.item);
                    form._id = that.copy ? undefined : form._id;
                    form.formattedSendTime = that.secondsToFormattedSendTime(
                        form.sendTime
                    );
                    form.roles = that.idsToObject(form.roles, that.roles);

                    that.form = form;
                })
                .catch((error) => {
                    HandleErrors.HandleErrors(error);
                });
        },
        async find() {
            const that = this;

            if (that.exercise._id) {
                // url = `/role?exercise=${that.exercise._id}`;
                that.roles = that.$store.getters['sim/getTraineeRolesExceptMe'];
            } else {
                const roles = await that.$http.get(`/role`).then((response) => {
                    return response.data;
                });

                // Filter out controller position, current user's position and any that have a deletedTime and actionTime.
                that.roles = _.filter(roles, (it) => {
                    return (!it.controller && it.role != that.$store.state.sim.role._id && !_.isNumber(it.deletedTime) && !_.isNumber(it.actionTime));
                });
            }
        },
        onSubmit: function (evt) {
            evt.preventDefault();

            const that = this;
            const data = _.clone(that.form);

            let request = null;
            let url = `/exercise-mission-task`;

            if (data._id) {
                request = that.$http.patch(`${url}/${data._id}`, data);
            } else {
                data.exercise = that.exercise._id ? that.exercise._id : undefined;
                data.sendTime = that.exercise.currentTime;
                data.toRole = data.toRole.role;
                data.fromRole = that.$store.getters['sim/getRoleID'];
                data.fromUser = that.$store.state.user.user._id;

                data.sendTime = that.formattedToSecondsSendTime(
                    that.form.formattedSendTime
                );

                request = that.$http.post(url, data);
            }

            request
                .then((response) => {
                    that.$bvToast.toast("Task saved", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                    });

                    that.form = {};
                    that.$emit("form", response.data);
                })
                .catch((error) => {
                    HandleErrors.HandleErrors(error);
                });
        },
        onReset: function (evt) {
            evt.preventDefault();
            const that = this;
            that.form = {};
            that.$emit("form", that.item ? that.item : {});
        },
    },
};
</script>
