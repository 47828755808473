<template>
    <div>
        <StatusBoard
            v-for="item in itemList"
            :key="item._id"
            :input="item"
            :exercise="exercise"
            v-on:removefromexercise="$emit('removefromexercise', $event)"
            v-on:editinexercise="$emit('editinexercise', $event)"
        />
    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "StatusBoardList",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            socketEventName: ""
        };
    },
    computed: {},
    created: function () {},
    mounted: function () {
        const that = this;

        // that.listenExerciseList(that.exercise, 'status-board');

        that.find();

        // that.subscribe(
        //     `${that.exercise._id}-remove-status-board-list`,
        //     function (data) {
        //         that.find();
        //     }
        // );

        that.socketIOSubscribe({
            name: `${that.exercise._id}-status-board-list`
        });
    },
    methods: {
        find: function () {
            const that = this;
            that.$http.get(`/status-board?exercise=${that.exercise._id}`)
                .then((response) => {
                    that.itemList = response.data;
                })
                .catch((error) => {
                    HandleErrors.HandleErrors(error);
                });
        },
    },
    beforeRouteLeave(to, from, next) {
        // this.sockets.unsubscribe(this.socketEventName);
        // this.sockets.unsubscribe(
        //     `${this.exercise._id}-remove-status-board-list`
        // );
    },
};
</script>
