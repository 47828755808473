<template>
    <div class="">
        <b-card class="mb-2">
            <b-card-title>
                {{ item.title }}
            </b-card-title>

            <b-card-text>
                {{ item.description }}
            </b-card-text>

            <b-card-text v-if="item.roles.length">
                <span
                    class="badge badge-primary mr-2"
                    v-for="role in item.roles"
                    :key="role"
                >
                    <RoleInfo :roleId="role" />
                </span>
            </b-card-text>

            <b-card-text v-if="!exercise._id || exercise._id && isExerciseController()">
                <ItemButtonOptions
                    widget="Inject"
                    :item="item"
                    :exercise="exercise"
                />
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "InjectComponent",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        item: {
            type: Object,
            required: true,
        },
        exercise: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data: function () {
        return {};
    },
    computed: {},
    created: function () {},
    mounted: function () {},
    methods: {
        addToExercise: function () {},
        removeFromExercise: function () {},
        duplicate: function () {},
    },
};
</script>
