<template>
    <div>
        <b-card class="my-2" v-if="isItemVisible(exercise, $store.state.sim.role, item) && item.role == $store.getters['sim/getRoleID']">
            <b-card-text class="small text-muted">
                <i
                    class="fas fa-times tw-cursor-pointer float-right"
                    title="Delete"
                    @click="remove(item)"
                ></i>
                <ShowSendTimeBadge
                    class="float-right mr-2"
                    :exercise="exercise"
                    :item="item"
                />
            </b-card-text>
            <b-card-text>
                {{ item.note }}
            </b-card-text>
            <b-card-text class="small text-muted">
                <FormatDateTime
                    :exercise="exercise"
                    :sendTime="item.sendTime"
                />
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "PositionLog",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        input: {
            type: Object,
            required: false,
        },
        id: {
            type: String,
            required: false,
        },
        exercise: {
            type: Object,
            required: true,
            default: Object,
        },
    },
    data: function () {
        return {
            socketEventName: "",
            note: "",
        };
    },
    computed: {
    },
    watch: {
        id: function (val) {
            if (val) {
                this.init();
            }
        },
        input: function (val) {
            if (val) {
                this.item = val;
            }
        },
    },
    created: function () {
        if (this.id) {
            this.init();
        } else if (this.input) {
            this.item = this.input;
        }
    },
    mounted: function () {},
    methods: {
        bgVariant: function (input) {
            return "default";
        },
        textVariant: function (input) {
            return "default";
        },
        init() {
            const that = this;
            that.$http.get(`/exercise-log/${this.id}`)
                .then((response) => {
                    that.item = response.data;
                })
                .catch((error) => {
                    HandleErrors.HandleErrors(error);
                });
        },
        remove: async function () {
            const that = this;

            await that.$http.patch(`/exercise-log/${that.item._id}`, { status: "deleted", actionTime: that.exercise.currentTime });

            that.$notify({
                text: "Deleted.",
                duration: 10000,
                type: "success",
            });
        },
    },
    beforeRouteLeave(to, from, next) {
        this.sockets.unsubscribe();
    },
};
</script>
