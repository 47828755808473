<template>
  <BaseItemWidget>

  <b-form @submit="onSubmit" @reset="onReset">
    <b-form-group
      id="resource-type-group"
      label="Resource Type:"
      label-for="resource-type"
    >
      <b-form-select
        id="resource-type"
        v-model="form.resourceType"
        :options="resourceTypeOptions"
      ></b-form-select>
    </b-form-group>

    <b-form-group
      id="priority-group"
      label="Priority:"
      label-for="priority"
    >
      <b-form-select
        id="priority"
        v-model="form.priority"
        :options="['low', 'medium', 'high', 'urgent', 'flash']"
      ></b-form-select>
    </b-form-group>

    <b-form-group
      id="comment-group"
      label="Comment:"
      label-for="comment"
    >
      <b-form-textarea
        id="comment"
        v-model="form.comment"
        placeholder="Enter Resource Comment"
        rows="6"
      ></b-form-textarea>
    </b-form-group>

    <!-- <b-form-group
      id="response-group"
      label="Response:"
      label-for="response"
    >
      <b-form-textarea
        id="response"
        v-model="form.response"
        placeholder="Enter Resource Response"
        rows="6"
      ></b-form-textarea>
    </b-form-group> -->

    <b-form-group
      v-if="form.resourceType == 'resource'"
      id="inventory-group"
      label="Inventory:"
      label-for="inventory"
    >
      <b-form-select
        id="inventory"
        v-model="form.inventory"
        :options="inventory"
        value-field="_id"
        text-field="resource"
      ></b-form-select>
    </b-form-group>

    <b-form-group
      v-if="form.resourceType == 'service'"
      id="service-group"
      label="Service:"
      label-for="service"
    >
      <b-form-select
        id="service"
        v-model="form.service"
        :options="services"
        value-field="_id"
        text-field="service"
      ></b-form-select>
    </b-form-group>

    <b-form-group
      v-if="form.resourceType == 'custom'"
      id="custom-group"
      label="Custom:"
      label-for="custom">
        <b-form-input
          id="custom"
          v-model="form.custom"
          required
          placeholder="Enter Custom"
        ></b-form-input>
      </b-form-group>

      <div class="row">
        <div class="col">
          <b-form-group
            id="quantity-group"
            label="Quantity:"
            label-for="quantity">
              <b-form-input
                id="quantity"
                v-model="form.quantity"
                type="number"
                required
                placeholder="Enter Quantity"
              ></b-form-input>
            </b-form-group>
        </div>
        <div class="col">
          <b-form-group
            v-if="form.resourceType == 'custom'"
            id="units-group"
            label="Units:"
            label-for="units">
              <b-form-input
                id="units"
                v-model="form.units"
                placeholder="Enter Units"
              ></b-form-input>
            </b-form-group>
        </div>
      </div>

    <b-form-group
      id="send-time-group"
      label="Send Time:"
      label-for="send-time">
        <b-form-input
          id="send-time"
          v-model="form.formattedSendTime"
          placeholder="now"
          aria-describedby="send-time-input-live-help"
        ></b-form-input>
        <b-form-text id="send-time-input-live-help">
          Default value is blank now (current time, or 0 seconds, which is on exercise start). Otherwise, use the format HH:MM:SS, MM:SS, SS
        </b-form-text>
    </b-form-group>

      <!-- <b-form-group id="exercise-group" v-if="exercise">
        <b-form-checkbox v-model="form.exercise" :value="exercise._id" unchecked-value="" class="mb-3">
          Add to {{ exercise.name }}
        </b-form-checkbox>
      </b-form-group> -->

      <div class="row">
        <div class="col">
          <b-button block variant="outline-primary" type="submit">Request</b-button>
        </div>
        <div class="col">
          <b-button block variant="outline-warning" type="reset">Clear</b-button>
        </div>
      </div>

  </b-form>

  </BaseItemWidget>
</template>

<script>
import {
    HTTP
} from '@/util/http-common';
import HandleErrors from '@/util/handle-errors';

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "ResourceRequest",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object
    },
    id: {
      type: String,
      required: false,
      default: null
    },
    copy: {
      type: Boolean,
      required: false,
      default: false
    }
},
  data: function() {
    return {
      item: null,
      form: {
        inventory: null,
        service: null,
        resourceType: 'custom',
        custom: null,
        quantity: null,
        units: null,
        priority: null,
        reason: null,
        comment: '',
        responses: [],
        latitude: null,
        longitude: null
      },
      formattedSendTime: '',
      formattedSendTimePlaceholder: 'now',
      resourceTypeOptions: ['resource', 'custom'],// 'service', 'resource', 'custom'
      inventory: [],
      services: []
    };
  },
  computed: {
    ...mapState("sample", ["sample"])
  },
  watch: {
    id: function (val) {
      if(val) {
        this.init();
      } else {
        this.item = {};
        this.form = {};
      }
    }
  },
  created: function() {
    this.find();

    if(this.id) {
      this.init();
    }
  },
  mounted: function() {},
  methods: {
    init() {
      const that = this;
      that.$http.get(`/resource-request/${that.id}`).then(response => {
        that.item = response.data;

        that.form = _.extend({}, that.item);
        that.form._id = that.copy ? undefined : that.form._id;
      });
    },
    find: function() {
      // Inventory
      const that = this;
      let headers = {params: {}};

      if(that.exercise._id) {
        headers.params.exercise = that.exercise._id;
      }

      that.$http.get(`/inventory`, headers).then(response => {
        that.inventory = _.filter(response.data, (item) => {
          return !item.actionTime;
        });
      }).catch(error => {
        HandleErrors.HandleErrors(error);
      });

      that.$http.get(`/service`, headers).then(response => {
        that.services = response.data;
      }).catch(error => {
        HandleErrors.HandleErrors(error);
      });
    },
    onSubmit: async function(evt) {
      evt.preventDefault();

      const that = this;
      const data = _.clone(that.form);
      const formattedSendTime = that.form.formattedSendTime ? that.form.formattedSendTime : String(that.exercise.currentTime);

      data.exercise = that.exercise._id ? that.exercise._id : undefined;

      data.sendTime = that.formattedToSecondsSendTime(formattedSendTime);
      data.role = that.$store.getters['sim/getRoleID'];

      let response = null;
      if(data._id) {
        response = await that.$http.patch(`/resource-request/${data._id}`, data);
      } else {
        response = await that.$http.post(`/resource-request`, data);
      }

      that.$bvToast.toast('Resource Request Saved!', {
        title: 'Success',
        variant: 'success',
        solid: true
      });

      that.form = {};
      that.$emit('form', response.data);
    },
    onReset: function(evt) {
      evt.preventDefault();
      const that = this;
      that.$emit('form', that.form);
      that.form = {};
    }
  }
};
</script>
