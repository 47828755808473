<template>
  <BaseSimWidget id="spreadsheetWidget">
    <template v-slot:title>
      <i class="fas fa-table"></i>
      Spreadsheet
    </template>

    <b-tabs v-model="tabIndex" align="right" pills>
      <template #tabs-end v-if="!fullScreen">
        <!-- <b-nav-item>
          <i class="fas fa-chevron-down"></i>
        </b-nav-item> -->
        <b-nav-item>
          <i class="fas fa-expand-arrows-alt" @click.prevent="goFullScreen()"></i>
        </b-nav-item>
      </template>

      <b-tab title="Library" :disabled="!isExerciseController() && !hasPermission(widgets.spreadsheet.slug)">
        <template v-slot:title>
          <i class="fas fa-briefcase"></i>
        </template>
        
        <SpreadsheetLibrary
          :exercise="exercise"
          v-on:editinexercise="editInExercise($event)"
          v-on:addtoexercise="addItemToExercise($event)"
        />
      </b-tab>
      <b-tab title="List">
        <template v-slot:title>
          <i class="fas fa-list"></i>
        </template>
        
        <SpreadsheetList
          :exercise="exercise"
          v-on:editinexercise="editInExercise($event)"
          v-on:selectedspreadsheet="selectedSpreadsheet($event)"
        />
      </b-tab>

      <b-tab title="Form" :disabled="!isExerciseController() && !hasPermission(widgets.spreadsheet.slug)">
        <template v-slot:title>
          <i class="fas fa-plus"></i>
        </template>
        
        <SpreadsheetForm
            v-on:form="formFinished($event)"
            :exercise="exercise"
            :copy="copy"
            :id="id"
        />
      </b-tab>

      <b-tab :disabled="!(selectedItem)" title="Details">
        <template v-slot:title>
          <i class="fas fa-table"></i>
        </template>

        <Spreadsheet
          v-if="selectedItem"
          :input="selectedItem"
          :exercise="exercise"
          :detailView="true"
        />
      </b-tab>

      <b-tab title="Help">
        <template v-slot:title>
          <i class="fas fa-question"></i>
        </template>
        
        <SpreadsheetHelp />
      </b-tab>

    </b-tabs>
  </BaseSimWidget>
</template>

<script>
// import {
//     HTTP
// } from '@/util/http-common';
// import HandleErrors from '@/util/handle-errors';

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "SpreadsheetSimWidget",
  mixins: [WidgetMixin],
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object
    },
    fullScreen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      show: true,
      fullWidth: false,
      title: "Sample Page",
      id: null,
      copy: false,
      tabIndex: 1,
      selectedItem: null
    };
  },
  computed: {
    ...mapState("sample", ["sample"])
  },
  created: function() {
    //console.log(this.$options.name);
  },
  mounted: function() {},
  methods: {
    formFinished: function(item) {
        this.id = null;
        this.copy = false;
        
        if(item.exercise) {
          this.tabIndex = 1;
        } else {
          this.tabIndex = 0;
        }
    },
    addItemToExercise: function (item) {
      this.id = item._id;
      this.copy = true;
      this.tabIndex = 2;
    },
    editInExercise: function(item) {
        this.id = item._id;
        this.copy = false;
        this.tabIndex = 2;
    },
    selectedSpreadsheet: function(item) {
      this.selectedItem = item;
      setTimeout(() => {
        this.tabIndex = 3;
      }, 500);
    },
    goFullScreen() {
      this.$emit('setfullscreenmodalwidget', 'SpreadsheetSimWidget');
    }
  }
};
</script>
