<template>
  <b-card class="mb-2">
            <b-card-title>
                {{ item.service }}

                <ShowSendTimeBadge
                  class="float-right"
                  :exercise="exercise"
                  :item="item"
                />
            </b-card-title>

            <b-card-text>
                {{ item.teams }} {{ item.units }}
            </b-card-text>

            <b-card-text v-if="!exercise._id || exercise._id && isExerciseController()">
                <ItemButtonOptions
                    widget="Service"
                    :item="item"
                    :exercise="exercise"
                />
            </b-card-text>
        </b-card>
</template>

<script>
import SocketIOMixin from '@/util/socketio';
export default {
  name: "ServiceListItemComponent",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
    exercise: {
      type: Object,
      required: false,
      default: null,
    },
  }
};
</script>
