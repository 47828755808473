<template>
  <b-card>
    <b-row no-gutters>
      <b-col class="text-center">
        <h3>{{exercise.name}}</h3>
        <h5 class="text-muted">
          <CurrentTime v-if="exercise" :exercise="exercise" :sendTime="exercise.currentTime" />
          {{exercise.currentTime}}
        </h5>

        <pre v-html="exercise.description">

        </pre>
      </b-col>
    </b-row>

    <hr>

    <b-row no-gutters>
      <b-col class="text-center">
        <b-button squared variant="outline-success">
          <i class="fas fa-play"></i> Play Exercise
        </b-button>
      </b-col>
    </b-row>
  </b-card>

  <!-- <b-container fluid>
      <b-row>
        <b-col>
          <h3>{{ exercise.name }}</h3>
        </b-col>
      </b-row>
      <b-row class="tw-p-3">
        <b-col>
          <b-button variant="outline-success" @click="play()" block>
            <i class="fas fa-play"></i>
            Play
          </b-button>
        </b-col>
        <b-col>
          <b-button variant="outline-warning" @click="pause()" block>
            <i class="fas fa-pause"></i>
            Pause
          </b-button>
        </b-col>
        <b-col>
          <b-button variant="outline-danger" @click="stop()" block>
            <i class="fas fa-stop"></i>
            Stop
          </b-button>
        </b-col>
      </b-row>

      <b-row class="tw-p-3">
        
        <b-col>
          <b-button variant="outline-primary" @click="speed(-1)" block>
            <i class="fas fa-minus"></i>
            Decrease
          </b-button>
        </b-col>
        <b-col>
          <b-button variant="outline-primary" @click="speed(1)" block>
            <i class="fas fa-plus"></i>
            Increase
          </b-button>
        </b-col>
      </b-row>
    </b-container> -->
</template>

<script>
// import {
//     HTTP
// } from '@/util/http-common';
// import HandleErrors from '@/util/handle-errors';

import { mapState } from "vuex";

import * as moment from 'moment';

import SocketIOMixin from '@/util/socketio';
export default {
  name: "Library",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: true,
      default: Object,
    },
  },
  data: function() {
    return {};
  },
  computed: {
    ...mapState("sample", ["sample"]),
  },
  created: function() {
    //console.log(this.$options.name);
  },
  mounted: function() {},
  methods: {
    get: function() {
      // that.$http.get('/').then((response) => {
      //     let something = response.data;
      // }).catch((error) => {
      //     HandleErrors.HandleErrors(error);
      // });
    },
    play: function() {},
    pause: function() {},
    stop: function() {},
    speed: function(speed) {}
  },
};
</script>
