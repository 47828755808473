<template>
  <BaseSimWidget id="weatherWidget">
    <template v-slot:title>
      <i class="fas fa-cloud"></i>
      Weather
    </template>

    <b-tabs v-model="tabIndex" id="" align="right" pills>
      <b-tab>
          <template #title>
            <i class="fas fa-lightbulb"></i>
          </template>

          <WeatherCurrent
            v-if="exercise._id"
            :exercise="exercise"
        />
      </b-tab>
      <b-tab>
          <template #title>
            <i class="fas fa-list"></i>
          </template>

          <WeatherList
            v-if="exercise._id"
            :exercise="exercise"
            v-on:editinexercise="editInExercise($event)"
        />
      </b-tab>
      <b-tab :disabled="!isExerciseController() && !hasPermission(widgets.weather.slug)">
          <template #title>
            <i class="fas fa-briefcase"></i>
          </template>

          <WeatherLibrary
            :exercise="exercise"
            v-on:addtoexercise="addItemToExercise($event)"
        />
      </b-tab>
      <b-tab :disabled="!isExerciseController() && !hasPermission(widgets.weather.slug)">
          <template #title>
            <i class="fas fa-plus"></i>
          </template>

          <WeatherForm
            :exercise="exercise"
            :copy="copy"
            :id="id"
            v-on:form="formFinished($event)"
        />
      </b-tab>

      <b-tab>
          <template #title>
            <i class="fas fa-question"></i>
          </template>

          <p>Help will be here.</p>
      </b-tab>

      <b-tab v-if="!fullScreen">
          <template #title>
            <i class="fas fa-arrows-alt" @click.prevent="goFullScreen()"></i>
          </template>
      </b-tab>
    </b-tabs>
    
  </BaseSimWidget>
</template>

<script>
import {
    HTTP
} from '@/util/http-common';
import HandleErrors from '@/util/handle-errors';

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "WeatherSimWidget",
  mixins: [WidgetMixin],
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object
    },
    fullScreen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      show: true,
      fullWidth: false,
      title: "Sample Page",
      addWeather: false,
      newWeather: false,
      id: null,
      copy: false,
      tabIndex: 0
    };
  },
  computed: {},
  created: function() {},
  mounted: function() {},
  methods: {
    formFinished: function() {
        this.id = null;
        this.copy = false;
        this.tabIndex = 1;
    },
    addItemToExercise: function(item) {
        this.id = item._id;
        this.copy = true;
        this.tabIndex = 3;
    },
    editInExercise: function(item) {
        this.id = item._id;
        this.copy = false;
        this.tabIndex = 3;
    },
    addItemToExercise: function(item) {
        this.id = item._id;
        this.copy = true;
        this.tabIndex = 3;
    },
    goFullScreen() {
      this.$emit('setfullscreenmodalwidget', 'WeatherSimWidget');
    }
  }
};
</script>
