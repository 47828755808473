<template>
    <b-card v-if="canShow && isItemVisible(exercise, $store.state.sim.role, item)" class="my-2">

        <b-card-title>

            <ShowSendTimeBadge
                class="float-right"
                :exercise="exercise"
                :item="item"
            />

            <b-badge :variant="getStatusVariant()" class="tw-mx-1 float-right">
                {{ item.status }}
            </b-badge>

            <b-badge :variant="getPriorityVariant()" class="tw-mx-1 float-right">
                {{ item.priority }}
            </b-badge>

            <b-badge variant="info" class="tw-mx-1 float-right" v-if="item.custom">
                {{ item.custom }}
            </b-badge>
            <b-badge variant="info" class="tw-mx-1 float-right" v-if="inventory">
                {{ inventory.resource }}
            </b-badge>
            
        </b-card-title>

        <b-card-text class="tw-mt-10">
            <hr>
        </b-card-text>

        <b-card-text v-if="item.comment">
            <b-card bg-variant="light">
                <b-card-text>
                    <!-- <h4 class="tw-inline-block">Comments:&nbsp;&nbsp;</h4> -->
                    <p class="tw-inline-block">{{ item.comment }}</p>
                </b-card-text>
            </b-card>
        </b-card-text>

        <b-card-text>
            <b-card bg-variant="light">
                <b-card-text class="tw-text-center">
                    <h4>Received / Requested</h4>
                    <h1 class="tw-text-6xl">
                        {{ item.received }} / {{ item.quantity }}
                        <span class="tw-text-xl" v-if="inventory">{{inventory.units}}</span>
                        <span class="tw-text-xl" v-else>{{ item.units }}</span>
                    </h1>
                </b-card-text>
            </b-card>
        </b-card-text>

        <!-- <b-card-text v-if="item.resourceType == 'custom'">
            {{ item.quantity }} {{ item.units }}
        </b-card-text>

        <b-card-text v-if="item.resourceType == 'resource' && inventory">
            <h4>

                Inventory:
                <b-badge class="tw-m-2 tw-p-2">
                    {{ inventory.quantity }}
                    <span v-if="inventory">{{inventory.units}}</span>
                    <span v-else>{{ item.units }}</span>
                </b-badge>

                Available:
                <b-badge class="tw-m-2 tw-p-2">
                    {{ inventory.quantity - inventory.usage }}
                    <span v-if="inventory">{{inventory.units}}</span>
                    <span v-else>{{ item.units }}</span>
                </b-badge>

            </h4>
        </b-card-text> -->

        

        <b-card-text v-if="item.responses.length">
            <b-card bg-variant="light">
                <b-card-text>
                    <h4><strong>Responses</strong></h4>
                    <b-list-group>
                        <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="(response, idx) in item.responses" :key="idx">
                            {{ response.comment }}
                            <b-badge variant="primary"><RoleInfo :role-id="response.role" /></b-badge>
                        </b-list-group-item>
                    </b-list-group>
                </b-card-text>
            </b-card>
        </b-card-text>

        <b-card-text v-if="item.status == 'sent' && view == 'requested'">
          <b-form-group
            id="quantity-group"
            label="Quantity:"
            label-for="quantity">
              <b-form-input
                id="quantity"
                v-model="form.quantity"
                type="number"
                required
                placeholder="Enter Quantity"
              ></b-form-input>
            </b-form-group>
        </b-card-text>

        <!-- <b-card-text>
            <ShowObject :input="item" />
            {{ item }}

            <hr><hr>

            {{ inventory }}
        </b-card-text> -->

        <b-card-text v-if="canShowResponseInput()">
            <b-form-group
            id="response-group"
            label-for="response"
            >
            <b-form-textarea
                id="response"
                v-model="form.response"
                placeholder="Enter comment or response before performing action"
                rows="6"
            ></b-form-textarea>
            </b-form-group>
        </b-card-text>

        <b-card-text>
            <b-container fluid>
                <b-row no-gutters>
                    <b-col></b-col>
                    <b-col cols="auto" v-if="canShowButton('send')">
                        <b-button
                            variant="outline-success"
                            class="mx-1"
                            @click="setStatus('sent')"
                        >
                            Send
                        </b-button>
                    </b-col>
                    <b-col cols="auto" v-if="canShowButton('cancel')">
                        <b-button
                            variant="outline-danger"
                            class="mx-1"
                            @click="setStatus('cancelled')"
                        >
                            Cancel
                        </b-button>
                    </b-col>
                    <b-col cols="auto" v-if="canShowButton('accept')">
                        <b-button
                            variant="outline-success"
                            class="mx-1"
                            @click="setStatus('accepted')"
                        >
                            Accept
                        </b-button>
                    </b-col>
                    <b-col cols="auto" v-if="canShowButton('in-transit')">
                        <b-button
                            variant="outline-success"
                            class="mx-1"
                            @click="setStatus('in-transit')"
                        >
                            In Transit
                        </b-button>
                    </b-col>
                    <b-col cols="auto" v-if="canShowButton('received')">
                        <b-button
                            variant="outline-success"
                            class="mx-1"
                            @click="setStatus('received')"
                        >
                            Received
                        </b-button>
                    </b-col>
                    <b-col cols="auto" v-if="canShowButton('return')">
                        <b-button
                            variant="outline-success"
                            class="mx-1"
                            @click="setStatus('returned')"
                        >
                            Return
                        </b-button>
                    </b-col>
                    <b-col cols="auto" v-if="canShowButton('complete')">
                        <b-button
                            variant="outline-success"
                            class="mx-1"
                            @click="setStatus('completed')"
                        >
                            Complete
                        </b-button>
                    </b-col>
                    <b-col cols="auto" v-if="canShowButton('deny')">
                        <b-button
                            variant="outline-danger"
                            class="mx-1"
                            @click="setStatus('denied')"
                        >
                            Deny
                        </b-button>
                    </b-col>
                    <b-col cols="auto" v-if="canShowButton('clarify')">
                        <b-button
                            variant="outline-info"
                            class="mx-1"
                            @click="setStatus('sent')"
                        >
                            Clarify
                        </b-button>
                    </b-col>
                    <!-- <b-col cols="auto" v-if="showButton('forward')">
                        <b-button
                            variant="outline-warning"
                            class="mx-1"
                            @click="updateToRoleStatus('forwarded')"
                        >
                            Forward
                        </b-button>
                    </b-col> -->
                    <b-col></b-col>
                </b-row>
            </b-container>
        </b-card-text>
    </b-card>
</template>

<script>
import { HTTP } from "@/util/http-common";

import * as _ from 'lodash';

import SocketIOMixin from '@/util/socketio';
export default {
    name: "ResourceRequest",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        input: {
            type: Object,
            required: false,
        },
        id: {
            type: String,
            required: false,
        },
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
        view: {
            type: String,
            required: false,
            default: 'request'
        }
    },
    data: function () {
        return {
            item: {},
            form: {response: ''},
            inventory: null,
            service: null
        };
    },
    computed: {
        canShow: function() {
            // If this role has logistics permission, show if one of these statuses.
            if(this.view == 'requested' && this.$store.state.sim.role.permissionList.indexOf(this.widgets.resourceRequested.permission) >= 0) {
                if(this.item.status == 'draft' || this.item.status == 'cancelled') {
                    return false;
                } else {
                    return true;
                }
                // return (['sent', 'accepted', 'in-transit', 'received', 'returned', 'completed', 'denied', 'clarify'].indexOf(this.item.status) >= 0);
            }

            if(this.view == 'request' && this.item.role == this.$store.getters['sim/getRoleID']) {
                return true;
            }

            return false;
        }
    },
    created() {
        // if (this.id) {
        //     this.init();
        // } else if (this.input) {
        //     this.item = this.input;
        //     this.getInventory();
        // }
        this.init();
    },
    watch: {
        id: function (val) {
            if (val) {
                this.init();
            }
        },
        input: function (val) {
            if (val) {
                this.init();
            }
        },
    },
    mounted: function () {},
    methods: {
        async init() {
            const that = this;

            if(that.id) {
                const response = await that.$http.get(`/resource-request/${that.id}`);
                that.item = response.data;
            } else {
                that.item = that.input;
            }

            if(that.item.inventory) {
                const response = await that.$http.get(`/inventory/${that.item.inventory}`);
                that.inventory = response.data;
            }

            that.form.quantity = that.item.quantity;
        },
        showButton(status) {
            return true;

            // If this is the inbox.
            // if(this.inbox == "in") {
            //     if(this.item.status == "sent" && ["accept", "decline", "clarify", "forward"].indexOf(button) >= 0) {
            //         return true;
            //     } else if(this.item.status == "accepted" && ["complete", "decline", "clarify", "forward"].indexOf(button) >= 0) {
            //         return true;
            //     }
            // } else {
            //     if(this.item.status == "draft" && ["send", "cancel"].indexOf(button) >= 0) {
            //         return true;
            //     }
            // }

            // return false;
        },
        canShowButton(button) {
            const that = this;
            const roleID = that.$store.getters['sim/getRoleID'];

            // console.log('button: ', button, ', item.role: ', that.item.role, ' roleID: ', roleID);

            if(that.view == "request") {
                if(button == 'send' && that.item.status == 'draft' && that.item.role == roleID) {
                    return true;
                } else if(button == 'cancel' && ['draft', 'sent'].indexOf(that.item.status) >= 0 && that.item.role == roleID) {
                    return true;
                } else if(['received'].indexOf(button) >= 0 && that.item.status == 'in-transit' && that.item.role == roleID) {
                    // Return true if one of these buttons and status is sent and has logistics permission.
                    return true;
                } else if(['complete'].indexOf(button) >= 0 && that.item.status == 'received' && that.item.role == roleID && that.item.resourceType == 'custom') {
                    // Return true if one of these buttons and status is sent and has logistics permission.
                    return true;
                }  else if(['return'].indexOf(button) >= 0 && that.item.status == 'received' && that.item.role == roleID && that.item.resourceType == 'resource') {
                    // Return true if one of these buttons and status is sent and has logistics permission.
                    return true;
                }
            } else {
                if(['accept', 'deny', 'clarify'].indexOf(button) >= 0 && that.item.status == 'sent' && that.hasPermission(that.widgets.resourceRequested.permission)) {
                    // Return true if one of these buttons and status is sent and has logistics permission.
                    return true;
                } else if(['in-transit'].indexOf(button) >= 0 && that.item.status == 'accepted' && that.hasPermission(that.widgets.resourceRequested.permission)) {
                    // Return true if one of these buttons and status is sent and has logistics permission.
                    return true;
                } else if(['complete'].indexOf(button) >= 0 && that.item.status == 'returned' && that.hasPermission(that.widgets.resourceRequested.permission)) {
                    // Return true if one of these buttons and status is sent and has logistics permission.
                    return true;
                }
            }

            return false;
        },
        canShowResponseInput() {
            // If this is the request view, only show the response input if these conditions are true.
            if(this.view == "request") {
                return (['sent', 'clarify', 'in-transit', 'received'].indexOf(this.item.status) >= 0);
            }

            return (['sent', 'accepted', 'returned'].indexOf(this.item.status) >= 0);
        },
        getStatusVariant() {
            if(this.item.status == 'draft') {
                return 'secondary';
            } else if(this.item.status == 'denied') {
                return 'danger';
            } else if(this.item.status == 'cancelled') {
                return 'dark';
            } else {
                return 'success';
            }
            
        },
        getPriorityVariant() {
            switch (this.item.priority) {
                case 'low':
                    return 'secondary';
                case 'medium':
                    return 'info';
                case 'high':
                    return 'primary';
                default:// Flash/Urgent
                    return 'danger';
            }
        },
        // async send() {
        //     const that = this;

        //     // Clone the current task.
        //     const oldItem = _.cloneDeep(that.item);

        //     oldItem._id = undefined;
        //     oldItem.actionTime = that.exercise.currentTime;

        //     await that.$http.post(`/resource-request`, oldItem);

        //     if(that.form.response) {
        //         that.item.responses.push({
        //             role: that.$store.getters['sim/getRoleID'],
        //             comment: that.form.response
        //         });
        //     }

        //     that.item.sendTime = that.exercise.currentTime;
        //     that.item.status = 'sent';
        //     that.item.parent = oldItem._id;

        //     // Update the current one.
        //     await that.$http.patch(`/resource-request/${that.item._id}`, {
        //         sendTime: that.item.sendTime,
        //         status: that.item.status,
        //         parent: that.item.parent,
        //         responses: that.item.responses
        //     });

        //     that.form.response = '';
        // },
        // async cancel() {
        //     const that = this;

        //     // Clone the current task.
        //     const oldItem = _.cloneDeep(that.item);

        //     oldItem._id = undefined;
        //     oldItem.actionTime = that.exercise.currentTime;

        //     await that.$http.post(`/resource-request`, oldItem);

        //     if(that.form.response) {
        //         that.item.responses.push({
        //             role: that.$store.getters['sim/getRoleID'],
        //             comment: that.form.response
        //         });
        //     }

        //     that.item.sendTime = that.exercise.currentTime;
        //     that.item.status = 'cancelled';
        //     that.item.parent = oldItem._id;

        //     // Update the current one.
        //     await that.$http.patch(`/resource-request/${that.item._id}`, {
        //         sendTime: that.item.sendTime,
        //         status: that.item.status,
        //         parent: that.item.parent,
        //         responses: that.item.responses
        //     });

        //     that.form.response = '';
        // },
        // async accept() {
        //     const that = this;

        //     if(that.item.resourceType === "resource") {
        //         // oldItem.received = oldItem.quantity;

        //         // Create an old inventory and get the new _id.
        //         const oldInventory = _.cloneDeep(that.inventory);
        //         oldInventory._id = undefined;
        //         oldInventory.actionTime = that.exercise.currentTime;
        //         oldInventory._id = await that.$http.post(`/inventory`, oldInventory).then(response => response.data._id);

        //         // Update the inventory for the current inventory item and save it.
        //         that.inventory.usage += that.item.quantity;
        //         that.inventory.sendTime = that.exercise.currentTime;
        //         await that.$http.patch(`/inventory/${that.inventory._id}`, {
        //             sendTime: that.inventory.sendTime,
        //             usage: that.inventory.usage
        //         });

        //         // Get all the resource requests for this exercise.
        //         // Filter by those that are using this inventory.
        //         // Create old copies, updating the actionTime/inventory->oldInventory/
        //         const allRR = await that.$http.get(`/resource-request`, {params: {exercise: that.exercise._id}}).then(response => response.data);
        //         _.each(allRR, async (rr) => {
        //             // If an RR has the ID of the current
        //             if(rr.inventory === that.inventory._id) {
        //                 await that.$http.patch(`/resource-request/${rr._id}`, rr);

        //                 rr._id = undefined;
        //                 rr.actionTime = oldInventory.actionTime;
        //                 rr.inventory = oldInventory._id;
        //                 await that.$http.post(`/resource-request`, rr);
        //             }
        //         });

        //         // Update the current RR with the number received.
        //         that.item.received = that.item.quantity;
        //     }

        //     if(that.form.response) {
        //         that.item.responses.push({
        //             role: that.$store.getters['sim/getRoleID'],
        //             comment: that.form.response
        //         });
        //     }
        //     that.item.sendTime = that.exercise.currentTime;
        //     that.item.status = 'accepted';
        //     // that.parent = oldItem;

        //     // Update the current one.
        //     await that.$http.patch(`/resource-request/${that.item._id}`, {
        //         sendTime: that.item.sendTime,
        //         status: that.item.status,
        //         // parent: oldItem._id,
        //         responses: that.item.responses,
        //         received: that.item.received
        //     });
        // },
        // async received() {
        //     const that = this;

        //     // Clone the current task.
        //     const oldItem = _.cloneDeep(that.item);

        //     oldItem._id = undefined;
        //     oldItem.actionTime = that.exercise.currentTime;

        //     await that.$http.post(`/resource-request`, oldItem);

        //     if(that.form.response) {
        //         that.item.responses.push({
        //             role: that.$store.getters['sim/getRoleID'],
        //             comment: that.form.response
        //         });
        //     }

        //     that.item.sendTime = that.exercise.currentTime;
        //     that.item.status = 'received';
        //     that.item.parent = oldItem._id;

        //     // Update the current one.
        //     await that.$http.patch(`/resource-request/${that.item._id}`, {
        //         sendTime: that.item.sendTime,
        //         status: that.item.status,
        //         parent: that.item.parent,
        //         responses: that.item.responses
        //     });

        //     that.form.response = '';
        // },
        // async returned() {
        //     const that = this;

        //     // Clone the current task.
        //     const oldItem = _.cloneDeep(that.item);

        //     oldItem._id = undefined;
        //     oldItem.actionTime = that.exercise.currentTime;

        //     await that.$http.post(`/resource-request`, oldItem);

        //     if(that.form.response) {
        //         that.item.responses.push({
        //             role: that.$store.getters['sim/getRoleID'],
        //             comment: that.form.response
        //         });
        //     }

        //     that.item.sendTime = that.exercise.currentTime;
        //     that.item.status = 'returned';
        //     that.item.parent = oldItem._id;

        //     // Update the current one.
        //     await that.$http.patch(`/resource-request/${that.item._id}`, {
        //         sendTime: that.item.sendTime,
        //         status: that.item.status,
        //         parent: that.item.parent,
        //         responses: that.item.responses
        //     });

        //     that.form.response = '';
        // },
        // async deny() {
        //     const that = this;

        //     // Clone the current task.
        //     const oldItem = _.cloneDeep(that.item);

        //     oldItem._id = undefined;
        //     oldItem.actionTime = that.exercise.currentTime;

        //     await that.$http.post(`/resource-request`, oldItem);

        //     if(that.form.response) {
        //         that.item.responses.push({
        //             role: that.$store.getters['sim/getRoleID'],
        //             comment: that.form.response
        //         });
        //     }

        //     that.item.sendTime = that.exercise.currentTime;
        //     that.item.status = 'denied';
        //     that.item.parent = oldItem._id;

        //     // Update the current one.
        //     await that.$http.patch(`/resource-request/${that.item._id}`, {
        //         sendTime: that.item.sendTime,
        //         status: that.item.status,
        //         parent: that.item.parent,
        //         responses: that.item.responses
        //     });

        //     that.form.response = '';
        // },
        // async complete() {
        //     const that = this;

        //     if(that.item.resourceType === "resource") {
        //         // oldItem.received = oldItem.quantity;

        //         // Create an old inventory and get the new _id.
        //         const oldInventory = _.cloneDeep(that.inventory);
        //         oldInventory._id = undefined;
        //         oldInventory.actionTime = that.exercise.currentTime;
        //         oldInventory._id = await that.$http.post(`/inventory`, oldInventory).then(response => response.data._id);

        //         // Update the inventory for the current inventory item and save it.
        //         that.inventory.usage -= that.item.quantity;// @change - Change to that.item.received.
        //         that.inventory.sendTime = that.exercise.currentTime;
        //         await that.$http.patch(`/inventory/${that.inventory._id}`, {
        //             sendTime: that.inventory.sendTime,
        //             usage: that.inventory.usage
        //         });

        //         // Get all the resource requests for this exercise.
        //         // Filter by those that are using this inventory.
        //         // Create old copies, updating the actionTime/inventory->oldInventory/
        //         const allRR = await that.$http.get(`/resource-request`, {params: {exercise: that.exercise._id}}).then(response => response.data);
        //         _.each(allRR, async (rr) => {
        //             // If an RR has the ID of the current
        //             if(rr.inventory === that.inventory._id) {
        //                 await that.$http.patch(`/resource-request/${rr._id}`, rr);

        //                 rr._id = undefined;
        //                 rr.actionTime = oldInventory.actionTime;
        //                 rr.inventory = oldInventory._id;
        //                 await that.$http.post(`/resource-request`, rr);
        //             }
        //         });

        //         // Update the current RR with the number received.
        //         that.item.received = that.item.quantity;
        //     }

        //     if(that.form.response) {
        //         that.item.responses.push({
        //             role: that.$store.getters['sim/getRoleID'],
        //             comment: that.form.response
        //         });
        //     }
        //     that.item.sendTime = that.exercise.currentTime;
        //     that.item.status = 'completed';
        //     // that.parent = oldItem;

        //     // Update the current one.
        //     await that.$http.patch(`/resource-request/${that.item._id}`, {
        //         sendTime: that.item.sendTime,
        //         status: that.item.status,
        //         // parent: oldItem._id,
        //         responses: that.item.responses
        //     });
        // },
        async setStatus(status) {
            const that = this;

            if(that.item.resourceType === "resource") {
                // oldItem.received = oldItem.quantity;

                // Create an old inventory and get the new _id.
                const oldInventory = _.cloneDeep(that.inventory);
                oldInventory._id = undefined;
                oldInventory.actionTime = that.exercise.currentTime;
                oldInventory._id = await that.$http.post(`/inventory`, oldInventory).then(response => response.data._id);

                // Update the inventory for the current inventory item and save it.
                if(status == "accepted") {
                    that.inventory.usage += that.form.quantity;// @change - Change to that.item.received.
                    // Update the current RR with the number received.
                    that.item.received = that.form.quantity;
                } else if(status == "completed") {
                    that.inventory.usage -= that.item.received;
                }
                that.inventory.sendTime = that.exercise.currentTime;
                await that.$http.patch(`/inventory/${that.inventory._id}`, {
                    sendTime: that.inventory.sendTime,
                    usage: that.inventory.usage
                });

                // Get all the resource requests for this exercise.
                // Filter by those that are using this inventory.
                // Create old copies, updating the actionTime/inventory->oldInventory/
                const allRR = await that.$http.get(`/resource-request`, {params: {exercise: that.exercise._id}}).then(response => response.data);
                _.each(allRR, async (rr) => {
                    // If an RR has the ID of the current
                    if(rr.inventory === that.inventory._id) {
                        // Update the sentTime for the current ones.
                        await that.$http.patch(`/resource-request/${rr._id}`, {
                            sendTime: that.exercise.currentTime
                        });

                        // Create a copy with oldInventory.
                        rr._id = undefined;
                        rr.actionTime = oldInventory.actionTime;
                        rr.inventory = oldInventory._id;
                        await that.$http.post(`/resource-request`, rr);
                    }
                });

                that.item.sendTime = that.exercise.currentTime;
                that.item.status = status;
                // that.parent = oldItem;
                // @change - Figure out how to get the oldItem._id from the loop above.
                // that.item.parent = oldItem._id;

                if(that.form.response) {
                    that.item.responses.push({
                        role: that.$store.getters['sim/getRoleID'],
                        comment: that.form.response
                    });
                }

                const postData = {
                    sendTime: that.item.sendTime,
                    status: that.item.status,
                    responses: that.item.responses
                }
                if(status == "accepted") {
                    postData.received = that.form.quantity;
                }

                // Update the current one.
                await that.$http.patch(`/resource-request/${that.item._id}`, postData);
            } else {
                // Clone the current task.
                const oldItem = _.cloneDeep(that.item);

                oldItem._id = undefined;
                oldItem.actionTime = that.exercise.currentTime;
                oldItem._id = await that.$http.post(`/resource-request`, oldItem).then(response => response.data._id);

                if(that.form.response) {
                    that.item.responses.push({
                        role: that.$store.getters['sim/getRoleID'],
                        comment: that.form.response
                    });
                }

                that.item.sendTime = that.exercise.currentTime;
                that.item.status = status;
                that.item.parent = oldItem._id;

                const postData = {
                    sendTime: that.item.sendTime,
                    status: that.item.status,
                    parent: that.item.parent,
                    responses: that.item.responses
                }
                if(status == "accepted") {
                    postData.received = that.form.quantity;
                }

                // Update the current one.
                await that.$http.patch(`/resource-request/${that.item._id}`, postData);
            }

            that.form.response = '';
        }
    },
};
</script>
