<template>
  <b-badge :variant="variant">
    <i class="fas fa-clock tw-mr-1"></i>
    <ShowSendTime
      :exercise="exercise"
      :item="item"
    />
  </b-badge>
</template>

<script>

import * as _ from 'lodash';

export default {
  name: "ShowSendTimeBadge",
  mixins: [],
  components: {},
  props: {
    exercise: {
        type: Object,
        required: false,
        default: Object,
    },
    item: {
        type: Object,
        required: true
    },
  },
  data: function () {
    return {};
  },
  computed: {
    variant: function () {
      const that = this;

      const sendTime = (that.item.updateTime) ? that.item.updateTime : that.item.sendTime;

      // If from library.
      if(!that.exercise._id || that.exercise._id != that.item.exercise) {
        return "secondary";
      } else if (_.isNumber(that.item.deletedTime)) {
        return "dark";
      } else if (sendTime < that.exercise.currentTime) {
        return "success";
      } else if (sendTime == that.exercise.currentTime) {
        return "primary";
      } else {
        return "secondary";
      }
    },
  },
};
</script>
