var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'ni ni-shop text-primary',
          path: '/dashboard'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Users',
          icon: 'ni ni-archive-2 text-green',
          path: '/users'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Profile',
          icon: 'ni ni-archive-2 text-green',
          path: '/user/me'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Media Library',
          icon: 'ni ni-archive-2 text-green',
          path: '/media-library'
        }}}),_c('sidebar-item',{attrs:{"link":{
                name: 'Inject Library',
                icon: 'ni ni-ungroup text-orange'
                }}},_vm._l((_vm.libraryWidgets),function(widget){return _c('sidebar-item',{key:widget.slug,attrs:{"link":{ name: widget.title, path: `/library/${widget.slug}` }}})}),1)],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }