<template>
    <BroadcastList
        :exercise="exercise"
        type="social-media"
        v-on:removefromexercise="$emit('removefromexercise', $event)"
        v-on:editinexercise="$emit('editinexercise', $event)"
    />
</template>

<script>
import SocketIOMixin from '@/util/socketio';
export default {
    name: "SocialMediaList",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
    },
};
</script>
