<template>
    <div>
        <b-container fluid>
            <!-- <b-row v-for="(val, key) in rows" :key="key" class="tw-border-b-4 tw-py-2">
                <b-col v-if="!cols.length || (cols.length && cols.indexOf(key) >= 0)">{{key}}</b-col>
                <b-col v-if="!cols.length || (cols.length && cols.indexOf(key) >= 0)">{{val}}</b-col>
            </b-row> -->
            <b-row v-for="(val, key) in rows" :key="key" class="tw-border-b-4 tw-py-2">
                <b-col>{{key}}</b-col>
                <b-col>{{val}}</b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

import * as _ from 'lodash';

export default {
    mixins: [],
    components: {},
    props: {
        input: {
            type: Object,
            required: false,
            default: Object
        },
        cols: {
            type: Array,
            required: false,
            default: Array
        }
    },
    computed: {
        rows() {
            if(this.cols.length) {
                const that = this;
                const newObj = {};
                _.each(this.input, (val, key) => {
                    if(that.cols.indexOf(key) >= 0) {
                        newObj[key] = val;
                    }
                });
                return newObj;
            } else {
                return this.input;
            }
        }
    }
};
</script>
