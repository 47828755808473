<template>
    <!-- <b-card class="my-2" v-if="canShowAtCurrentTime(exercise, item) || isExerciseController()"> -->
    <b-card class="my-2" v-if="isItemVisible(exercise, $store.state.sim.role, item)">
      <!-- <b-card class="my-2"> -->
      <b-card-title>
        {{ item.title }}

        <!-- <b-badge class="float-right">
          <i class="fas fa-clock"></i>
          {{ secondsToFormattedSendTime(item.sendTime) }}
        </b-badge> -->
        <ShowSendTimeBadge
          class="float-right"
          :exercise="exercise"
          :item="item"
        />
      </b-card-title>

      <b-card-text>
        {{ item.message }}
      </b-card-text>

      <b-card-text v-if="item.role">
        <span
          class="badge badge-primary mr-1"
        >
          <RoleInfo :roleId="item.role" />
        </span>
      </b-card-text>

      <b-card-text v-if="item.roles && item.roles.length">
        <span
          class="badge badge-secondary mr-1"
          v-for="role in item.roles"
          :key="role"
        >
          <RoleInfo :roleId="role" />
        </span>
      </b-card-text>

      <!-- <b-card-text>
        <ShowObject :input="item" :cols="['sendTime', 'actionTime']" />
      </b-card-text> -->

      <!-- <b-card-text v-if="!exercise._id || (exercise._id && isExerciseController() && (!item.exercise || (item.exercise && exercise.currentTime < item.sendTime)))"> -->
      <b-card-text v-if="isItemButtonOptionsVisible(exercise, $store.state.sim.role, item)">
        <hr />
        <ItemButtonOptions
          :widget="$options.name"
          :item="item"
          :exercise="exercise"
          v-on:details="$emit('details', $event)"
          v-on:edit="$emit('edit', $event)"
          v-on:duplicate="$emit('duplicate', $event)"
          v-on:removefromexercise="remove($event)"
          v-on:editinexercise="$emit('editinexercise', $event)"
          v-on:addtoexercise="$emit('addtoexercise', $event)"
        />
      </b-card-text>

      <!-- <b-card-text>
        <ShowObject :input="item" :cols="['createdTime', 'deletedTime', 'sendTime']" />
        <ShowObject :input="$store.state.sim.role" :cols="['controller', 'name']" />
      </b-card-text> -->
    </b-card>
</template>

<script>
import { mapState } from "vuex";
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "Inject",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    input: {
      type: Object,
      required: false,
      default: null,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
    view: {
      type: String,
      required: false,
      default: "details",
    },
  },
  data: function() {
    return {
      item: {},
      show: false,
    };
  },
  created() {
    if (this.id) {
      this.init();
    } else if (this.input) {
      this.item = this.input;
    }
  },
  watch: {
    id: function(val) {
      if (val) {
        this.init();
      }
    },
    input: function(val) {
      if (val) {
        this.item = val;
      }
    },
  },
  mounted: function() {},
  methods: {
    canShow(view) {
      return this.show || this.views.indexOf(view) >= 0;
    },
    async init() {
      this.item = await this.$http.get(`/inject/${this.id}`).then((response) => response.data);
    },
    remove: async function() {
      await that.$http.patch(`/inject/${this.item._id}`, {deletedTime: this.$store.getters['sim/currentTime']});

      this.$notify({
        text: "Deleted.",
        duration: 10000,
        type: "success",
      });
    },
  },
};
</script>
