<template>
    <b-card class="my-2" v-if="canShow && isItemVisible(exercise, $store.state.sim.role, item)">
        <b-card-title>
            <i class="fas fa-bullhorn" v-if="item.roles.length > 0"></i>
            <i class="fas fa-globe" v-else></i>
            {{ item.title }}

            <ShowSendTimeBadge
            class="float-right"
            :exercise="exercise"
            :item="item"
            />
        </b-card-title>

        <b-card-text class="tw-text-justify">
            {{ item.message }}
        </b-card-text>

        <b-card-text v-if="item.roles && item.roles.length">
            <span
                class="badge badge-secondary mr-1"
                v-for="role in item.roles"
                :key="role"
            >
                <RoleInfo :roleId="role" />
            </span>
        </b-card-text>

        <b-card-text
            class="text-center"
            v-if="canShowOptions && isItemActionVisible(exercise, $store.state.sim.role, item)"
        >
            <b-button variant="primary" class="mx-1" @click="read()">
                Read
            </b-button>
            <b-button variant="outline-warning" class="mx-1" @click="setSnooze(5)">
                Snooze for 5 minutes
            </b-button>
            <b-button variant="outline-warning" class="mx-1" @click="setSnooze(10)">
                Snooze for 10 minutes
            </b-button>
        </b-card-text>
    </b-card>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "MySimInject",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        input: {
            type: Object,
            required: false,
            default: null,
        },
        id: {
            type: String,
            required: false,
            default: null,
        },
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
    },
    data: function () {
        return {
            item: {},
            snooze: false,
        };
    },
    watch: {
        id: function (val) {
            if (val) {
                this.init();
            }
        },
        input: function (val) {
            if (val) {
                this.item = val;
            }
        },
    },
    computed: {
        canShow() {
            // Hide if snoozed.
            if(this.snooze) {
                return false;
            }

            // Hide if not to the current role.
            if(this.item.roles.length && this.item.roles.indexOf(this.$store.getters['sim/getRoleID']) < 0) {
                return false;
            }

            return true;
        },
        canShowOptions() {
            if(!this.$store.state.sim.role._id || this.$store.state.sim.role.controller) {
                return false;
            }

            if(this.item.acknowledged.indexOf(this.$store.getters['sim/getRoleID']) < 0) {
                return true;
            }

            return false;
        }
    },
    created() {
        if (this.id) {
            this.init();
        } else if (this.input) {
            this.item = this.input;
            this.item.snooze = false;
        }
    },
    mounted: function () {},
    methods: {
        async init() {
            const that = this;
            const response = await that.$http.get(`/inject/${this.id}`);
            that.item = response.data;
            that.item.snooze = false;
        },
        setSnooze(time) {
            const that = this;
            that.snooze = true;

            setTimeout(() => {
                that.snooze = false;

                that.$notify({
                    text: that.item.title,
                    duration: 10000,
                    type: "success",
                });
            }, time * 1000);
        },
        async read() {
            const that = this;
            await that.$http.patch(`/inject/${that.item._id}`, {
                acknowledged: [that.$store.getters['sim/getRoleID']],
            });
        }
    },
};
</script>
