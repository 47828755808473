<template>
    <div>
        <StatusBoardStatus
            v-for="item in sortedItemUpdateList"
            :key="item._id"
            :item="item"
            :exercise="exercise"
            :status-board-status-items="statusBoardStatusItems"
            v-on:removefromexercise="$emit('removefromexercise', $event)"
            v-on:editinexercise="$emit('editinexercise', $event)"
        />
    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "StatusBoardStatusList",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            socketEventName: "",
            statusBoardStatusItems: [],
        };
    },
    computed: {},
    created: function () {},
    mounted: function () {
        const that = this;

        that.find();

        that.socketIOSubscribe({
            name: `${that.exercise._id}-status-board-list`
        });

        that.socketIOSubscribe({
            name: `${that.exercise._id}-status-board-status-list`,
            callback: (data) => {
                const index = _.findIndex(that.statusBoardStatusItems, (o) => {
                    return o._id == data._id;
                });

                if (index >= 0) {
                    that.statusBoardStatusItems.splice(index, 1);
                }

                that.statusBoardStatusItems.push(data);
            }
        });
    },
    methods: {
        find: async function () {
            const that = this;

            that.itemList = await that.$http.get(
                `/status-board?exercise=${that.exercise._id}`
            ).then((response) => {
                return response.data;
            });

            that.statusBoardStatusItems = await that.$http.get(
                `/status-board-status?exercise=${that.exercise._id}`
            ).then((response) => {
                return response.data;
            });
        },
    },
    beforeRouteLeave(to, from, next) {
        // this.sockets.unsubscribe(this.socketEventName);
        // this.sockets.unsubscribe(
        //     `${this.exercise._id}-remove-status-board-list`
        // );
    },
};
</script>
