<template>
  <div>
    

    <div class="text-right" v-if="exercise._id && !item.exercise">

      <b-button
        variant="outline-secondary"
        class="mx-1"
        @click="$emit('addtoexercise', item)"
      >
        <i class="fas fa-plus"></i> Add To Exercises
      </b-button>
    </div>

    <div class="text-right" v-else-if="item.exercise">

      <b-button
            variant="outline-danger"
            class="mx-1"
            @click="$emit('removefromexercise', item)"
        >
            <i class="fas fa-times"></i> Remove From Exercise
        </b-button>
        
        <b-button
            variant="outline-success"
            class="mx-1"
            @click="$emit('editinexercise', item)"
        >
            <i class="fas fa-edit"></i> Edit
        </b-button>

        <slot name="extra-in-exercise-options" />
    </div>

    <div class="text-right" v-else>
        <!-- 
            :to="{ name: 'library-detail', params: { id: item._id } }"
         -->
      <b-button
        variant="outline-secondary"
        class="mx-1"
        squared
        @click="$emit('details', item)"
      >
        <i class="fas fa-file"></i> 
      </b-button>
      <!-- 
          :to="{
          name: 'library-form-edit',
          params: { form: 'edit', id: item._id },
        }"
       -->
      <b-button
        variant="outline-secondary"
        class="mx-1"
        squared
        @click="$emit('edit', item)"
      >
        <i class="fas fa-edit"></i> 
      </b-button>
      <!-- 
          :to="{
          name: 'library-form-copy',
          params: { form: 'copy', id: item._id },
        }"
       -->
      <b-button
        variant="outline-secondary"
        class="mx-1"
        squared
        @click="$emit('duplicate', item)"
      >
        <i class="fas fa-copy"></i> 
      </b-button>
    </div>
  </div>
</template>

<script>

export default {
  name: "ItemButtonOptionsComponent",
  mixins: [],
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
    widget: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: function () {
    return {};
  },
  computed: {},
  created: function () {},
  mounted: function () {},
  methods: {
  },
};
</script>
