<template>
    <BaseItemWidget>
        <Service v-for="item in itemList" :key="item._id" :input="item"
            v-on:removefromexercise="$emit('removefromexercise', $event)"
            v-on:editinexercise="$emit('editinexercise', $event)" />
    </BaseItemWidget>
</template>

<script>

import HandleErrors from "@/util/handle-errors";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "ServiceList",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            socketEventName: ""
        };
    },
    computed: {},
    created: function () { },
    mounted: function () {
        const that = this;

        // that.listenExerciseList(that.exercise, 'service');

        that.find();

        that.socketIOSubscribe({
            name: `${that.exercise._id}-service-list`
        });

        that.subscribe(
            `${that.exercise._id}-remove-service-list`,
            function (data) {
                that.find();
            }
        );
    },
    methods: {
        find: async function () {
            const that = this;
            that.itemList = await that.$http.get("/service", {
                params: { keyword: that.keyword, exercise: that.exercise._id},
            }).then((response) => {
                return response.data;
            });
        },
    },
    beforeRouteLeave(to, from, next) {
        this.sockets.unsubscribe(this.socketEventName);
        this.sockets.unsubscribe(`${this.exercise._id}-remove-service-list`);
    },
};
</script>
