<template>
    <b-card class="my-2" :bg-variant="bgVariant()" v-if="isItemVisible(exercise, $store.state.sim.role, item)">
      <b-card-text class="small text-muted">
        <i
          v-if="$store.state.user.user._id == item.user"
          class="fas fa-times tw-cursor-pointer float-right"
          title="Delete"
          @click="remove(item)"
        ></i>
        <ShowSendTimeBadge
          class="float-right mr-2"
          :exercise="exercise"
          :item="item"
        />
      </b-card-text>
      <b-card-text>
        {{ item.note }}
      </b-card-text>
      <b-card-text class="small text-muted">
        <FormatDateTime :exercise="exercise" :sendTime="item.sendTime" />
      </b-card-text>
    </b-card>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "Scribe",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    input: {
      type: Object,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    exercise: {
      type: Object,
      required: true,
      default: Object,
    },
  },
  data: function() {
    return {
      socketEventName: "",
      note: "",
    };
  },
  computed: {
    sortedItems() {
      const that = this;
      return _.sortBy(that.items, [
        function(o) {
          return -o.sendTime;
        },
      ]);
    },
  },
  watch: {
    id: function(val) {
      if (val) {
        this.init();
      }
    },
    input: function(val) {
      if (val) {
        this.item = val;
      }
    },
  },
  created: function() {
    if (this.id) {
      this.init();
    } else if (this.input) {
      this.item = this.input;
    }
  },
  mounted: function() {},
  methods: {
    bgVariant: function() {
      return (this.$store.state.user.user._id == this.item.user) ? "info" : "default";
    },
    textVariant: function(input) {
      return "default";
    },
    init() {
      const that = this;
      that.$http.get(`/scribe/${this.id}`)
        .then((response) => {
          that.item = response.data;
        })
        .catch((error) => {
          HandleErrors.HandleErrors(error);
        });
    },
    remove: async function() {
      const that = this;

      await that.$http.patch(`/scribe/${that.item._id}`, { status: "deleted", actionTime: that.exercise.currentTime });

      that.$notify({
        text: "Deleted.",
        duration: 10000,
        type: "success",
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.sockets.unsubscribe();
  },
};
</script>
