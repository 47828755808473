<template>
    <div class="mt-2">
        <div class="mb-3">
            <b-form-input
                id="inject-search"
                size="lg"
                placeholder="Search Locations"
                v-model="keyword"
                @keyup="find()"
            ></b-form-input>
        </div>
        <sort-inject :inject-sort="sortByVal" v-on:sort="setSort($event)"></sort-inject>
        <Map
            v-for="item in sortedBySort"
            :key="item._id"
            :input="item"
            :exercise="exercise"
            v-on:details="$emit('details', $event)"
            v-on:edit="$emit('edit', $event)"
            v-on:duplicate="$emit('duplicate', $event)"
            v-on:addtoexercise="$emit('addtoexercise', $event)"
            view="summary"
        />
    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import * as _ from "lodash";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "LocationLibrary",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
    },
    data: function () {
        return {
            socketEventName: "",
            keyword: ''
        };
    },
    computed: {},
    created() {
        const that = this;

        that.find();

        that.socketIOSubscribeLibraryHelper({
            name: `map`
        });
    },
    mounted() {},
    methods: {
        find: async function () {
            const that = this;
            that.itemList = await that.$http.get("/disaster-map", {params: {keyword: that.keyword}}).then((response) => response.data);
        },
    },
    destroyed() {
        // this.unsubscribe(this.socketEventName);
    },
};
</script>
