<template>
  <div>
    <FormAction :id="id" :copy="copy" />
    <b-form @submit="onSubmit" @reset="onReset">
      <b-form-group id="name-group" label="SimCell Name:" label-for="name">
        <b-form-input
          id="name"
          v-model="form.name"
          required
          placeholder="Enter Name"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="description-group"
        label="Description:"
        label-for="description"
      >
        <b-form-textarea
          id="description"
          v-model="form.description"
          placeholder="Enter Description"
          rows="6"
        ></b-form-textarea>
      </b-form-group>

      <!-- <b-form-group
        id="role-group"
        label="Roles:"
        label-for="roles"
      >

      <b-form-checkbox-group
        id="roles"
        v-model="form.roles"
        :options="roles"
        value-field="_id"
        text-field="name"
        name="roles"
        stacked
      ></b-form-checkbox-group>

      </b-form-group> -->

      <b-form-group id="role-group" label="Roles:" label-for="roles">
        <multiselect
          v-model="form.roles"
          :multiple="true"
          :options="roles"
          track-by="_id"
          label="name"
        ></multiselect>
      </b-form-group>

      <FormButtonOptions :exercise="exercise" :id="id" :copy="copy" />
    </b-form>
  </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import * as _ from 'lodash';

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "SimcellForm",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    input: {
      type: Object,
      required: false,
      default: Object,
    },
    copy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      item: null,
      form: {
        name: null,
        roles: [],
      },
      roles: [],
    };
  },
    watch: {
        exercise: function (val) {
            if (val._id) {
                this.form.exercise = true;
            } else {
                this.form.exercise = false;
            }
        },
        id: function (val) {
            if (val) {
                this.init();
            } else {
                this.item = {};
                this.form = {};
            }
        },
        input: function (val) {
            this.item = this.input;
            this.form = _.extend({}, this.input);
        },
    },
  computed: {
    ...mapState("sample", ["sample"]),
  },
  created: function () {
    if (this.id) {
      this.init();
    } else {
      this.find();
    }
  },
  mounted: function () {},
  methods: {
    async init() {
      const that = this;

      that.find();

      that.item = await that.$http.get(`/sim-cell/${that.id}`).then((response) => response.data);
      that.form = _.extend({}, that.item);
      that.form._id = that.copy ? undefined : that.form._id;

      that.form.roles = _.filter(that.roles, (role) => {
        return that.item.roles.indexOf(role._id) >= 0;
      });
    },
    find: async function () {
      const that = this;
      let header = {};

      if(that.exercise._id && !that.copy) {
        header.params = {exercise: that.exercise._id};
      }

      that.roles = await that.$http.get('/role', header).then((response) => response.data);
    },
    onSubmit: async function (evt) {
      evt.preventDefault();

      const that = this;
      const data = _.clone(that.form);
      const newRoles = [];
      let simcell = null;

      data.exercise = that.exercise._id ? that.exercise._id : undefined;

      if(data.exercise) {
        data.sendTime = that.exercise.currentTime;
      }

      if (data._id) {
        simcell = await that.$http.patch(`/sim-cell/${data._id}`, data).then(response => response.data);
      } else {
        simcell = await that.$http.post('/sim-cell', data).then(response => response.data);

        if(data.exercise) {
          _.each(data.roles, async (role) => {
            role._id = undefined;
            role.exercise = data.exercise;
            role.simcell = simcell;

            if(data.exercise) {
              role.sendTime = that.exercise.currentTime;
            }

            const newRole = await that.$http.post('/role', role).then(response => response.data);
            newRoles.push(newRole._id);
          });

          await that.$http.patch(`/sim-cell/${simcell._id}`, {roles: newRoles});
        }
      }

      that.$bvToast.toast("Simcell Saved!", {
        title: "Success",
        variant: "success",
        solid: true,
      });

      that.$emit("form", simcell);
    },
    onReset: function (evt) {
      evt.preventDefault();
      this.$emit("form", this.item ? this.item : {});
    },
  },
};
</script>
