<template>
    <b-card v-if="isItemVisible(exercise, $store.state.sim.role, item)" class="mb-2">
        <b-card-title>
            {{ item.resource }}

            <!-- <b-badge class="float-right">
                <i class="fas fa-clock"></i>
                {{ secondsToFormattedSendTime(item.sendTime) }}
            </b-badge> -->

            <ShowSendTimeBadge
                class="float-right"
                :exercise="exercise"
                :item="item"
            />
            
            <!-- <b-badge class="float-right mr-2">
                {{exercise.currentTime}}: {{item.sendTime}} - {{item.actionTime}}
            </b-badge> -->
        </b-card-title>

        <b-card-text>
            {{ item.description }}
        </b-card-text>

        <b-card-text>
            
            <b-badge class="py-2 px-3 tw-m-2">
                Available:
            </b-badge>
            <b-badge variant="light" class="py-2 px-3 tw-m-2">
                {{ item.quantity - item.usage }} / {{ item.quantity }} {{ item.units }}
            </b-badge>
            

            <b-badge
                v-if="item.returnable"
                variant="primary"
                class="float-right"
            >
                returnable
            </b-badge>
        </b-card-text>

        <b-card-text v-if="canShowButtons()">
            <ItemButtonOptions
                :widget="$options.name"
                :item="item"
                :exercise="exercise"
                v-on:details="$emit('details', $event)"
                v-on:edit="$emit('edit', $event)"
                v-on:duplicate="$emit('duplicate', $event)"
                v-on:removefromexercise="remove($event)"
                v-on:editinexercise="$emit('editinexercise', $event)"
                v-on:addtoexercise="$emit('addtoexercise', $event)"
            />
        </b-card-text>
    </b-card>
</template>

<script>
import { mapState } from "vuex";
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "Inventory",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        input: {
            type: Object,
            required: false,
        },
        id: {
            type: String,
            required: false,
        },
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
    },
    data: function () {
        return {
            item: {},
        };
    },
    created() {
        if (this.id) {
            this.init();
        } else if (this.input) {
            this.item = this.input;
        }
    },
    watch: {
        id: function (val) {
            if (val) {
                this.init();
            }
        },
        input: function (val) {
            if (val) {
                this.item = val;
            }
        },
    },
    mounted: function () {},
    methods: {
        init() {
            const that = this;
            that.$http.get(`/inventory/${this.id}`)
                .then((response) => {
                    that.item = response.data;
                })
                .catch((error) => {
                    HandleErrors.HandleErrors(error);
                });
        },
        // remove: function () {
        //     const that = this;
        //     that.$http.delete(`/inventory/${that.item._id}`)
        //         .then((response) => {
        //             that.$notify({
        //                 text: "Deleted.",
        //                 duration: 10000,
        //                 type: "success",
        //             });

        //             that.$emit("removefromexercise", that.item);
        //         })
        //         .catch((error) => {
        //             HandleErrors.HandleErrors(error);
        //         });
        // },
        canShowButtons: function() {
            // If no exercise, return true.
            if(!this.item.exercise && !this.exercise) {
                return true;
            }

            const res = !this.item.returnable || (this.item.returnable && this.item.usage == 0);

            return res && this.isItemButtonOptionsVisible2(this.exercise, this.$store.state.sim.role, this.item);
        },
        remove: async function() {
            await that.$http.patch(`/inventory/${this.item._id}`, {deletedTime: this.$store.getters['sim/currentTime']});

            this.$notify({
                text: "Deleted.",
                duration: 10000,
                type: "success",
            });
    },
    },
};
</script>
