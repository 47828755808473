<template>
  <div class="mt-2">
    <div class="mb-3">
      <b-form-input
        id="inject-search"
        size="lg"
        :placeholder="`Search ${type}`"
        v-model="keyword"
        @keyup="find()"
      ></b-form-input>
    </div>
    <sort-inject :inject-sort="sortByVal" v-on:sort="setSort($event)"></sort-inject>
    <Broadcast
      v-for="item in sortedBySort"
      :key="item._id"
      :input="item"
      :exercise="exercise"
      v-on:details="$emit('details', $event)"
      v-on:edit="$emit('edit', $event)"
      v-on:duplicate="$emit('duplicate', $event)"
      v-on:addtoexercise="$emit('addtoexercise', $event)"
      view="summary"
    />
  </div>
</template>

<script>

import * as moment from 'moment';
import * as _ from "lodash";
// import Broadcast from './Broadcast.vue';

import SocketIOMixin from '@/util/socketio';
export default {
  name: "BroadcastLibrary",
  mixins: [SocketIOMixin],
  // components: {Broadcast},
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
    type: {
      type: String,
      required: false,
      default: "broadcast",
    },
  },
  data: function () {
    return {
      socketEventName: "",
      keyword: "",
    };
  },
  computed: {
    // sortedItems() {
    //   const that = this;
    //   return _.sortBy(that.itemList, [
    //     function (o) {
    //       return -moment(o.createdAt).unix();
    //     },
    //   ]);
    // },
  },
  created() {
    const that = this;

    that.find();

    that.socketIOSubscribeLibraryHelper({
      name: this.type,
    });
  },
  destroyed() {
    // this.unsubscribe(this.socketEventName);
  },
  mounted() {},
  methods: {
    find: async function () {
      const that = this;
      that.itemList = await that.$http.get(`/media`, {
        params: {
          keyword: that.keyword,
          type: that.type,
        },
      }).then((response) => response.data);
    },
  },
  // beforeRouteLeave(to, from, next) {
  //     this.sockets.unsubscribe(this.socketEventName);
  // },
};
</script>
