<template>
  <div>
    <div v-for="it in statusBoardStatusItems" :key="it._id">
      <b-media v-if="it.statusBoard == item._id && isItemVisible(exercise, $store.state.sim.role, it)">

        <h5 class="mt-0">
          <CurrentTime
            v-if="exercise"
            :exercise="exercise"
            :exercise-date="exercise.startSimDate"
            :sendTime="it.sendTime"
          />

          <i
            v-if="isItemActionVisible(exercise, $store.state.sim.role, item, widgets.statusBoard.slug)"
            class="fas fa-pencil-alt tw-cursor-pointer tw-float-right"
            @click="$emit('editstatus', it)"
          ></i>
        </h5>
        <p>
          {{ it.comment }}
          {{ initShowAddStatus() }}
        </p>
      </b-media>
    </div>

    <!-- <b-container fluid v-if="showAddStatus">
            <b-row>
                <b-col class="tw-text-center">
                    <b-button @click="$emit('editstatus', {})">
                        Add Status
                    </b-button>
                </b-col>
            </b-row>
        </b-container> -->
  </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "StatusBoardCountStatus",
  mixins: [SocketIOMixin],
  components: {},
  data: function () {
    return {
      // showAddStatus: true
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
    statusBoardStatusItems: {
      type: Array,
      required: true,
    },
  },
  created() {},
  methods: {
    initShowAddStatus() {
      // this.showAddStatus = false;
      this.$emit("shownewform");
    },
  },
};
</script>
