<template>
  
  <BaseWidget>
    <template v-slot:title>
      Significant Events
    </template>

    <SignificantEventForm v-if="exercise.name" :exercise="exercise" />
    <SignificantEventList v-if="exercise.name" :exercise="exercise" />

  </BaseWidget>
</template>

<script>
// import {
//     HTTP
// } from '@/util/http-common';
// import HandleErrors from '@/util/handle-errors';

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "SignificantEventWidget",
  mixins: [WidgetMixin],
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object
    },
    fullScreen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      show: true,
      fullWidth: false,
      title: "Sample Page"
    };
  },
  computed: {
    ...mapState("sample", ["sample"])
  },
  created: function() {
    //console.log(this.$options.name);
  },
  mounted: function() {},
  methods: {
    get: function() {
      // that.$http.get('/').then((response) => {
      //     let something = response.data;
      // }).catch((error) => {
      //     HandleErrors.HandleErrors(error);
      // });
    },
    find: function() {},
    patch: function() {},
    delete: function() {}
  }
};
</script>
