<template>
  <b-card tag="article" class="my-2" v-if="isItemVisible(exercise, $store.state.sim.role, item)">
    <b-card-title>
      {{ item.name }}

      <ShowSendTimeBadge
          class="float-right"
          :exercise="exercise"
          :item="item"
        />
    </b-card-title>

    <b-card-text>
      {{ item.description }}
    </b-card-text>

    <b-card-text v-if="!detailView">
      <b-badge
          class="mr-1"
          v-for="column in item.columns"
          :key="column"
        >
          {{ column }}
        </b-badge>
    </b-card-text>

    <!-- <b-card-text v-if="!exercise._id || exercise._id && isExerciseController()"> -->
    <!-- <b-card-text v-if="!detailView && (!exercise._id || (exercise._id && isExerciseController()))"> -->
      <b-card-text>

      <ItemButtonOptions
        v-if="!detailView && isItemButtonOptionsVisible(exercise, $store.state.sim.role, item)"
        :widget="$options.name"
        :item="item"
        :exercise="exercise"
        v-on:details="$emit('details', $event)"
        v-on:edit="$emit('edit', $event)"
        v-on:duplicate="$emit('duplicate', $event)"
        v-on:removefromexercise="remove($event)"
        v-on:editinexercise="$emit('editinexercise', $event)"
        v-on:addtoexercise="$emit('addtoexercise', $event)"
      >
        <!-- <template v-slot:extra-in-exercise-options>
          <b-button
            variant="outline-success"
            class="mx-1"
            @click="$emit('selectedspreadsheet', item)"
          >
            <i class="fas fa-edit"></i> View1
          </b-button>
        </template> -->
      </ItemButtonOptions>

      <b-button
         v-if="!detailView && item.exercise == exercise._id && item.sendTime <= exercise.currentTime"
            variant="outline-success"
            class="mx-1 float-right"
            @click="$emit('selectedspreadsheet', item)"
          >
            <i class="fas fa-table"></i> View
      </b-button>

      <b-button
         v-if="!detailView && item.exercise == exercise._id && item.sendTime <= exercise.currentTime"
            variant="outline-success"
            class="mx-1 float-right"
            @click="$emit('editinexercise', item)"
          >
            <i class="fas fa-edit"></i> Edit
      </b-button>

    </b-card-text>

    <!-- <b-card-text class="text-right" v-if="!detailView && exercise._id && $store.state.sim.role._id && !isExerciseController()"> -->
    <!-- <b-card-text class="text-right" v-if="!detailView && item.exercise == exercise._id">
      <b-button
            variant="outline-success"
            class="mx-1"
            @click="$emit('selectedspreadsheet', item)"
          >
            <i class="fas fa-edit"></i> View2
      </b-button>
    </b-card-text> -->

    <b-card-text v-if="detailView">
      <div class="tw-text-right tw-m-2" v-if="isItemActionVisible(exercise, $store.state.sim.role, item, widgets.spreadsheet.slug)">
        <b-button @click="addRow"> Add Row </b-button>
      </div>
      <ag-grid-vue
        style="width: 100%; height: 300px"
        class="ag-theme-alpine"
        :columnDefs="agGrid.columnDefs"
        :rowData="agGrid.rowData"
        @grid-ready="onGridReady"
        @cell-value-changed="onCellValueChanged"
        :stopEditingWhenCellsLoseFocus="true"
      >
      </ag-grid-vue>
    </b-card-text>

  </b-card>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
// import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue";

import * as _ from "lodash";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "Spreadsheet",
  mixins: [SocketIOMixin],
  components: {
    AgGridVue,
  },
  props: {
    input: {
      type: Object,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
    detailView: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      item: {},
      agGrid: {
        gridApi: null,
        gridColumnApi: null,
        columnDefs: null,
        rowData: null,
        options: null,
      },
    };
  },
  created() {
    if (this.id) {
      this.init();
    } else if (this.input) {
      this.item = this.input;
      this.initRows();
    }
  },
  watch: {
    id: function (val) {
      if (val) {
        this.init();
      }
    },
    input: function (val) {
      if (val) {
        this.item = val;
        this.initRows();
      }
    },
  },
  mounted: function () {},
  methods: {
    onGridReady(params) {
      this.agGrid.gridApi = params.api;
      this.agGrid.gridColumnApi = params.columnApi;
    },
    async addRow() {
      const that = this;
      const data = {
        exercise: that.exercise._id,
        spreadsheet: that.item._id,
        sendTime: that.exercise.currentTime,
        data: {},
      };

      _.each(that.agGrid.columnDefs, (item) => {
        data.data[item.field.split(".")[1]] = "";
      });

      await that.$http.post(`/spreadsheet-row`, data);
    },
    async onCellValueChanged(event) {
      const data = this.agGrid.rowData[event.rowIndex];
      data.data[event.colDef.field.split(".")[1]] = event.newValue;
      await this.$http.patch(`/spreadsheet-row/${data._id}`, data);
    },
    async init() {
      const that = this;
      that.item = await that.$http.get(`/spreadsheet/${this.id}`).then(
        (response) => response.data
      );
      that.initRows();
    },
    async initRows() {
      const that = this;

      // First unsubscribe.
      that.socketIOUnSubscribe();

      // Initialize the columns.
      that.agGrid.columnDefs = [];
      const editable = that.isMine(that.item, that.$store.state.sim.role);
      _.each(that.item.columns, (col) => {
        that.agGrid.columnDefs.push({
          headerName: col,
          field: "data." + col.toLowerCase().replace(" ", ""),
          editable: true,
          sortable: true,
          filter: true
        });
      });

      that.agGrid.rowData = await that.$http.get("/spreadsheet-row", {
        params: {
          exercise: that.exercise._id,
          spreadsheet: that.item._id,
        },
      }).then((response) => response.data);

      that.socketIOSubscribe({
        name: `${that.exercise._id}-spreadsheet-row-list`,
        callback: function(data) {
          const index = _.findIndex(that.agGrid.rowData, (o) => {
            return o._id == data._id;
          });

          if (index >= 0) {
            that.agGrid.rowData[index] = data;
          } else {
            that.agGrid.rowData.push(data);
          }
        }
      });
    },
    // remove: function () {
    //   const that = this;
    //   that.$http.delete(`/spreadsheet/${that.item._id}`)
    //     .then((response) => {
    //       that.$notify({
    //         text: "Deleted.",
    //         duration: 10000,
    //         type: "success",
    //       });

    //       that.$emit("removefromexercise", that.item);
    //     })
    //     .catch((error) => {
    //       HandleErrors.HandleErrors(error);
    //     });
    // },

    remove: async function() {
      await that.$http.patch(`/spreadsheet/${this.item._id}`, {deletedTime: this.$store.getters['sim/currentTime']});

      this.$notify({
        text: "Deleted.",
        duration: 10000,
        type: "success",
      });
    },
  },
};
</script>
