<template>
    <BaseSimWidget>
        <template v-slot:title>
            <i class="fas fa-pen-fancy"></i>
            Chat
        </template>

        <b-tabs v-model="tabIndex" id="" align="right" pills>
            <b-tab>
                <template #title>
                    <i class="fas fa-list"></i>
                </template>

                <ChatList :exercise="exercise" />
            </b-tab>

            <b-tab>
                <template #title>
                    <i class="fas fa-plus"></i>
                </template>

                <ChatForm
                    :exercise="exercise"
                    v-on:chatCreated="chatCreated($event)"
                />
            </b-tab>

            <b-tab>
                <template #title>
                    <i class="fas fa-question"></i>
                </template>

                <p>Help will be here.</p>
            </b-tab>
        </b-tabs>
    </BaseSimWidget>
</template>

<script>
// import {
//     HTTP
// } from '@/util/http-common';
// import HandleErrors from '@/util/handle-errors';

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "ChatSimWidget",
    mixins: [WidgetMixin],
    props: {
        exercise: {
            type: Object,
            required: true,
            default: Object,
        },
        fullScreen: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        return {
            show: true,
            fullWidth: false,
            title: "Chat",
            tabIndex: 0,
        };
    },
    computed: {},
    created: function () {},
    mounted: function () {},
    methods: {
        chatCreated(ev) {
            this.chatId = ev;
            this.tabIndex = 0;
        },
    },
};
</script>
