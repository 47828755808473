<template>
  <b-card class="my-2" v-if="isItemVisible(exercise, $store.state.sim.role, item)">
    <b-card-title>
      {{ item.name }}

      <ShowSendTimeBadge
          class="float-right"
          :exercise="exercise"
          :item="item"
        />
      <b-badge variant="primary" class="float-right mr-2">
        {{ item.type }}
      </b-badge>
    </b-card-title>

    <b-card-text>
      {{ item.description }}
    </b-card-text>

    <b-card-text v-if="isItemButtonOptionsVisible(exercise, $store.state.sim.role, item)">
      <ItemButtonOptions
        :widget="$options.name"
        :item="item"
        :exercise="exercise"
        v-on:details="$emit('details', $event)"
        v-on:edit="$emit('edit', $event)"
        v-on:duplicate="$emit('duplicate', $event)"
        v-on:removefromexercise="remove($event)"
        v-on:editinexercise="$emit('editinexercise', $event)"
        v-on:addtoexercise="$emit('addtoexercise', $event)"
      />
    </b-card-text>
  </b-card>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "StatusBoard",
  mixins: [SocketIOMixin],
  components: {},
  data: function() {
    return {
      item: {},
    };
  },
  props: {
    input: {
      type: Object,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
  },
  created() {
    if (this.id) {
      this.init();
    } else if (this.input) {
      this.item = this.input;
    }
  },
  watch: {
    id: function(val) {
      if (val) {
        this.init();
      }
    },
    input: function(val) {
      if (val) {
        this.item = val;
      }
    },
  },
  methods: {
    init() {
      const that = this;
      that.$http.get(`/status-board/${that.id}`)
        .then((response) => {
          that.item = response.data;
        })
        .catch((error) => {
          HandleErrors.HandleErrors(error);
        });
    },
    // remove: function() {
    //   const that = this;
    //   that.$http.delete(`/status-board/${that.item._id}`)
    //     .then((response) => {
    //       that.$notify({
    //         text: "Deleted.",
    //         duration: 10000,
    //         type: "success",
    //       });

    //       that.$emit("removefromexercise", that.item);
    //     })
    //     .catch((error) => {
    //       HandleErrors.HandleErrors(error);
    //     });
    // },

    remove: async function() {
      await that.$http.patch(`/status-board/${this.item._id}`, {deletedTime: this.$store.getters['sim/currentTime']});

      this.$notify({
        text: "Deleted.",
        duration: 10000,
        type: "success",
      });
    },
  },
};
</script>
