<template>
    <BaseItemWidget>
        <FormAction :id="id" :copy="copy" />
        <b-form @submit="onSubmit" @reset="onReset">
            <b-form-group id="name-group" label="Name:" label-for="name">
                <b-form-input
                    id="name"
                    v-model="form.name"
                    required
                    placeholder="Enter Status Board Title"
                ></b-form-input>
            </b-form-group>

            <b-form-group
                id="description-group"
                label="Description:"
                label-for="description"
            >
                <b-form-textarea
                    id="description"
                    v-model="form.description"
                    placeholder="Enter Description"
                    rows="6"
                ></b-form-textarea>
            </b-form-group>

            <b-form-group id="type-group" label="Type:" label-for="type">
                <b-form-select
                    id="type"
                    v-model="form.type"
                    :options="[
                        'used/capacity',
                        'out-of',
                        'count-up',
                        'count-down',
                        'percent',
                        'info',
                    ]"
                ></b-form-select>
            </b-form-group>

            <b-form-group
                id="send-time-group"
                label="Send Time:"
                label-for="send-time"
            >
                <b-form-input
                    id="send-time"
                    v-model="form.formattedSendTime"
                    :placeholder="secondsToFormattedSendTime($store.state.sim.exercise.currentTime)"
                    aria-describedby="send-time-input-live-help"
                ></b-form-input>
                <b-form-text id="send-time-input-live-help">
                    Default value is blank now (current time, or 0 seconds,
                    which is on exercise start). Otherwise, use the format
                    HH:MM:SS, MM:SS, SS
                </b-form-text>
            </b-form-group>

            <!-- <b-form-group id="exercise-group" v-if="exercise">
        <b-form-checkbox v-model="form.exercise" :value="exercise._id" unchecked-value="" class="mb-3">
          Add to {{ exercise.name }}
        </b-form-checkbox>
      </b-form-group>

      <div class="row">
        <div class="col">
          <b-button block variant="outline-primary" type="submit">Save</b-button>
        </div>
        <div class="col">
          <b-button block variant="outline-warning" type="reset">Clear</b-button>
        </div>
      </div> -->

            <FormButtonOptions :exercise="exercise" :id="id" :copy="copy" />
        </b-form>
    </BaseItemWidget>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import * as _ from 'lodash';

import SocketIOMixin from '@/util/socketio';
export default {
    name: "StatusBoardFormComponent",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
        id: {
            type: String,
            required: false,
            default: null,
        },
        input: {
            type: Object,
            required: false,
            default: Object,
        },
        copy: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        return {
            item: null,
            form: {
                name: null,
                description: null,
                type: null,
                exercise: null,
            },
            formattedSendTime: "",
            formattedSendTimePlaceholder: "now",
        };
    },
    computed: {
        ...mapState("sample", ["sample"]),
    },
    watch: {
        id: function (val) {
            if (val) {
                this.init();
            } else {
                this.item = {};
                this.form = {};
            }
        },
        input: function (val) {
            this.item = this.input;
            this.form = _.extend({}, this.input);
        },
    },
    created: function () {
        if (this.id) {
            this.init();
        }
    },
    mounted: function () {},
    methods: {
        init() {
            const that = this;
            that.$http.get(`/status-board/${that.id}`).then((response) => {
                that.item = response.data;

                that.form = _.extend({}, that.item);
                that.form._id = that.copy ? undefined : that.form._id;
                that.form.formattedSendTime = that.secondsToFormattedSendTime(that.form.sendTime);
            });
        },
        onSubmit: function (evt) {
            evt.preventDefault();

            const that = this;
            const data = _.clone(that.form);

            data.exercise = that.exercise ? that.exercise._id : undefined;

            data.sendTime = that.formattedToSecondsSendTime(that.form.formattedSendTime);

            let request = null;
            let url = `/status-board`;

            if (data._id) {
                request = that.$http.patch(`${url}/${data._id}`, data);
            } else {
                if(that.exercise._id) {
                    data.createdTime = that.exercise.currentTime;
                }

                request = that.$http.post(url, data);
            }

            request
                .then((response) => {
                    that.$bvToast.toast("Status Board Saved!", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                    });

                    // that.onReset(evt);
                    that.form = {};
                    that.$emit('form', response.data);
                })
                .catch((error) => {
                    HandleErrors.HandleErrors(error);
                });
        },
        onReset: function (evt) {
            evt.preventDefault();
            this.$emit('form', (this.item) ? this.item : {});
        },
    },
};
</script>
