<template>
    <BaseSimWidget id="resourceRequestWidget">
        <template v-slot:title>
            <i class="fas fa-truck"></i>
            Resource Requests
        </template>

        <b-tabs v-model="tabIndex" id="" align="right" pills>
            <!-- <b-tab>
                <template #title>
                    <i class="fas fa-list"></i>
                </template>

                <ResourceRequestList
                    :exercise="exercise"
                    v-on:editinexercise="editInExercise($event)"
                    v-on:selectresourcerequest="selectResourceRequest($event)"
                    v-on:leaveresourcerequest="leaveResourceRequest($event)"
                />
            </b-tab> -->

            <template #tabs-end v-if="!fullScreen">
        <!-- <b-nav-item>
          <i class="fas fa-chevron-down"></i>
        </b-nav-item> -->
        <b-nav-item>
          <i class="fas fa-expand-arrows-alt" @click.prevent="goFullScreen()"></i>
        </b-nav-item>
      </template>

            <b-tab :disabled="!hasPermission(widgets.resourceRequested.permission)">
                <template #title>
                    <i class="fas fa-inbox"></i>
                </template>

                <ResourceRequestedList
                    :exercise="exercise"
                    v-on:editinexercise="editInExercise($event)"
                    v-on:selectresourcerequest="selectResourceRequest($event)"
                    v-on:leaveresourcerequest="leaveResourceRequest($event)"
                />
            </b-tab>

            <b-tab>
                <template #title>
                    <i class="fas fa-list"></i>
                </template>

                <ResourceRequestList
                    :exercise="exercise"
                    v-on:editinexercise="editInExercise($event)"
                    v-on:selectresourcerequest="selectResourceRequest($event)"
                    v-on:leaveresourcerequest="leaveResourceRequest($event)"
                />
            </b-tab>

            <!-- :disabled="!isExerciseController() && !hasPermission(widgets.resourceRequest.slug)" -->
            <b-tab
                :disabled="isExerciseController()"
            >
                <template #title>
                    <i class="fas fa-plus"></i>
                </template>

                <ResourceRequestForm
                    :exercise="exercise"
                    :copy="copy"
                    :id="id"
                    v-on:form="formFinished($event)"
                />
            </b-tab>

            <b-tab>
                <template #title>
                    <i class="fas fa-question"></i>
                </template>

                <p>Help will be here.</p>
            </b-tab>

        </b-tabs>
    </BaseSimWidget>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "ResourceRequestWidget",
    mixins: [WidgetMixin],
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
        fullScreen: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        return {
            show: true,
            fullWidth: false,
            title: "Sample Page",
            addResourceRequest: false,
            newResourceRequest: false,
            id: null,
            copy: false,
            tabIndex: 0,
        };
    },
    computed: {
        ...mapState("sample", ["sample"]),
    },
    created: function () {},
    mounted: function () {},
    methods: {
        formFinished: function () {
            this.id = null;
            this.copy = false;
            this.tabIndex = 0;
        },
        addItemToExercise: function (item) {
            this.id = item._id;
            this.copy = true;
            this.tabIndex = 2;
        },
        editInExercise: function (item) {
            this.id = item._id;
            this.copy = false;
            this.tabIndex = 2;
        },
        selectResourceRequest: function (item) {
            const that = this;

            const data = { primaryUser: that.$store.state.user.user._id };
            that.$http.patch(
                `${that.widgets.resourcerequest.baseUrl}/${item._id}`,
                data
            ).then(function (response) {
                that.$store.commit("sim/setResourceRequest", response.data);
                console.log(that.$store.state.sim.resourcerequest);
            });
        },
        leaveResourceRequest: function (item) {
            const that = this;

            const data = { primaryUser: null };
            that.$http.patch(
                `${that.widgets.resourcerequest.baseUrl}/${item._id}`,
                data
            ).then(function () {
                that.$store.commit("sim/setResourceRequest", {});
            });
        },
        addItemToExercise: function (item) {
            this.id = item._id;
            this.copy = true;
            this.tabIndex = 2;
        },
    goFullScreen() {
      this.$emit('setfullscreenmodalwidget', 'ResourceRequestSimWidget');
    }
    },
};
</script>
