export default {
    namespaced: true,
    state: {
        user: {},
        customer: {},
        token: ''
    },
    getters: {
        isExerciseController(state) {
            return (state.user.systemRoles && state.user.systemRoles.length) ? true : false;
        },
        isAuthenticated(state) {
            return Boolean(state.token);
        }
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        setUser(state, user) {
            state.user = user;
        },
        setCustomer(state, customer) {
            state.customer = customer;
        },
        reset(state) {
            state.token = '';
            state.user = {};
            state.customer = {};
        }
    },
    actions: {
        logout({commit}) {
            commit('reset');
            window.localStorage.removeItem('token');
            window.localStorage.removeItem('user');
            window.localStorage.removeItem('customer');
        }
    },
}