<template>
  <div v-if="item._id" class="tw-mx-2">
    <b-img-lazy v-if="isImage()" v-bind="mainProps" :src="getUrl()" alt="Image 1"></b-img-lazy>

    <b-img-lazy v-if="isDoc()" v-bind="mainProps" :src="getUrl()" alt="Image 1"></b-img-lazy>

    <div v-if="isContent()" v-html="item.content"></div>

    <b-embed v-if="isVideo()" type="video" aspect="4by3" controls poster="poster.png">
      <source :src="getUrl()" :type="item.mimetype">
    </b-embed>
  </div>
</template>

<script>
import HandleErrors from "@/util/handle-errors";

import * as _ from "lodash";

export default {
  name: "RoleInfoComponent",
  mixins: [],
  components: {},
  props: {
    mediaLibraryId: {
      type: String,
      required: false,
      default: null
    },
    media: {
      type: Object,
      required: false,
      default: null
    },
    details: {
      type: Boolean,
      default: false
    },
    thumbnail: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      item: {},
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        rounded: true,
        thumbnail: true,
        blankColor: '#bbb',
        // width: 250,
        // height: 250,
        class: 'my-5'
      }
    };
  },
  computed: {},
  watch: {
    mediaLibraryId: function (val) {
      if(val) {
        this.get();
      }
    }
  },
  created: function() {
    this.mainProps.thumbnail = this.thumbnail;
    this.mainProps.fluidGrow = !this.thumbnail;

    if(this.media) {
      this.item = this.media;
    } else if(this.mediaLibraryId) {
      this.get();
    }
  },
  mounted: function() {},
  methods: {
    get() {
      const that = this;

      that.$http.get(`/media-library/${that.mediaLibraryId}`).then((response) => {
        that.item = response.data;
      }).catch((error) => {
        HandleErrors.HandleErrors(error);
      });
    },
    isImage() {
      const that = this;
      return _.startsWith(that.item.mimetype, 'image');
    },
    isContent() {
      const that = this;
      return Boolean(that.item.content);
    },
    isDoc() {
      const that = this;
      return !that.isImage() && !that.isContent() && !that.isVideo();
    },
    isVideo() {
      const that = this;
      return _.startsWith(that.item.mimetype, 'video');
    },
    getUrl(item) {
      const that = this;
      const { width, height } = that.mainProps
      let extention = that.item.name.split('.')[1];
      
      if(that.item.thumbnail && that.thumbnail) {
        return `${process.env.VUE_APP_AXIOS_BASE_URL}/uploads/${that.item.thumbnail}`;
      } else if(that.item.name) {
        return `${process.env.VUE_APP_AXIOS_BASE_URL}/uploads/${that.item.name}`;
      } else if(!that.item.name) {
        return `https://via.placeholder.com/250x250.png?text=Document`;
      } else {
        return `https://via.placeholder.com/250x250.png?text=${extention}`;
      }
    }
  }
};
</script>
