<template>
  <BaseItemWidget>
  <card v-for="log in sortedItems" :key="log._id">
    <small class="text-muted font-weight-bold">
      <FormatDateTime :exercise="exercise" :sendTime="log.sendTime" />
    </small>
    <!-- <h5 class=" mt-3 mb-0">New likes</h5> -->
    <p class=" text-sm mt-1 mb-0">
      {{ log.note }}
    </p>
    <div class="mt-3" v-if="log.tags.length">
      <badge rounded type="info" v-for="(tag, index) in log.tags" :key="index" class="mx-1">{{tag}}</badge>
    </div>
  </card>
  </BaseItemWidget>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";
// import SocketIO from '@/util/socketio';

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "SignificantEventList",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      socketEventName: '',
      mainProps: {
        blank: true,
        width: 10,
        height: 10,
        class: "m1"
      }
    };
  },
  // watch: {
  //   exercise: function(val) {
  //     const that = this;

  //     if (val._id) {
  //     }
  //   }
  // },
  computed: {
    ...mapState("user", ["user"]),
    sortedItems: function() {
      return _.sortBy(this.items, function(item) {
        return -item.sendTime;
      });
    }
  },
  created: function() {

  },
  mounted: function() {
    const that = this;

    that.find();

    that.socketEventName = `${that.exercise._id}-significant-event-list`;
    that.subscribe(that.socketEventName, data => {
      const index = _.findIndex(that.items, o => {
        return o._id == data._id;
      });

      if (index >= 0) {
        that.items[index] = data;
      } else {
        that.items.push(data);
      }
    });
  },
  methods: {
    find: function() {
      const that = this;
      that.$http.get(`/exercise-log?exercise=${that.exercise._id}&type=significant-event`)
        .then(response => {
          that.items = response.data;
        })
        .catch(error => {
          HandleErrors.HandleErrors(error);
        });
    }
  },
  beforeRouteLeave (to, from, next) {
    this.sockets.unsubscribe(this.socketEventName);
  }

};
</script>
