<template>
  <BaseItemWidget>
    <FormAction :id="id" :copy="copy" />

    <div
      class="tw-text-center"
      v-if="!id || copy"
    >
      <b-button-group>
        <b-button @click="mediaLibraryType = 'upload'">Upload File</b-button>
        <b-button @click="mediaLibraryType = 'library'">Media Library</b-button>
        <b-button @click="mediaLibraryType = 'typed'">Typed</b-button>
      </b-button-group>
    </div>

    <b-form @submit="onSubmit" @reset="onReset">
      <b-form-group id="title-group" label="Title:" label-for="title">
        <b-form-input
          id="title"
          v-model="form.title"
          required
          placeholder="Enter title"
        ></b-form-input>
      </b-form-group>

      <!-- <b-form-group label="File:" label-for="file" v-if="!form.mediaLibrary">
        <b-form-file
          id="file"
          v-model="form.file"
          :state="Boolean(form.file)"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
        ></b-form-file>
        <b-button @click="form.file = null">Clear file</b-button>
      </b-form-group> -->

      <div class="container-fluid" v-if="mediaLibraryType == 'upload'">
        <div class="row">
          <div class="col px-0">
            <b-form-group label="File:" label-for="file">
              <b-form-file
                id="file"
                v-model="form.file"
                :state="Boolean(form.file)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </div>
          <div class="col-auto px-0" style="padding-top: 32px">
            <b-button
              @click="form.file = null"
              variant="outline-danger"
              block
              class="ml-1"
              >
                Clear file
            </b-button>
          </div>
        </div>
      </div>

      <!-- <ShowMedia
        v-if="mediaLibraryType == 'upload' && item && item.mediaLibrary"
        :mediaLibraryId="item.mediaLibrary"
        :thumbnail="false"
      />
      <br v-if="mediaLibraryType" /> -->

      <!-- <div class="tw-border tw-rounded-lg" v-if="form.mediaLibrary && mediaLibraryType !== 'typed'">
        <h4>Selected from Media Library</h4>
        <ShowMedia
          :mediaLibraryId="form.mediaLibrary"
          :thumbnail="false"
        />
      </div> -->

      <div class="tw-border tw-rounded-lg" v-if="form.mediaLibrary">
        <h4 class="tw-text-center tw-mt-6">Selected from Media Library</h4>
        <ShowMedia
          :mediaLibraryId="form.mediaLibrary"
          :thumbnail="false"
        />
      </div>

      <!-- <MediaLibrarySearch /> -->

      <b-card class="tw-text-center" v-if="mediaLibraryType == 'library'">
        <b-card-text class="tw-px-10">
          <b-btn
            variant="primary"
            @click="launchMediaLibraryModal()"
            block
          >
            Select From Library
          </b-btn>
        </b-card-text>
      </b-card>

      <!-- <div class="tw-border tw-rounded-lg" style="height: 300px; overflow-y: auto;" v-if="mediaLibraryType == 'library'">
        <h4>Media Library</h4>
        <MediaLibrary
          :add="true"
          v-on:addtobroadcastform="addToBroadcastForm($event)"
        />
      </div> -->
      <b-form-group id="message-group" label="Message:" label-for="message">
        <!-- <b-form-textarea
          id="message"
          v-model="form.message"
          placeholder="Enter broadcast message"
          rows="6"
        ></b-form-textarea> -->
        <quill-editor
          id="message"
          ref="myQuillEditor"
          v-model="form.message"
          :options="editorOption"
          class="editor"
        />
      </b-form-group>

      <b-form-group
        id="description-group"
        label="Description:"
        label-for="description"
      >
        <b-form-textarea
          id="description"
          v-model="form.description"
          placeholder="Enter Description"
          rows="6"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group id="tag-group" label="Tags:" label-for="tags">
        <b-form-tags
          input-id="tags"
          v-model="form.tags"
          class="mb-2"
          placeholder="Enter new tags separated by space, comma or semicolon"
        ></b-form-tags>
      </b-form-group>

      <b-form-group
        id="send-time-group"
        label="Send Time:"
        label-for="send-time"
      >
        <b-form-input
          id="send-time"
          v-model="form.formattedSendTime"
          placeholder="now"
          aria-describedby="send-time-input-live-help"
        ></b-form-input>
        <b-form-text id="send-time-input-live-help">
          Default value is blank now (current time, or 0 seconds, which is on
          exercise start). Otherwise, use the format HH:MM:SS, MM:SS, SS
        </b-form-text>
      </b-form-group>

      <!-- <b-form-group id="exercise-group" v-if="exercise">
        <b-form-checkbox v-model="form.exercise" :value="exercise._id" unchecked-value="" class="mb-3">
          Add to {{ exercise.name }}
        </b-form-checkbox>
      </b-form-group>

      <div class="row">
        <div class="col">
          <b-button block variant="outline-primary" type="submit">Save</b-button>
        </div>
        <div class="col">
          <b-button block variant="outline-warning" type="reset">Clear</b-button>
        </div>
      </div> -->

      <FormButtonOptions :exercise="exercise" :id="id" :copy="copy" />
    </b-form>

    <b-modal
      id="mediaLibraryModal"
      ref="mediaLibraryModal"
      title="Media Library"
      centered
      size="lg"
      >
      <MediaLibrary
        :add="true"
        v-on:addtobroadcastform="addToBroadcastForm($event)"
      />
    </b-modal>
  </BaseItemWidget>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

// import MediaLibrarySearch from "@/pages/SimEOC/MediaLibrarySearch.vue";
import MediaLibrary from "@/views/SimEOC/MediaLibrary.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "BroadcastFormComponent",
  mixins: [SocketIOMixin],
  components: {
    // MediaLibrarySearch,
    MediaLibrary,
  },
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
    type: {
      type: String,
      required: false,
      default: "broadcast",
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    input: {
      type: Object,
      required: false,
      default: Object,
    },
    copy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function() {
    return {
      item: null,
      mediaLibraryType: "upload",
      form: {
        title: null,
        message: null,
        tags: [],
        exercise: null,
        file: null,
        mediaLibrary: null
      },
      editorOption: {
        modules: {},
        placeholder: "Enter media description",
      },
      formattedSendTime: "",
      formattedSendTimePlaceholder: "now",
      roles: [],
    };
  },
  computed: {},
  watch: {
    id: function(val) {
      if (val) {
        this.init();
      } else {
        this.item = {};
        this.form = {};
      }
    },
    input: function(val) {
      this.item = this.input;
      this.form = _.extend({}, this.input);
    }
  },
  created: function() {
    if (this.id) {
      this.init();
    }
  },
  mounted: function() {},
  methods: {
    init() {
      const that = this;
      that.$http.get(`/media/${that.id}`).then((response) => {
        that.item = response.data;

        that.form = _.extend({}, that.item);
        that.form._id = that.copy ? undefined : that.form._id;
        that.form.formattedSendTime = that.secondsToFormattedSendTime(
          that.form.sendTime
        );

        if(that.item.message) {
          that.mediaLibraryType = "typed";
        }
      });
    },
    onSubmit: function(evt) {
      evt.preventDefault();

      const that = this;

      const fd = new FormData();

      // Clear the other depending on the type.
      // if(that.mediaLibraryType === "typed") {
      //   this.form.mediaLibrary = '';
      //   this.form.file = null;
      // } else {
      //   this.form.message = '';
      // }

      if(that.mediaLibraryType === "typed") {
        this.form.mediaLibrary = '';
        this.form.file = null;
      }

      if (that.form.file) {
        fd.append("file", this.form.file, this.form.file.name);
        fd.append("has_file", "yes");
      } else if(that.form.mediaLibrary) {
        fd.append("mediaLibrary", that.form.mediaLibrary);
      }

      fd.append("title", that.form.title);
      fd.append("message", that.form.message);
      fd.append("description", that.form.description);
      fd.append("tags", that.form.tags);
      fd.append("type", that.type);

      if (that.exercise._id) {
        fd.append("exercise", that.exercise._id);
      }

      let sendTime = that.formattedToSecondsSendTime(
        that.form.formattedSendTime
      );
      fd.append("sendTime", sendTime);
      if(that.exercise._id) {
        fd.append("createdTime", that.exercise.currentTime);
      }

      let request = null;
      let url = `/media`;

      if (that.form._id) {
        request = that.$http.patch(`${url}/${that.form._id}`, fd);
      } else {
        request = that.$http.post(url, fd);
      }

      request
        .then((response) => {
          that.$bvToast.toast("Created", {
            title: "Success",
            variant: "success",
            solid: true,
          });

          // that.onReset(evt);
          that.form = {};
          that.$emit("form", response.data);
        })
        .catch((error) => {
          HandleErrors.HandleErrors(error);
        });
    },
    onReset: function(evt) {
      evt.preventDefault();
      this.$emit("form", this.item ? this.item : {});
    },
    addToBroadcastForm(media) {
        this.form.mediaLibrary = media._id;
        this.$refs.mediaLibraryModal.hide();
    },
    launchMediaLibraryModal() {
      this.$refs.mediaLibraryModal.show();
      // this.$bvModal.show(`decline-modal-${that.item._id}`);
    },
  },
};
</script>

<style scoped>
.editor {
  /* height: 15rem; */
}
</style>
