<template>
  <div>
    <ChatEntry
      v-for="item in sortedChatEntries"
      :key="item._id"
      :exercise="exercise"
      :chat="chat"
      :item="item"
    />
  </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";
// import SocketIO from '@/util/socketio';

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "ChatEntryList",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: true,
      default: Object,
    },
    chat: {
      type: Object,
      required: true,
    },
    chatEntries: {
      type: Array,
      required: true,
    },
  },
  data: function() {
    return {};
  },
  computed: {
    sortedChatEntries() {
      const that = this;
      return _.sortBy(that.chatEntries, [
        function (o) {
          return -o.sendTime;
        },
      ]);
    },
  },
  created: function() {},
  mounted: function() {
    // const that = this;
    // const socketEventName = `${that.user._id}-position-log`;
    // console.log('socketEventName: ', socketEventName)
    // that.listenExerciseList(that.exercise, socketEventName);
    // that.find();
    // // that.socketEventName = `${that.exercise._id}-${that.user._id}-position-log-list`;
    // // console.log(that.socketEventName);
    // // that.subscribe(that.socketEventName, (data) => {
    // //     const index = _.findIndex(that.logs, (o) => {
    // //         return o._id == data._id;
    // //     });
    // //     if (index >= 0) {
    // //         that.logs[index] = data;
    // //     } else {
    // //         that.logs.push(data);
    // //     }
    // // });
  },
  methods: {
    // find: async function () {
    //     const that = this;
    //     const response = await that.$http.get(`/exercise-log?exercise=${that.exercise._id}&type=position`);
    //     that.items = response.data;
    // },
  },
  // beforeRouteLeave(to, from, next) {
  //     this.sockets.unsubscribe(this.socketEventName);
  // },
};
</script>
