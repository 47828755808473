<template>
    <b-card tag="article" class="mb-2">
            <b-card-title>
                {{ item.name }}

                <ShowSendTimeBadge
                class="float-right"
                :exercise="exercise"
                :item="item"
                />
            </b-card-title>

            <b-card-text>
                {{ item.description }}
            </b-card-text>

            <b-card-text v-if="!exercise._id || exercise._id && isExerciseController()">
                <ItemButtonOptions
                    widget="Spreadsheet"
                    :item="item"
                    :exercise="exercise"
                />
            </b-card-text>
        </b-card>
</template>

<script>
import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "InjectComponent",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        item: {
            type: Object,
            required: true,
        },
        exercise: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data: function () {
        return {};
    },
    computed: {},
    created: function () {},
    mounted: function () {},
    methods: {
        addToExercise: function () {},
        removeFromExercise: function () {},
        duplicate: function () {},
    },
};
</script>
