<template>
    <b-card class="mb-2">
        <b-card-title>
            {{ item.name }}

            <ShowSendTimeBadge
            class="float-right"
            :exercise="exercise"
            :item="item"
            />
            <b-badge variant="primary" class="float-right mr-2">
                {{ item.type }}
            </b-badge>
        </b-card-title>

        <b-card-text>
            {{ item.description }}
        </b-card-text>

        <b-card-text v-if="!exercise._id || exercise._id && isExerciseController()">
            <ItemButtonOptions
                widget="StatusBoard"
                :item="item"
                :exercise="exercise"
            />
        </b-card-text>
    </b-card>
</template>

<script>
import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "StatusBoardListItemComponent",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        item: {
            type: Object,
            required: true,
        },
        exercise: {
            type: Object,
            required: false,
            default: null,
        },
    },
};
</script>
