<template>
  <BaseItemWidget>
    <FormAction :id="id" :copy="copy" />
    <b-form @submit="onSubmit" @reset="onReset">
      <b-form-group id="name-group" label="Name:" label-for="name">
        <b-form-input
          id="name"
          v-model="form.name"
          required
          placeholder="Enter Name"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="description-group"
        label="Description:"
        label-for="description"
      >
        <b-form-textarea
          id="description"
          v-model="form.description"
          placeholder="Enter Description"
          rows="6"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group id="columns-group" label="Columns:" label-for="columns">
        <b-form-tags
          input-id="columns"
          v-model="form.columns"
          class="mb-2"
          placeholder="Enter columns separated by space, comma or semicolon"
        ></b-form-tags>
      </b-form-group>

      <b-form-group
        id="send-time-group"
        label="Send Time:"
        label-for="send-time"
        v-if="!(item && item._id && !copy)"
      >
        <b-form-input
          id="send-time"
          v-model="form.formattedSendTime"
          placeholder="now"
          aria-describedby="send-time-input-live-help"
        ></b-form-input>
        <b-form-text id="send-time-input-live-help">
          Default value is blank now (current time, or 0 seconds, which is on
          exercise start). Otherwise, use the format HH:MM:SS, MM:SS, SS
        </b-form-text>
      </b-form-group>

      <FormButtonOptions :exercise="exercise" :id="id" :copy="copy" />
    </b-form>
  </BaseItemWidget>
</template>

<script>

import * as _ from "lodash";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "ExerciseSpreadsheetForm",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    input: {
      type: Object,
      required: false,
      default: Object,
    },
    copy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      item: null,
      form: {
        name: "",
        description: "",
        exercise: false,
      },
      formattedSendTime: "",
      formattedSendTimePlaceholder: "now",
      permissions: [],
    };
  },
  watch: {
    exercise: function (val) {
      if (val._id) {
        this.form.exercise = true;
      } else {
        this.form.exercise = false;
      }
    },
    id: function (val) {
      if (val) {
        this.init();
      } else {
        this.item = {};
        this.form = {};
      }
    },
    input: function (val) {
      this.item = this.input;
      this.form = _.extend({}, this.input);
    },
  },
  computed: {
    ...mapState("sample", ["sample"]),
  },
  created: function () {
    if (this.id) {
      this.init();
    }
  },
  mounted: function () {},
  methods: {
    init() {
      const that = this;
      that.$http.get(`/spreadsheet/${that.id}`).then((response) => {
        that.item = response.data;

        that.form = _.extend({}, that.item);
        that.form._id = that.copy ? undefined : that.form._id;
        that.form.formattedSendTime = that.secondsToFormattedSendTime(
          that.form.sendTime
        );
      });
    },
    onSubmit: async function (evt) {
      evt.preventDefault();

      const that = this;
      const data = _.clone(that.form);

      if(!that.form.columns.length) {
        return that.$bvToast.toast("Please include at least one column!", {
          title: "Warning",
          variant: "warning",
          solid: true,
        });
      }

      data.exercise = that.exercise._id ? that.exercise._id : undefined;

      // Don't update the send time if this is a copy.
      // if(data._id && !that.copy) {
      //   data.sendTime = that.formattedToSecondsSendTime(
      //     that.form.formattedSendTime
      //   );
      // }
      data.sendTime = that.formattedToSecondsSendTime(that.form.formattedSendTime);

      if(that.exercise._id) {
        data.updateTime = that.exercise.currentTime;
      }

      let response = null;

      if (data._id) {
        response = await that.$http.patch(`/spreadsheet/${data._id}`, data);
      } else {
        if(that.exercise._id) {
          data.createdTime = that.exercise.currentTime;
        }

        response = await that.$http.post(`/spreadsheet`, data);
      }

      that.$bvToast.toast("Spreadsheet Saved!", {
        title: "Success",
        variant: "success",
        solid: true,
      });

      // that.onReset(evt);
      that.$emit("form", response.data);
      this.form = {};
    },
    onReset: function (evt) {
      evt.preventDefault();
      this.form = {};
      this.$emit("form", this.item ? this.item : {});
    },
  },
};
</script>
