var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseItemWidget',[_c('FormAction',{attrs:{"id":_vm.id,"copy":_vm.copy}}),_c('b-form',{on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('b-form-group',{attrs:{"id":"name-group","label":"Name:","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","required":"","placeholder":"Enter Status Board Title"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('b-form-group',{attrs:{"id":"description-group","label":"Description:","label-for":"description"}},[_c('b-form-textarea',{attrs:{"id":"description","placeholder":"Enter Description","rows":"6"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('b-form-group',{attrs:{"id":"type-group","label":"Type:","label-for":"type"}},[_c('b-form-select',{attrs:{"id":"type","options":[
                    'used/capacity',
                    'out-of',
                    'count-up',
                    'count-down',
                    'percent',
                    'info',
                ]},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1),_c('b-form-group',{attrs:{"id":"send-time-group","label":"Send Time:","label-for":"send-time"}},[_c('b-form-input',{attrs:{"id":"send-time","placeholder":_vm.secondsToFormattedSendTime(_vm.$store.state.sim.exercise.currentTime),"aria-describedby":"send-time-input-live-help"},model:{value:(_vm.form.formattedSendTime),callback:function ($$v) {_vm.$set(_vm.form, "formattedSendTime", $$v)},expression:"form.formattedSendTime"}}),_c('b-form-text',{attrs:{"id":"send-time-input-live-help"}},[_vm._v(" Default value is blank now (current time, or 0 seconds, which is on exercise start). Otherwise, use the format HH:MM:SS, MM:SS, SS ")])],1),_c('FormButtonOptions',{attrs:{"exercise":_vm.exercise,"id":_vm.id,"copy":_vm.copy}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }