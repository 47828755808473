<template>
    <b-card>
        <FormAction :id="id" :copy="copy" />
        <b-form @submit="onSubmit" @reset="onReset">
            <b-form-group
                id="resource-group"
                label="Resource:"
                label-for="resource"
            >
                <b-form-input
                    id="resource"
                    v-model="form.resource"
                    required
                    placeholder="Enter Resource"
                ></b-form-input>
            </b-form-group>

            <b-form-group
                id="description-group"
                label="Description:"
                label-for="description"
            >
                <b-form-textarea
                    id="description"
                    v-model="form.description"
                    placeholder="Enter Description"
                    rows="6"
                ></b-form-textarea>
            </b-form-group>

            <div class="row">
                <div class="col">
                    <b-form-group
                        id="quantity-group"
                        label="Quantity:"
                        label-for="quantity"
                    >
                        <b-form-input
                            id="quantity"
                            v-model="form.quantity"
                            required
                            placeholder="Enter Quantity"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group
                        id="units-group"
                        label="Units:"
                        label-for="units"
                    >
                        <b-form-input
                            id="units"
                            v-model="form.units"
                            required
                            placeholder="Enter Units"
                        ></b-form-input>
                    </b-form-group>
                </div>
            </div>

            <!-- <b-form-group
        id="tag-group"
        label="Tags:"
        label-for="tags"
      >
        <b-form-tags input-id="tags" v-model="form.tags" class="mb-2" placeholder="Enter new tags separated by space, comma or semicolon"></b-form-tags>
      </b-form-group> -->

            <b-form-group id="returnable-group">
                <b-form-checkbox v-model="form.returnable" class="mb-3">
                    Returnable
                </b-form-checkbox>
            </b-form-group>

            <b-form-group
                id="send-time-group"
                label="Send Time:"
                label-for="send-time"
            >
                <b-form-input
                    id="send-time"
                    v-model="form.formattedSendTime"
                    placeholder="now"
                    aria-describedby="send-time-input-live-help"
                ></b-form-input>
                <b-form-text id="send-time-input-live-help">
                    Default value is blank now (current time, or 0 seconds,
                    which is on exercise start). Otherwise, use the format
                    HH:MM:SS, MM:SS, SS
                </b-form-text>
            </b-form-group>

            <!-- <b-form-group id="exercise-group" v-if="exercise">
        <b-form-checkbox
          v-model="form.exercise"
          :value="exercise._id"
          unchecked-value=""
          class="mb-3"
        >
          Add to {{ exercise.name }}
        </b-form-checkbox>
      </b-form-group>

      <div class="row">
        <div class="col">
          <b-button block variant="outline-primary" type="submit"
            >Save</b-button
          >
        </div>
        <div class="col">
          <b-button block variant="outline-warning" type="reset"
            >Clear</b-button
          >
        </div>
      </div> -->

            <FormButtonOptions :exercise="exercise" :id="id" :copy="copy" />
        </b-form>
    </b-card>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "InventoryFormComponent",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
        id: {
            type: String,
            required: false,
            default: null,
        },
        input: {
            type: Object,
            required: false,
            default: Object,
        },
        copy: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        return {
            item: null,
            form: {
                resource: null,
                description: null,
                quantity: null,
                units: null,
                exercise: null,
            },
            formattedSendTime: "",
            formattedSendTimePlaceholder: "now",
            roles: [],
        };
    },
    computed: {
        ...mapState("sample", ["sample"]),
    },
    watch: {
        id: function (val) {
            if (val) {
                this.init();
            } else {
                this.item = {};
                this.form = {};
            }
        },
        input: function (val) {
            this.item = this.input;
            this.form = _.extend({}, this.input);
        },
    },
    created: function () {
        if (this.id) {
            this.init();
        }
    },
    mounted: function () {},
    methods: {
        init() {
            const that = this;
            that.$http.get(`/inventory/${that.id}`).then((response) => {
                that.item = response.data;

                that.form = _.extend({}, that.item);
                that.form._id = that.copy ? undefined : that.form._id;
                that.form.formattedSendTime = that.secondsToFormattedSendTime(that.form.sendTime);
            });
        },
        onSubmit: async function (evt) {
            evt.preventDefault();

            const that = this;
            const data = _.clone(that.form);
            // let formattedSendTime = that.form.formattedSendTime;

            // if(!formattedSendTime && that.exercise) {
            //     formattedSendTime = String(that.exercise.currentTime);
            // }

            data.exercise = that.exercise._id ? that.exercise._id : undefined;
            data.sendTime = that.formattedToSecondsSendTime(that.form.formattedSendTime);

            // Make a copy of the current one.

            let response = null;
            
            if(data.exercise && that.item && data._id) {
                // Clone current inventory and save it with a new id.
                const oldInventory = _.cloneDeep(that.item);
                oldInventory._id = undefined;
                oldInventory.actionTime = that.exercise.currentTime;
                oldInventory._id = await that.$http.post(`/inventory`, oldInventory).then(response => response.data._id);

                // Find any RR using item inventory, make a copy and set oldInventory.
                const allRR = await that.$http.get(`/resource-request`, {params: {exercise: that.exercise._id}}).then(response => response.data);
                _.each(allRR, async (rr) => {
                    // If an RR has the ID of the current
                    if(rr.inventory === that.item._id) {
                        // Update the sentTime for the current ones.
                        await that.$http.patch(`/resource-request/${rr._id}`, {
                            sendTime: that.exercise.currentTime
                        });

                        // Create a copy with oldInventory.
                        rr._id = undefined;
                        rr.actionTime = oldInventory.actionTime;
                        rr.inventory = oldInventory._id;
                        await that.$http.post(`/resource-request`, rr);
                    }
                });

                data.sendTime = that.exercise.currentTime;
                response = await that.$http.patch(`/inventory/${data._id}`, data);
            } else {
                if (data._id) {
                    response = await that.$http.patch(`/inventory/${data._id}`, data);
                } else {
                    if(that.exercise._id) {
                        data.createdTime = that.exercise.currentTime;
                    }

                    response = await that.$http.post(`/inventory`, data);
                }
            }

            that.$bvToast.toast("Inventory saved", {
                title: "Success",
                variant: "success",
                solid: true,
            });

            that.form = {};
            that.$emit('form', response.data);
        },
        onReset: function (evt) {
            evt.preventDefault();
            this.$emit('form', (this.item) ? this.item : {});
        },
    },
};
</script>
