<template>
    <Broadcast
        :exercise="exercise"
        :id="id"
        :widget="$options.name"
        v-on:details="$emit('details', $event)"
        v-on:edit="$emit('edit', $event)"
        v-on:duplicate="$emit('duplicate', $event)"
        v-on:removefromexercise="$emit('removefromexercise', $event)"
        v-on:editinexercise="$emit('editinexercise', $event)"
        v-on:addtoexercise="$emit('addtoexercise', $event)"
    />
</template>

<script>
import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "SocialMediaComponent",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        id: {
            type: String,
            required: true,
        },
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
    },
};
</script>
