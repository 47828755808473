<template>
    <b-card
        :img-src="item.imageUrl"
        img-alt="Image"
        img-top
        tag="article"
        class="mb-2"
    >
        <b-card-title>
            {{ item.title }}

            <ShowSendTimeBadge
            class="float-right"
            :exercise="exercise"
            :item="item"
            />
        </b-card-title>

        <b-card-text>
            <ShowMedia :mediaLibraryId="item.mediaLibrary" :thumbnail="false" />
            <div v-html="item.message"></div>

            <hr />
            <b-badge class="mx-1" v-for="tag in item.tags" :key="tag">
                {{ tag }}
            </b-badge>
        </b-card-text>

        <b-card-text v-if="!exercise._id || exercise._id && isExerciseController()">
                <ItemButtonOptions
                    widget="Broadcast"
                    :item="item"
                    :exercise="exercise"
                />
            </b-card-text>
    </b-card>
</template>

<script>

import SocketIOMixin from '@/util/socketio';
export default {
    name: "BroadcastListItemComponent",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        item: {
            type: Object,
            required: true,
        },
        exercise: {
            type: Object,
            required: false,
            default: null,
        },
    }
};
</script>
