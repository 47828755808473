<template>
  <div>
    <!-- <div class="col-auto">
      <a href="#" class="avatar rounded-circle">
        <img alt="Image placeholder" :src="member.image" />
      </a>
    </div> -->
    <!-- <div class="col-auto">
      <b-button v-if="item.exercise && !item.user" type="primary" size="sm">
        Join
      </b-button>
      <b-button v-if="item.exercise && !item.user" type="danger" size="sm">
        Remove
      </b-button>
      <b-button v-if="exercise && !item.exercise" type="primary" size="sm">
        Add to Exercise
      </b-button>
    </div> -->

    <b-card :title="item.name" class="mb-2">
        <b-card-text>
            {{item.description}}
        </b-card-text>

        <b-card-text v-if="item.permissions">
            <b-badge
                class="mr-1"
                v-for="permission in item.permissions"
                :key="permission"
                >{{ permission }}</b-badge>
        </b-card-text>

      <b-card-text v-if="!exercise._id || exercise._id && isExerciseController()">
                <ItemButtonOptions
                    widget="Role"
                    :item="item"
                    :exercise="exercise"
                />
            </b-card-text>
    </b-card>
  </div>
</template>

<script>
import SocketIOMixin from '@/util/socketio';
export default {
  name: "RoleComponent",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    item: {
      type: Object,
      required: true
    },
    exercise: {
      type: Object,
      required: false,
      default: null
    }
  }
};
</script>
