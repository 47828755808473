<template>
  <b-card class="my-2" :bg-variant="item.severe ? 'danger' : ''" :text-variant="item.severe ? 'white' : ''" v-if="isItemVisible(exercise, $store.state.sim.role, item)">
    <b-card-title>
      <!-- {{ item.forecast }} -->

      <ShowSendTimeBadge
          class="float-right"
          :exercise="exercise"
          :item="item"
        />
      
        <b-badge variant="info" class="float-right mr-2">
        {{ item.humidity }} <span>&percnt;</span> H
      </b-badge>
      <b-badge variant="secondary" class="float-right mr-2">
        {{ item.temperature }} <span>&deg;</span> ({{item.windChill}} <span>&deg;</span> WC)
      </b-badge>
      <b-badge variant="dark" class="float-right mr-2">
        {{ item.windSpeed }} mph {{item.windDirection}}
      </b-badge>
      <b-badge variant="primary" class="float-right mr-2">
        {{ item.forecast }}
      </b-badge>
    </b-card-title>

    <b-card-text class="tw-pt-2">
      <b-media>
      <template #aside>
        <b-img v-bind="{width: 75, height: 75}" :src="`simeoc/weather/${item.forecast.replace(' ', '')}.png`" fluid alt="Responsive image"></b-img>
      </template>

      <div>
        {{ item.forecast }}
        {{ item.description }}
      </div>
    </b-media>

      
      
    </b-card-text>

    <b-card-text v-if="isItemButtonOptionsVisible(exercise, $store.state.sim.role, item)">
      <ItemButtonOptions
        :widget="$options.name"
        :item="item"
        :exercise="exercise"
        v-on:details="$emit('details', $event)"
        v-on:edit="$emit('edit', $event)"
        v-on:duplicate="$emit('duplicate', $event)"
        v-on:removefromexercise="remove($event)"
        v-on:editinexercise="$emit('editinexercise', $event)"
        v-on:addtoexercise="$emit('addtoexercise', $event)"
      />
    </b-card-text>
  </b-card>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "Weather",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    input: {
      type: Object,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
  },
  data: function() {
    return {
      item: {},
    };
  },
  created() {
    if (this.id) {
      this.init();
    } else if (this.input) {
      this.item = this.input;
    }
  },
  watch: {
    id: function(val) {
      if (val) {
        this.init();
      }
    },
    input: function(val) {
      if (val) {
        this.item = val;
      }
    },
  },
  mounted: function() {},
  methods: {
    init() {
      const that = this;
      that.$http.get(`/weather/${this.id}`)
        .then((response) => {
          that.item = response.data;
        })
        .catch((error) => {
          HandleErrors.HandleErrors(error);
        });
    },
    // remove: function(item) {
    //   const that = this;
    //   that.$http.delete(`/weather/${that.item._id}`)
    //     .then((response) => {
    //       that.$notify({
    //         text: "Deleted.",
    //         duration: 10000,
    //         type: "success",
    //       });

    //       that.$emit("removefromexercise", that.item);
    //     })
    //     .catch((error) => {
    //       HandleErrors.HandleErrors(error);
    //     });
    // },
    remove: async function() {
      await that.$http.patch(`/weather/${this.item._id}`, {deletedTime: this.$store.getters['sim/currentTime']});

      this.$notify({
        text: "Deleted.",
        duration: 10000,
        type: "success",
      });
    },
  },
};
</script>
