<template>
  <b-container fluid>

    <b-modal v-model="modalAddUser" id="modalAddUser" title="Add User" ok-only centered scrollable>
      <b-container fluid>
      <b-row class="my-3">
        <b-col sm="12" md="6" offset-md="3">
          <b-form-input
            size="lg"
            v-model="createUserForm.name"
            placeholder="Name"
          ></b-form-input>
        </b-col>
      </b-row>

      <b-row class="my-3">
        <b-col sm="6" md="3" offset-md="3">
          <b-form-checkbox
            id="active"
            v-model="createUserForm.active"
            name="active"
            :value="true"
            :unchecked-value="false"
          >Active</b-form-checkbox>
        </b-col>
        <b-col sm="6" md="3">
          <b-form-input
            type="password"
            size="lg"
            v-model="createUserForm.password"
            placeholder="Password"
          ></b-form-input>
        </b-col>
      </b-row>

      <b-row class="my-3">
        <b-col sm="6" md="3" offset-md="3">
          <b-form-input
            size="lg"
            v-model="createUserForm.email"
            placeholder="Email"
          ></b-form-input>
        </b-col>
        <b-col sm="6" md="3">
          <b-form-input
            size="lg"
            v-model="createUserForm.role"
            placeholder="Company Role"
          ></b-form-input>
        </b-col>
      </b-row>

      <b-row class="my-3">
        <b-col sm="12" md="6" offset-md="3">
          <multiselect
            size="lg"
            v-model="createUserForm.systemRoles"
            :multiple="true"
            :options="['admin', 'exercise-developer', 'exercise-controller']"
          ></multiselect>
        </b-col>
      </b-row>

      <b-row class="my-3">
        <b-col sm="6" md="3" offset-md="3">
          <b-button
            class="mt-2"
            variant="primary"
            block
            size="lg"
            @click="createUser()"
          >Sign Up</b-button>
        </b-col>
        <b-col sm="6" md="3">
          <b-button
            class="mt-2"
            variant="warning"
            block
            size="lg"
            @click="cancelCreateUser()"
          >Cancel</b-button>
        </b-col>
      </b-row>
    </b-container>
    </b-modal>

    <b-container fluid class="mt-3">
      <b-row>
        <b-col v-if="customers.length">
          <b-form-select
            v-model="selectedCustomer"
            :options="customers"
            size="lg"
            class="mb-3"
            value-field="_id"
            text-field="name"
            disabled-field="notEnabled"
          >
            <template #first>
              <b-form-select-option :value="null">-- Select A Customer To Filter --</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="auto" v-if="$store.state.user.user.isSuperUser || $store.state.user.user.systemRoles.indexOf('admin') >= 0">
          <b-button size="lg" @click="modalAddUser = true">
            Create User
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <card>
            <h2>Users</h2>
            <b-table striped hover :fields="fields" :items="users">
              <template #cell(name)="data">
                <b-link :to="{name: 'user-details', params: {id: data.item._id}}">
                  {{ data.value }}
                </b-link>
              </template>

              <template #cell(active)="data">
                <h4>
                  <b-badge variant="success" v-if="data.value">Yes</b-badge>
                  <b-badge variant="danger" v-else>No</b-badge>
                </h4>
              </template>

              <template #cell(systemRoles)="data">
                {{ data.value.join(", ")}}
              </template>
            </b-table>
          </card>
        </b-col>
      </b-row>
    </b-container>

  </b-container>
</template>

<script>
// import { HTTP } from "@/util/http-common";
import { HTTP } from "@/util/http-common.js";
// // import HandleErrors from "@/utilities/handle-errors";

// import DashboardBaseTemplate from "@/layouts/DashboardBaseTemplate.vue";

export default {
  // mixins: [DashboardBaseTemplate],
  data: function () {
    return {
      fields: ['name', 'email', 'role', 'systemRoles', 'active'],
      users: [],
      customers: [],
      myCustomer: {},
      selectedCustomer: null,
      modalAddUser: false,
      createUserForm: {}
    };
  },
  computed: {},
  watch: {
    selectedCustomer: function(val) {
      if (val) {
        this.findUsers();
        this.$router.replace({
          name: 'users',
          query: {
            customer: val
          }
        });
      }
    }
  },
  created: async function () {
    const that = this;

    if(that.$route.query.customer) {
      that.selectedCustomer = that.$route.query.customer;
    }

    if(that.$store.state.user.user.isSuperUser) {
      that.findCustomers();
    }

    that.findUsers();

    if(!that.selectedCustomer) {
      that.selectedCustomer = that.$store.state.user.customer._id;
    }
  },
  methods: {
    async findUsers() {
      const that = this;
      that.users = await that.$http.get("/user", {
        params: {
          customer: that.selectedCustomer
        }
      }).then(
        (response) => response.data
      );
    },
    async findCustomers() {
      const that = this;
      that.customers = await that.$http.get("/customer").then(
        (response) => response.data
      );
    },
    async createUser() {
      const that = this;

      that.createUserForm.customer = that.selectedCustomer;
      
      try {
        const response = await that.$http.post(`/user`, that.createUserForm);
        that.createUserForm = {};
        that.modalAddUser = false;
        that.findUsers();
      } catch (error) {
        return that.$notify({
          text: error.response.data.message,
          duration: 10000,
          type: "error"
        });
      }

      that.$notify({
        text: "Saved.",
        duration: 10000,
        type: "success"
      });
    },
    cancelCreateUser() {
      this.createUserForm = {};
      this.modalAddUser = false;
    }
  },
};
</script>

<style>
</style>