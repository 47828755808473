<template>
    <BroadcastLibrary
        type="social-media"
        :exercise="exercise"
        v-on:details="$emit('details', $event)"
        v-on:edit="$emit('edit', $event)"
        v-on:duplicate="$emit('duplicate', $event)"
        v-on:addtoexercise="$emit('addtoexercise', $event)"
    />
</template>

<script>

import SocketIOMixin from '@/util/socketio';
export default {
    name: "SocialMediaLibrary",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
    },
};
</script>
