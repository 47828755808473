<template>
  <BaseWidget>
    <template v-slot:title>
      Status Board Widgets
    </template>

    <template v-slot:actions>
      <b-button size="sm" @click="canAdd = !canAdd" squared variant="outline-primary">
          <i class="fas fa-plus"></i> add
        </b-button>
    </template>

    <b-modal id="status-board-form" title="Add Status">
      <p class="my-4">Hello from modal!</p>
    </b-modal>


    <div v-if="canAdd">
      <StatusBoardForm v-if="exercise" :exercise="exercise" />
    </div>


  </BaseWidget>
</template>

<script>
// import {
//     HTTP
// } from '@/util/http-common';
// import HandleErrors from '@/util/handle-errors';

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "StatusBoardWidget",
  mixins: [WidgetMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object
    },
    fullScreen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      show: true,
      fullWidth: false,
      title: "Sample Page",
      modals: {},
      canAdd: false
    };
  },
  computed: {
    ...mapState("sample", ["sample"])
  },
  created: function() {
    //console.log(this.$options.name);
  },
  mounted: function() {},
  methods: {
    get: function() {
      // that.$http.get('/').then((response) => {
      //     let something = response.data;
      // }).catch((error) => {
      //     HandleErrors.HandleErrors(error);
      // });
    },
    find: function() {},
    patch: function() {},
    delete: function() {}
  }
};
</script>
