<template>
    <BaseSimWidget>
        <template v-slot:title> {{ title }} </template>

        <ScribeForm :exercise="exercise" />

        <hr />

        <ScribeList :exercise="exercise" />
    </BaseSimWidget>
</template>

<script>
// import {
//     HTTP
// } from '@/util/http-common';
// import HandleErrors from '@/util/handle-errors';

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "Activity",
    mixins: [WidgetMixin],
    props: {
        exercise: {
            type: Object,
            required: true,
            default: Object,
        },
        fullScreen: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        return {
            show: true,
            fullWidth: false,
            title: "Scribe",
        };
    },
    computed: {},
    created: function () {},
    mounted: function () {},
    methods: {},
};
</script>
