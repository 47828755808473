<template>
    <div class="text-right m-2">
        <span title="Ascending Send Time" style="cursor: pointer;" :class="{'font-weight-bold text-primary': injectSort == 'sendTime'}" @click="$emit('sort', 'sendTime')">
            &nbsp;
            <i class="fas fa-sort-numeric-up"></i>
        </span>
        <span title="Descending Send Time" style="cursor: pointer;" :class="{'font-weight-bold text-primary': injectSort == '-sendTime'}" @click="$emit('sort', '-sendTime')">
            &nbsp;
            <i class="fas fa-sort-numeric-down"></i>
        </span>
        <span title="Ascending Modify Time" style="cursor: pointer;" :class="{'font-weight-bold text-primary': injectSort == 'updatedAt'}" @click="$emit('sort', 'updatedAt')">
            &nbsp;
            <i class="fas fa-sort-amount-up"></i>
        </span>
        <span title="Descending Modify Time" style="cursor: pointer;" :class="{'font-weight-bold text-primary': injectSort == '-updatedAt'}" @click="$emit('sort', '-updatedAt')">
            &nbsp;
            <i class="fas fa-sort-amount-down"></i>
        </span>
    </div>
</template>

<script>

import SocketIOMixin from '@/util/socketio';
export default {
    mixins: [SocketIOMixin],
    components: {},
    props: {
        injectSort: {
            type: String,
            required: false,
            default: "sendTime",
        },
    },
    computed: {
        
    },
};
</script>
