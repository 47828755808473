<template>
    <div>
        <MySimInject v-for="item in itemList" :key="item._id" :input="item" :exercise="exercise" />
    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
// import HandleErrors from "@/util/handle-errors";

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "MySimInjectList",
    mixins: [WidgetMixin, SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
        fullScreen: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        return {
            tabIndex: 0,
        };
    },
    computed: {},
    created: function () {
        const that = this;

        this.find();

        that.socketIOSubscribe({
            name: `${that.exercise._id}-my-inject-list`
        });
    },
    mounted: function () {},
    methods: {
        find: async function () {
            const that = this;
            // const response = await that.$http.get(
            //     `/my-inject`,
            //     {
            //         params: {
            //             exercise: that.exercise._id,
            //             role: that.$store.getters['sim/getRoleID']
            //         }
            //     }
            // );
            // that.itemList = response.data;

            that.itemList = await that.$http.get(
                `/inject`,
                {
                    params: {
                        exercise: that.exercise._id
                    }
                }
            ).then(response => response.data);
        },
    },
    beforeRouteLeave(to, from, next) {
        // this.sockets.unsubscribe(`${this.exercise._id}-my-inject`);
    },
};
</script>
