<template>
    <div>
        <Task
            class="my-2"
            v-for="item in sortedItemList"
            :key="item._id"
            :input="item"
            :exercise="exercise"
            :inbox="inbox"
            v-on:editinexercise="$emit('editinexercise', $event)"
        />
    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import * as _ from "lodash";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "TaskList",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
        inbox: {
            type: String,
            required: false,
            default: "in",
            enum: ["in", "out"],
        },
    },
    data: function () {
        return {};
    },
    computed: {

    },
    created: function () {},
    mounted: function () {
        const that = this;

        // that.listenExerciseList(that.exercise, "exercise-mission-task");

        that.find();

        that.socketIOSubscribe({
            name: `${that.exercise._id}-exercise-mission-task-list`
        });
    },
    methods: {
        find: async function () {
            const that = this;
            const roleId = that.$store.getters['sim/getRoleID'];

            const response = await that.$http.get("/exercise-mission-task", {
                params: { exercise: that.exercise._id },
            });

            // console.log(response.data)

            // that.itemList = _.filter(response.data, (it) => {
            //     // console.log(that.inbox, roleId, it.fromRole, it.toRole, '...');

            //     if (that.inbox == "in" && it.toRole == roleId) {
            //         return true;
            //     } else if (that.inbox == "out" && it.fromRole == roleId) {
            //         return true;
            //     } else {
            //         return false;
            //     }
            //     // if (
            //     //     item.fromRole == that.$store.getters['sim/getRoleID'] ||
            //     //     item.toRole == that.$store.getters['sim/getRoleID']
            //     // ) {
            //     //     return true;
            //     // } else {
            //     //     return false;
            //     // }
            // });

            that.itemList = response.data;

            // .then((response) => {
            //     that.itemList = _.filter(response.data, (item) => {
            //         if (
            //             item.fromRole == that.$store.getters['sim/getRoleID'] ||
            //             item.toRole == that.$store.getters['sim/getRoleID']
            //         ) {
            //             return true;
            //         } else {
            //             return false;
            //         }
            //     });
            // })
            // .catch((error) => {
            //     HandleErrors.HandleErrors(error);
            // });
        },
    },
};
</script>
