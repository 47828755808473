import { mapActions, mapState } from "vuex";
import * as moment from "moment";

// import { io } from 'socket.io-client';

// let socket = io('http://localhost:3030/', {
//     reconnection: true,
//     transports: ['websocket']
// });
// // let socket = io('ws://localhost:3030/', {forceNew: true, upgrade: true});

// socket.emit('come', 'One times two');
// socket.on('message', function(msg){

//     console.log(msg);
// });

// window.msoc = socket;
// console.log(socket)

export default {
  name: "SocketIOMixin",
  mixins: [],
  components: {},
  props: {},
  sockets: {
    connect: function () {
      console.log('socket to notification channel connected')
    },
  },
  data() {
    return {
      socketEventName: null,
      // items: [],
      // item: Object,
      itemList: [],
      socketNameList: [],
      simData: {
        items: [],
        counter: 0,
        item: null
      },
      sortByVal: '-updatedAt',
      options: {}
    };
  },
  computed: {
    ...mapState("user", ["user", "customer"]),
    sortedItemListBySendTime() {
      const that = this;
      return _.sortBy(that.items, [
        function (o) {
          return -o.sendTime;
        },
      ]);
    },
    sortedItemListByCreatedAt() {
      const that = this;
      return _.sortBy(that.items, [
        function (o) {
          return -o.sendTime;
        },
      ]);
    },
    sortedItemList() {
      const that = this;
      return _.sortBy(that.itemList, [
        function (o) {
          return -o.sendTime;
        },
      ]);
    },
    sortedItemUpdateList() {
      const that = this;
      return _.sortBy(that.itemList, [
          function (o) {
              return -o.updateTime;
          },
      ]);
    },
    sortedBySort() {
      const that = this;
      const asc = that.sortByVal[0] == '-' ? false : true;
      const sortBy = that.sortByVal[0] == '-' ? that.sortByVal.substring(1) : that.sortByVal;

      // console.log('asc: ', asc);
      // console.log('sortBy: ', sortBy);

      if(asc) {
        return _.sortBy(that.itemList, [
          function (o) {
            return o[sortBy];
          },
        ]);
      } else {
        return _.sortBy(that.itemList, [
          function (o) {
            return -o[sortBy];
          },
        ]);
      }
    },
  },
  created: function() {},
  destroyed: function() {
    this.unsubscribe();
  },
  methods: {
    ...mapActions("user", ["getCustomer", "setCustomer"]),

    setSort(sort) {
      this.sortByVal = sort;
    },

    subscribe(socketEventName, callback) {
      // console.log("subscribing to: ", socketEventName);
      const that = this;
      that.socketEventName = socketEventName;

      if (that.socketEventName) {
        that.sockets.subscribe(that.socketEventName, (data) => {
          callback(data);
        });
      }
    },
    unsubscribe(name) {
      const that = this;
      
      if (name) {
        that.sockets.unsubscribe(name);
        if(that.options.debug) {
          console.log("/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/unsubscribing to: ", name);
        }
      } else if (that.socketEventName) {
        that.sockets.unsubscribe(that.socketEventName);
        if(that.options.debug) {
          console.log("/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/unsubscribing to: ", that.socketEventName);
        }
      }
    },
    emit(message, data) {
      this.$socket.emit(message, data);
    },
    async listenLibraryList(widgetSlug) {
      const that = this;

      if (!that.customer._id) {
        let customer = await that.getCustomer().then((response) => {
          return response.data;
        });
        that.setCustomer(customer);
      }

      that.socketEventName = `${that.customer._id}-${widgetSlug}-library`;
      that.subscribe(that.socketEventName, function(data) {
        // let index = _.findIndex(that.items, function (o) {
        //     return o._id == data._id;
        // });

        // if (index >= 0) {
        //     that.items[index] = data;
        // } else {
        //     that.items.push(data);
        // }

        // that.items.sort((a, b) => {
        //     return a.sendTime > b.sendTime;
        // });
        // console.log(that.items.length);
        const items = _.remove(that.items, (it) => {
          return it._id != data._id;
        });
        // console.log(items.length);
        items.push(data);
        // console.log(items.length);

        items.sort((a, b) => {
          return a.sendTime > b.sendTime;
        });

        that.items = items;
        that.itemList = items;
      });
    },
    async listenExerciseList(exercise, widgetSlug) {
      const that = this;

      that.socketEventName = `${exercise._id}-${widgetSlug}-list`;
      // console.log("listenExerciseList: ", that.socketEventName);
      that.subscribe(that.socketEventName, function(data) {
        // let index = _.findIndex(that.items, function (o) {
        //     return o._id == data._id;
        // });

        // if (index >= 0) {
        //     that.items[index] = data;
        // } else {
        //     that.items.push(data);
        // }
        // console.log(that.items.length);
        const items = _.remove(that.items, (it) => {
          return it._id != data._id;
        });
        // console.log(items.length);
        items.push(data);
        // console.log(items.length);

        items.sort((a, b) => {
          return a.sendTime < b.sendTime;
        });

        that.items = items;
      });
    },
    initialize() {
      // console.log("----------------initialize");
      // const that = this;
      // that.socket = io(process.env.VUE_APP_AXIOS_BASE_URL);
      // let socket = io(process.env.VUE_APP_AXIOS_BASE_URL);

      // var socket = io(process.env.VUE_APP_AXIOS_BASE_URL, {
      //   extraHeaders: {
      //     Authorization: store.getters.getAuthorizationToken,
      //     authStrategies: ['jwt']
      //   }
      // });

      // console.log(store.getters.getAuthorizationToken);
      // socket.on('connect', () => {
      //   socket.emit('create', 'authentication', {
      //     strategy: 'jwt',
      //     accessToken: store.getters.getAuthorizationToken
      //   }, function(error, newAuthResult) {
      //     console.log('Authentication')
      //     console.log(newAuthResult);

      //     socket.emit('find', 'role', (error, data) => {
      //       console.log('Found all messages', data);
      //     });
      //   });

      // });
      // that.setSocket(socket);

      // that.socket.on("connect", () => {
      //   console.log(that.user.name);

      //   that.socket.emit('register', that.user._id);
      // });

      //   that.socket.on("news", function(data) {
      //   console.log("-------------------------------");
      //   console.log(data);
      //   // socket.emit('my other event', { my: 'data' });
      // });
    },
    // listen(message) {
    //   this.socket.emit('listen', message);
    // },
    // listenAndMerge(message, source) {
    //   this.socket.emit('listen', message);

    //   this.socket.on(message, (data) => {
    //     const index = _.findIndex(source, (o) => {
    //       return o._id == data._id;
    //     });
    //     console.log('index-', source);

    //     if(index >= 0) {
    //       source[index] = data;
    //     } else {
    //       source.push(data);
    //     }
    //   });
    // }
    socketSubscribe(name, items) {
      this.sockets.listener.subscribe(name, (item) => {
        const index = _.findIndex(items, (o) => {
          return o._id == item._id;
        });

        if (index >= 0) {
          items.splice(index, 1);
        }

        items.push(item);
      });
    },
    async socketIOSubscribeLibraryHelper(options) {
      const that = this;

      if (!that.customer._id) {
        let customer = await that.getCustomer().then((response) => {
          return response.data;
        });
        that.setCustomer(customer);
      }

      const newOptions = {
        name: `${that.customer._id}-${options.name}-library`
      };

      that.socketIOSubscribe(newOptions);
    },
    socketIOSubscribe(options) {
      const that = this;
      options.objectName = options.objectName || 'itemList';
      options.debug = options.debug || false;

      // Add sockets to the list.
      that.socketNameList.push(options.name);

      if(options.debug) {
        console.log('/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/socketNameList: ', that.socketNameList);
      }

      that.subscribe(options.name, function(data) {

        if(options.callback) {
          return options.callback(data);
        }

        if(options.debug) {
          console.log('/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/before: ', that.socketNameList);
        }

        const index = _.findIndex(that[options.objectName], (o) => {
          return o._id == data._id;
        });

        if (index >= 0) {
          that.$set(that[options.objectName], index, data);
          if(options.debug) {
            console.log('/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/set-item: ', that.socketNameList);
          }
        } else {
          that[options.objectName].push(data);
          if(options.debug) {
            console.log('/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/push-item: ', that.socketNameList);
          }
        }

        if(options.lastCallback) {
          if(options.debug) {
            console.log('/-/-/-/-/-/-/-/-/-/-/-/-/-/-/-/lastCallback: ', that.socketNameList);
          }

          return options.lastCallback(data);
        }
      });

      that.options = options;
    },
    socketIOUnSubscribe() {
      const that = this;
      // that.socketNameList.forEach((name) => {
      //   that.sockets.listener.unsubscribe(name);
      // });

      // console.log('unsubscribe: ', that.socketNameList)
    },
    socketIOUnSubscribeName(name) {
      // this.sockets.listener.unsubscribe(name);
    },
  },
};
