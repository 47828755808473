import Vue from 'vue'
import Vuex from 'vuex'

import sim from './modules/sim';
import user from './modules/user';

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    user: user,
    sim: sim,
  },
  state: {
    Authorization: ''
  },
  getters: {
    hasAuthorizationToken: state => {
      let Authorization = window.localStorage.getItem('Authorization');
      if (Authorization) {
        state.Authorization = Authorization;
        return true;
      } else {
        return false;
      }
    },
    getAuthorizationToken: state => {
      return state.Authorization;
    },
    getAuthorizationHeader: (state, getters) => {
      return (state.Authorization) ? { Authorization: getters.getAuthorizationToken} : {};
    }
  },
  mutations: {
    setAuthorizationToken (state, payload) {
      state.Authorization = payload.accessToken;
      that.$http.defaults.headers.common['Authorization'] = state.Authorization;
      window.localStorage.setItem('Authorization', state.Authorization);
    },
    setAuthorization (state, payload) {
      state.Authorization = payload;
      that.$http.defaults.headers.common['Authorization'] = state.Authorization;
    },
    clearAuthorizationToken (state) {
      state.Authorization = '';
      window.localStorage.removeItem('Authorization');
      that.$http.defaults.headers.common['Authorization'] = undefined;
    },
    toggleSidebar: (state) => {
      state.sidebar = !state.sidebar;
    }
  },
  actions: {
    setAuthorizationToken ({commit}, payload) {
      commit('setAuthorizationToken', payload);
    },
    clearAuthorizationToken({commit}) {
      commit('clearAuthorizationToken');
    },
    initApp({dispatch}) {
      dispatch('initAuthorizationToken');
    },
    initAuthorizationToken({commit}) {
      let Authorization = window.localStorage.getItem('Authorization');
      if (Authorization) {
        commit('setAuthorization', Authorization);
      } else {
        return false;
      }
    },
    toggleSidebar({commit}) {
      commit('toggleSidebar');
    },

  }
})
