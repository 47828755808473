<template>
    <b-container fluid class="tw-mt-5">
        <b-row>
            <b-col md="12" offset-md="0" sm="8" offset-sm="2">
                <b-card class="tw-mb-4">
                    <b-card-text>
                        <b-form @submit="onSubmit">
                            <b-container fluid>
                                <b-row>
                                    <b-col>
                                        <b-form-group
                                            id="name-group"
                                            label="Name:"
                                            label-for="name"
                                            
                                        >
                                            <b-form-input
                                                id="name"
                                                v-model="form.name"
                                                required
                                                placeholder="Enter Name"
                                                size="lg"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <quill-editor
                                            ref="myQuillEditor"
                                            v-model="form.description"
                                            :options="editorOption"
                                            class="editor"
                                        />
                                        <br><br><br>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <!-- <label for="startSimDate">Start Sim Date</label> -->
                                            <b-form-datepicker
                                                id="startSimDate"
                                                v-model="form.startSimDate"
                                                today-button
                                                reset-button
                                                close-button
                                                locale="en"
                                                size="lg"
                                                placeholder="Start Sim Date"
                                            ></b-form-datepicker>
                                    </b-col>
                                    <b-col v-if="form.startSimDate">
                                        <!-- <label for="endSimDate">End Sim Date</label> -->
                                        <b-form-datepicker
                                            id="endSimDate"
                                            :min="form.startSimDate"
                                            v-model="form.endSimDate"
                                            placeholder="End Sim Date"
                                            class="mb-2"
                                            size="lg"
                                        >
                                        </b-form-datepicker>
                                    </b-col>
                                </b-row>
                                <!-- <b-row>
                                    <b-col>
                                        <div style="width: 100%; height: 500px;">
                                            <v-map
                                                ref="myMap"
                                                @ready="doSomethingOnReady()"
                                                :zoom="mapOptions.initialZoom"
                                                :center="mapOptions.initialLocation"
                                                class="tw-h-full"
                                                >
                                                <v-tilelayer
                                                    url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                                                ></v-tilelayer>
                                                <v-tilelayer
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                ></v-tilelayer>

                                                <v-marker v-if="mapOptions.initialLocation.latitude" :lat-lng="[mapOptions.initialLocation.latitude, mapOptions.initialLocation.longitude]"></v-marker>
                                            </v-map>
                                        </div>
                                    </b-col>
                                </b-row> -->

                                <b-row>
                                    <b-col>
                                        <div style="width: 100%; height: 500px;" ref="mapContainer">
                                            
                                        </div>

                                        <hr>
                                        <strong>Latitude: </strong>{{ form.latitude }}
                                        <strong>Longitude: </strong>{{ form.longitude }}
                                        <strong>Zoom: </strong>{{ form.zoom }}
                                    </b-col>
                                </b-row>

                                <b-row class="mt-3">
                                    <b-col>
                                        <b-button
                                            block
                                            size="lg"
                                            variant="outline-primary"
                                            type="submit"
                                        >
                                            Save
                                        </b-button>
                                    </b-col>
                                    <b-col v-if="redirect">
                                        <!-- <b-button
                                            v-if="form._id"
                                            block
                                            size="lg"
                                            variant="outline-warning"
                                            :to="{ name: 'exercise', params: {id: form._id} }"
                                        >
                                            Cancel
                                        </b-button> -->
                                        <b-button
                                            block
                                            size="lg"
                                            variant="outline-warning"
                                            :to="{ name: 'Dashboard', query: {template: form.template} }"
                                        >
                                            Cancel
                                        </b-button>
                                    </b-col>

                                    <b-col v-if="!redirect">
                                        <b-button
                                            block
                                            size="lg"
                                            variant="outline-warning"
                                            @click="resetForm()"
                                        >
                                            Reset
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </b-form>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import * as _ from "lodash";

import mapboxgl from 'mapbox-gl';

// import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
// import { Icon } from "leaflet";

// delete Icon.Default.prototype._getIconUrl;

// Icon.Default.mergeOptions({
//   iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
//   iconUrl: require("leaflet/dist/images/marker-icon.png"),
//   shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
// });

export default {
    mixins: [],
    // components: {
    //     "v-map": LMap,
    //     "v-tilelayer": LTileLayer,
    //     "v-marker": LMarker
    // },
    props: {
        redirect: {
            type: Boolean,
            required: false,
            default: true,
        },
        exerciseId: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            editorOption: {
                modules: {},
                placeholder: "Enter exercise description",
                height: 300,
            },
            form: {},
            mapOptions: {
                initialZoom: 10,
                initialLocation: [25.771163582, -80.189499242]
            },
            map: null,
            originalData: null,
        };
    },
    created() {},
    async mounted() {
        const that = this;

        if (that.exerciseId) {
            const response = await that.$http.get(`/exercise/${that.exerciseId}`);
            that.originalData = _.clone(response.data);
            that.form = _.clone(response.data);

            if(that.form.latitude) {
                that.mapOptions.initialLocation = [that.form.latitude, that.form.longitude];
                that.mapOptions.initialZoom = that.form.zoom;
            }
        }

        const map = new mapboxgl.Map({
            container: that.$refs.mapContainer,
            style: "mapbox://styles/mapbox/streets-v12", // Replace with your preferred map style
            center: [that.mapOptions.initialLocation[1], that.mapOptions.initialLocation[0]],
            zoom: Math.ceil(that.mapOptions.initialZoom),
            boxZoom: true,
            cooperativeGestures: true,
        });

        that.map = map;

        that.map.on("move", function (e) {
            const center = that.map.getCenter();
            that.form.zoom = that.map.getZoom();
            that.form.latitude = center.lat;
            that.form.longitude = center.lng;
        });
    },
    methods: {
        onSubmit: async function (evt) {
            evt.preventDefault();

            const that = this;
            const data = _.clone(that.form);

            let response = null;

            if (that.exerciseId) {
                response = await that.$http.patch(`/exercise/${data._id}`, data);
            } else {
                response = await that.$http.post(`/exercise`, data);
            }

            if(that.redirect) {
                that.$router.push({
                    name: "Dashboard",
                    query: {
                        template: (response.data.template) ? true : false
                    }
                });
            }
        },
        resetForm: function () {
            const that = this;
            that.form = _.clone(that.originalData);

            that.map.setCenter([that.originalData.longitude, that.originalData.latitude]);
            that.map.setZoom(that.originalData.zoom);
        },
        // setMapData() {
        //     const that = this;
        //     const center = that.$refs.myMap.mapObject.getCenter();
        //     that.form.latitude = center.lat;
        //     that.form.longitude = center.lng;
        //     that.form.zoom = that.$refs.myMap.mapObject.getZoom();
        // },
        // doSomethingOnReady() {
        //     const that = this;
        //     that.$refs.myMap.mapObject.on("zoomend", function (e) {
        //         that.setMapData();
        //     });

        //     that.$refs.myMap.mapObject.on("moveend", function (e) {
        //         that.setMapData();
        //     });
        // }
    },
};
</script>

<style scoped>
.editor {
    height: 20rem;
}
</style>