<template>
    <span>{{ formatted }}</span>
</template>

<script>
import * as moment from "moment";

import SocketIOMixin from '@/util/socketio';
export default {
    mixins: [SocketIOMixin],
    components: {},
    props: {
        inputDate: {
            type: String,
            required: false,
            default: null
        },
        currentTime: {
            type: Number,
            required: false,
            default: 0
        }
    },
    computed: {
        formatted() {
            let inputDate = this.inputDate;

            if (!inputDate) {
                inputDate = moment();
            }

            return moment(inputDate).add(this.currentTime, 'seconds').format('dddd, MMMM D, YYYY');
        }
    }
};
</script>
