<template>
  <BaseSimWidget id="injectWidget">
    <template v-slot:title>
      <i class="fas fa-bullhorn"></i>
      Injects
    </template>

    <b-tabs v-model="tabIndex" align="right" pills>
      <template #tabs-end v-if="!fullScreen">
        <!-- <b-nav-item>
          <i class="fas fa-chevron-down"></i>
        </b-nav-item> -->
        <b-nav-item>
          <i
            class="fas fa-expand-arrows-alt"
            @click.prevent="goFullScreen()"
          ></i>
        </b-nav-item>
      </template>

      <b-tab>
        <template #title>
          <i class="fas fa-bullhorn"></i>
        </template>

        <MySimInjectList :exercise="exercise" />
      </b-tab>
      <b-tab
        :disabled="
          !isExerciseController() && !hasPermission(widgets.inject.slug)
        "
      >
        <template #title>
          <i class="fas fa-list"></i>
        </template>

        <InjectList
          :exercise="exercise"
        />
      </b-tab>
      <b-tab
        :disabled="
          !isExerciseController() && !hasPermission(widgets.inject.slug)
        "
      >
        <template #title>
          <i class="fas fa-briefcase"></i>
        </template>

        <InjectLibrary
          :exercise="exercise"
          v-on:addtoexercise="addItemToExercise($event)"
        />
      </b-tab>
      <b-tab
        :disabled="
          !isExerciseController() && !hasPermission(widgets.inject.slug)
        "
      >
        <template #title>
          <i class="fas fa-plus"></i>
        </template>

        <InjectForm
          :exercise="exercise"
          :copy="copy"
          :id="id"
          v-on:form="handleFormEmit($event)"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <i class="fas fa-question"></i>
        </template>

        <p>Help will be here.</p>
      </b-tab>
    </b-tabs>
  </BaseSimWidget>
</template>

<script>
// import {
//     HTTP
// } from '@/util/http-common';
// import HandleErrors from '@/util/handle-errors';

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "InjectSimWidget",
  mixins: [WidgetMixin],
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
    fullScreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      show: true,
      fullWidth: false,
      title: "Sample Page",
      id: null,
      copy: false,
      tabIndex: 0,
    };
  },
  computed: {},
  created: function () {},
  mounted: function () {
    if (this.$store.state.sim.role._id) {
      if(this.$store.state.sim.role.controller) {
        this.tabIndex = 1;
      } else {
        this.tabIndex = 0;
      }
    } else {
      this.tabIndex = 1;
    }
  },
  methods: {
    get: function () {},
    addItemToExercise: function (item) {
      this.id = item._id;
      this.copy = true;
      this.tabIndex = 3;
    },
    handleFormEmit: function (item) {
      this.tabIndex = 1;
    },
    patch: function () {},
    delete: function () {},
    goFullScreen() {
      this.$emit("setfullscreenmodalwidget", "InjectSimWidget");
    },
  },
};
</script>
