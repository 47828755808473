<template>
  <BaseSimWidget id="mapWidget">
    <template v-slot:title>
      <span>
        <i class="fas fa-map-marked"></i>
        Map
      </span>
    </template>

    <!--  class="tw-m-0 tw-p-0" -->
    <b-container fluid>
      <b-row>
        <b-col :style="styleObject" xl="4" lg="12">
          <b-tabs v-model="tabIndex" id="" align="right" pills>
            <template #tabs-end v-if="!fullScreen">
              <b-nav-item>
                <i class="fas fa-expand-arrows-alt" @click.prevent="goFullScreen()"></i>
              </b-nav-item>
            </template>

            <b-tab>
              <template #title>
                <i class="fas fa-briefcase"></i>
              </template>

              <MapLibrary
                :exercise="exercise"
                v-on:addtoexercise="addItemToExercise($event)"
              />
            </b-tab>

            <b-tab active>
              <template #title>
                <i class="fas fa-list"></i>
              </template>

              <MapList
                :exercise="exercise"
                v-on:editinexercise="editInExercise($event)"
                v-on:zoomtolocation="zoomToLocation($event)"
              />
            </b-tab>

            <b-tab :disabled="!isExerciseController() && !hasPermission(widgets.disasterMap.slug)">
              <template #title>
                <i class="fas fa-plus"></i>
              </template>

              <MapForm
                :exercise="exercise"
                :copy="copy"
                :id="id"
                v-on:form="formFinished($event)"
              />
            </b-tab>

            <b-tab>
              <template #title>
                <i class="fas fa-question"></i>
              </template>

              <p>Help will be here.</p>
            </b-tab>

          </b-tabs>
        </b-col>

        <b-col>
          <MapSimView :exercise="exercise" :full-screen="fullScreen" :zoom-location="zoomLocation" />
        </b-col>
      </b-row>
    </b-container>
  </BaseSimWidget>
</template>

<script>
// import {
//     HTTP
// } from '@/util/http-common';
// import HandleErrors from '@/util/handle-errors';

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "MapSimWidget",
  mixins: [WidgetMixin],
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
    fullScreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      show: true,
      fullWidth: false,
      title: "Sample Page",
      id: null,
      copy: false,
      tabIndex: 0,
      styleObject: {'height': '500px', 'overflow-y': 'auto'},
      zoomLocation: null
    };
  },
  computed: {
    ...mapState("sample", ["sample"]),
  },
  created: function () {
    //console.log(this.$options.name);
  },
  mounted: function () {
    if(this.fullScreen) {
      this.styleObject['height'] = '100%';
    } else {
      this.styleObject['height'] = '500px';
    }
  },
  methods: {
    get: function () {
      // that.$http.get('/').then((response) => {
      //     let something = response.data;
      // }).catch((error) => {
      //     HandleErrors.HandleErrors(error);
      // });
    },
    addItemToExercise: function (item) {
      this.id = item._id;
      this.copy = true;
      this.tabIndex = 2;
    },
    formFinished: function () {
      this.id = null;
      this.copy = false;
      this.tabIndex = 1;
    },
    editInExercise: function(item) {
        this.id = item._id;
        this.copy = true;
        this.tabIndex = 2;
    },
    zoomToLocation: function(item) {
      this.zoomLocation = item;
    },
    patch: function () {},
    delete: function () {},
    goFullScreen() {
      this.$emit('setfullscreenmodalwidget', 'MapSimWidget');
    }
  },
};
</script>
