<template>
    <div>
        <BaseItemWidget>
            <FormAction :id="id" :copy="copy" />
            <b-form @submit="onSubmit" @reset="onReset">
                <!-- <b-form-group
                    id="category-group"
                    label="Category:"
                    label-for="category"
                >
                    <b-form-select
                        id="category"
                        v-model="form.category"
                        :options="['information', 'warning', 'danger', 'location']"
                    ></b-form-select>
                </b-form-group> -->

                <b-container fluid>
                    <b-row>
                        <b-col v-for="cat in iconOptions" :key="cat.img" class="">
                            <b-img
                                :class="{'selected-image': selectedIcon === cat.img}"
                                class="tw-cursor-pointer"
                                rounded
                                v-bind="{width: 50, height: 50}"
                                :src="cat.img"
                                :alt="cat.title"
                                @click="selectedIcon = cat.img"
                            ></b-img>
                        </b-col>
                    </b-row>

                </b-container>

                <hr>

                <!-- <b-form-group
                    id="category-group"
                    label="Category:"
                    label-for="category"
                >
                    <multiselect id="category" v-model="form.category" placeholder="Select Category" label="title" track-by="img" :options="categoryOptions" :option-height="104" :show-labels="false">
                        <template slot="singleLabel" slot-scope="props"><img class="option__image" :src="props.option.img" alt="No Man’s Sky"><span class="option__desc"><span class="option__title">{{ props.option.title }}</span></span></template>
                        <template slot="option" slot-scope="props"><img class="option__image" :src="props.option.img" alt="No Man’s Sky">
                        <div class="option__desc"><span class="option__title">{{ props.option.title }}</span><span class="option__small">{{ props.option.desc }}</span></div>
                        </template>
                    </multiselect>
                </b-form-group> -->

                <b-form-group id="name-group" label="Label:" label-for="name">
                    <b-form-input
                        id="name"
                        v-model="form.name"
                        required
                        placeholder="Enter Label"
                    ></b-form-input>
                </b-form-group>

                <div class="row">
                    <div class="col" style="height: 300px;">
                        <quill-editor
                            ref="myQuillEditor"
                            v-model="form.description"
                            :options="editorOption"
                        />
                    </div>
                </div>

                <div class="row tw-mb-5">
                    <div class="col" style="height: 440px">
                        <MapPreview
                            :item="item"
                            :icon="selectedIcon"
                            :canEdit="true"
                            :exercise="exercise"
                            v-on:update:LatLng="updateLatLng($event)"
                        />
                        <p>Click to place or update markers!</p>
                    </div>
                </div>

                <br />
                <b-form-group
                    id="send-time-group"
                    label="Send Time:"
                    label-for="send-time"
                >
                    <b-form-input
                        id="send-time"
                        v-model="form.formattedSendTime"
                        placeholder="now"
                        aria-describedby="send-time-input-live-help"
                    ></b-form-input>
                    <b-form-text id="send-time-input-live-help">
                        Default value is blank now (current time, or 0 seconds,
                        which is on exercise start). Otherwise, use the format
                        HH:MM:SS, MM:SS, SS
                    </b-form-text>
                </b-form-group>

                <FormButtonOptions :exercise="exercise" :id="id" :copy="copy" />
            </b-form>
        </BaseItemWidget>

    </div>
</template>

<script>

import SocketIOMixin from '@/util/socketio';
export default {
    name: "MapFormComponent",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
        id: {
            type: String,
            required: false,
            default: null,
        },
        input: {
            type: Object,
            required: false,
            default: Object,
        },
        copy: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        return {
            item: {},
            formattedSendTime: "",
            formattedSendTimePlaceholder: "now",
            editorOption: {
                modules: {},
                placeholder: "Enter Description",
            },
            form: {},
            selectedIcon: "/simeoc/simeoc-map-icons/blue.png",
            initialLocation: [25.771163582, -80.189499242],
            map: null,
            mapOptions: {
                initialZoom: 10,
                initialLocation: [25.771163582, -80.189499242]
            },
            iconOptions: [
                { title: "Info Icon", img: "/simeoc/simeoc-map-icons/blue.png" },
                { title: "Dark Icon", img: "/simeoc/simeoc-map-icons/black.png" },
                { title: "Neutral Icon", img: "/simeoc/simeoc-map-icons/gray.png" },
                { title: "Success Icon", img: "/simeoc/simeoc-map-icons/green.png" },
                { title: "Warning Icon", img: "/simeoc/simeoc-map-icons/orange.png" },
                { title: "Danger Icon", img: "/simeoc/simeoc-map-icons/red.png" },

                { title: "Police Blockade", img: "/simeoc/simeoc-map-icons/caution.png" },
                { title: "Police Blockade", img: "/simeoc/simeoc-map-icons/police-caution-black.png" },
                { title: "Blockade", img: "/simeoc/simeoc-map-icons/icon_blockade-no-police.png" },
                { title: "Police Blockade", img: "/simeoc/simeoc-map-icons/icon_blockade-police.png" },
                { title: "Police Blockade", img: "/simeoc/simeoc-map-icons/icon_blockade-police-red.png" },

                { title: "Downed Electric Pole", img: "/simeoc/simeoc-map-icons/electric-pole.png" },
                { title: "Downed Electric Pole", img: "/simeoc/simeoc-map-icons/icon_downed-electrical.png" },
                { title: "Downed Tree", img: "/simeoc/simeoc-map-icons/icon_downed-tree.png" },

                { title: "Road Work", img: "/simeoc/simeoc-map-icons/icon_road-work.png" },

                { title: "Fire", img: "/simeoc/simeoc-map-icons/fire.png" },
                { title: "Fire", img: "/simeoc/simeoc-map-icons/fire-black.png" },
                { title: "Fire", img: "/simeoc/simeoc-map-icons/icon_fire.png" },
                { title: "Fire", img: "/simeoc/simeoc-map-icons/icon_fire-red.png" },
                { title: "Fire", img: "/simeoc/simeoc-map-icons/icon_fire-red-w-black.png" },
                { title: "Fire", img: "/simeoc/simeoc-map-icons/icon_fire-red-w-white.png" },

                { title: "Flood", img: "/simeoc/simeoc-map-icons/flood-black.png" },
                { title: "Flood", img: "/simeoc/simeoc-map-icons/flooding.png" },
                { title: "Flood", img: "/simeoc/simeoc-map-icons/icon_flood.png" },
                { title: "Flood", img: "/simeoc/simeoc-map-icons/icon_flood-blue.png" },

                { title: "Biohazard", img: "/simeoc/simeoc-map-icons/icon_biohazard-alt.png" },
                { title: "Car crash", img: "/simeoc/simeoc-map-icons/icon_car-crash.png" },
                { title: "Caution Biohazard", img: "/simeoc/simeoc-map-icons/icon_caution-biohazard.png" },
                { title: "Caution", img: "/simeoc/simeoc-map-icons/icon_caution-general.png" },
                { title: "Caution Radiation", img: "/simeoc/simeoc-map-icons/icon_caution-radiation.png" },
                { title: "Caution Toxic Danger", img: "/simeoc/simeoc-map-icons/icon_caution-toxic-danger.png" },
                { title: "Chemical Spill", img: "/simeoc/simeoc-map-icons/icon_chemical-spill.png" },
                { title: "Quarantine", img: "/simeoc/simeoc-map-icons/icon_quarantine.png" },
                { title: "Radiation", img: "/simeoc/simeoc-map-icons/icon_radiation-alt.png" },
                { title: "Toxic Danger", img: "/simeoc/simeoc-map-icons/icon_toxic-danger-alt.png" },

                { title: "Evacuation Area", img: "/simeoc/simeoc-map-icons/icon_evacuation-area.png" },
                { title: "No Entry", img: "/simeoc/simeoc-map-icons/icon_no-entry.png" },
                { title: "No Entry", img: "/simeoc/simeoc-map-icons/icon_no-entry-plain.png" },

                { title: "Police Checkpoint", img: "/simeoc/simeoc-map-icons/police-caution.png" },
                { title: "Police Checkpoint", img: "/simeoc/simeoc-map-icons/police-caution-road-black.png" },
                { title: "Polic Checkpoint", img: "/simeoc/simeoc-map-icons/icon_checkpoint-police.png" },

                { title: "Spot Marker", img: "/simeoc/simeoc-map-icons/x.png" },
            ]
        };
    },
    computed: {},
    watch: {
        id: function (val) {
            if (val) {
                this.init();
            } else {
                this.item = {};
                this.form = {};
            }
        },
        input: function (val) {
            this.item = this.input;
            this.form = _.extend({}, this.input);
        },
    },
    created: function () {
        this.mapRef = `form-${Math.random()}`;

        if (this.id) {
            this.init();
        }
    },
    mounted: function () {},
    methods: {
        init() {
            const that = this;

            if(that.exercise._id) {
                that.initialLocation = [that.exercise.latitude, that.exercise.longitude];
            }

            that.$http.get(`/disaster-map/${that.id}`).then((response) => {
                that.item = response.data;

                that.form = _.extend({}, that.item);
                that.form._id = that.copy ? undefined : that.form._id;
                that.selectedIcon = that.form.icon;
                
                // If item.exericise, don't set sendTime.
                if(!that.item.exercise) {
                    that.form.formattedSendTime = that.secondsToFormattedSendTime(that.form.sendTime);
                }
            });
        },
        onSubmit: async function (evt) {
            evt.preventDefault();

            const that = this;
            const data = _.clone(that.form);

            if(!that.selectedIcon) {
                that.$notify({
                    message: "Please select an icon!",
                    timeout: 5000,
                    type: "danger",
                });
                return;
            }

            data.icon = that.selectedIcon;

            data.exercise = that.exercise ? that.exercise._id : undefined;
            data.role = that.$store.getters['sim/getRoleID'];
            data.sendTime = that.formattedToSecondsSendTime(that.form.formattedSendTime);

            // let request = null;
            // let url = `/disaster-map`;
            let response = null;

            // If copying and item has exercise, then expire the old one.
            if(that.copy && that.item.exercise) {
                response = await that.$http.patch(`/disaster-map/${that.id}`, {actionTime: that.exercise.currentTime});
                response = await that.$http.post(`/disaster-map`, data);
            } else if(that.copy || !that.item._id) {
                response = await that.$http.post(`/disaster-map`, data);
            } else {
                response = await that.$http.patch(`/disaster-map/${that.id}`, data);
            }

            that.$notify({
                message: "Location Updated.",
                timeout: 5000,
                type: "success",
            });

            that.form = {};
            that.$emit('form', response.data);
        },
        onReset: function (evt) {
            evt.preventDefault();
            this.form = {};
            this.item = {};
            this.selectedIcon = "/simeoc/simeoc-map-icons/blue.png";
            this.$emit('form', (this.item) ? this.item : {});
        },
        updateLatLng: function (latlng) {
            const that = this;
            that.form.latitude = latlng.lat;
            that.form.longitude = latlng.lng;
        },
    },
};
</script>

<style scoped>
.selected-image {
    background-color: #ccc;
}
</style>