<template>
  <BroadcastListItem :exercise="exercise" :item="item" />
</template>

<script>
import SocketIOMixin from '@/util/socketio';
export default {
  name: "SocialMediaListItemComponent",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
    exercise: {
      type: Object,
      required: false,
      default: null,
    },
  },
};
</script>
