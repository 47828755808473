<template>
  <span v-if="myUser.name" class="tw-inline-block tw-mx-2">{{ myUser.name }}</span>
</template>

<script>

export default {
  name: "UserFullName",
  mixins: [],
  components: {},
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      myUser: {},
    };
  },
  computed: {},
  created: function () {
    this.get();
  },
  mounted: function () {},
  methods: {
    get: function () {
      const that = this;
      that.$http.get(`/users/${that.userId}`).then((response) => {
        that.myUser = response.data;
      });
    },
  },
};
</script>
