<template>
  <BroadcastForm
    :exercise="exercise"
    type="social-media"
    :id="id"
    :copy="copy"
    v-on:form="$emit('form', $event)"
  />
</template>

<script>
import SocketIOMixin from '@/util/socketio';
export default {
  name: "SocialMediaForm",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
    type: {
      type: String,
      required: false,
      default: "social-media",
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    copy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
