<template>
    <b-card class="mb-2">
        <b-card-title>
            {{ item.name }}

            <ShowSendTimeBadge
            class="float-right"
            :exercise="exercise"
            :item="item"
            />
            <MapCategory class="float-right mr-1" :category="item.category" />
        </b-card-title>

        <b-card-text>
            {{ item.category }}
        </b-card-text>

        <b-card-text style="height: 300px" v-if="preview">
            <MapPreview :item="item" />
        </b-card-text>

        <b-card-text v-html="item.description"> </b-card-text>

        <b-card-text>
            <b-container fluid>
                <b-row>
                    <b-col cols="auto">
                        <b-button
                            variant="outline-primary"
                            @click="preview = !preview"
                            v-if="!preview"
                        >
                            <i class="fas fa-eye"></i>
                        </b-button>
                        <b-button
                            variant="outline-primary"
                            @click="preview = !preview"
                            v-if="preview"
                        >
                            <i class="fas fa-eye-slash"></i>
                        </b-button>
                    </b-col>
                    <b-col v-if="!exercise._id || exercise._id && isExerciseController()">
                        <ItemButtonOptions
                            widget="Map"
                            :item="item"
                            :exercise="exercise"
                        />
                    </b-col>
                </b-row>
            </b-container>
        </b-card-text>
    </b-card>
</template>

<script>
import { mapState } from "vuex";

import { LMap, LTileLayer, LMarker, LCircle, LRectangle } from "vue2-leaflet";
import { Icon, Marker, LayerGroup } from "leaflet";

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

import SocketIOMixin from '@/util/socketio';
export default {
    name: "InjectComponent",
    mixins: [SocketIOMixin],
    components: {
        "v-map": LMap,
        "v-tilelayer": LTileLayer,
        "v-marker": LMarker,
        "v-circle": LCircle,
        "v-rectangle": LRectangle,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        exercise: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data: function () {
        return {
            preview: false,
            initialLocation: [47.41322, -1.219482],
            bounds: [
                [47.9, -2],
                [47.0, -0.5],
            ],
            icon: new Icon({
                iconUrl:
                    "https://leafletjs.com/examples/custom-icons/leaf-orange.png",
            }),
        };
    },
    computed: {},
    created: function () {},
    mounted: function () {},
    methods: {
        addToExercise: function () {},
        removeFromExercise: function () {},
        duplicate: function () {},
        doSomethingOnReady: function () {
            const that = this;
            that.initialLocation = [that.item.latitude, that.item.longitude];
            // [that.form.latitude, that.form.longitude] = that.initialLocation;
            // that.$refs.myMap.mapObject.on('click', function(e) {
            //   that.form.latitude = e.latlng.lat;
            //   that.form.longitude = e.latlng.lng;
            //   that.initialLocation = e.latlng;
            // });
        },
    },
};
</script>
