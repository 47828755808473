<template>
  <BaseSimWidget>
    <template v-slot:title>
      Inventory
    </template>

    <b-tabs id="" align="right" pills>
      <b-tab active>
          <template #title>
            <i class="fas fa-list"></i>
          </template>

          <InventoryList :exercise="exercise" />
      </b-tab>
      <b-tab>
          <template #title>
            <i class="fas fa-briefcase"></i>
          </template>

          <InventoryLibrary :exercise="exercise" />
      </b-tab>
      <b-tab>
          <template #title>
            <i class="fas fa-plus"></i>
          </template>

          <InventoryForm :exercise="exercise" />
      </b-tab>

      <b-tab>
          <template #title>
            <i class="fas fa-question"></i>
          </template>

          <p>Help will be here.</p>
      </b-tab>
    </b-tabs>
  </BaseSimWidget>
</template>

<script>
// import {
//     HTTP
// } from '@/util/http-common';
// import HandleErrors from '@/util/handle-errors';

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "Activity",
  mixins: [WidgetMixin],
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object
    },
    fullScreen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      show: true,
      fullWidth: false,
      title: "Sample Page"
    };
  },
  computed: {
    ...mapState("sample", ["sample"])
  },
  created: function() {
    //console.log(this.$options.name);
  },
  mounted: function() {},
  methods: {
    get: function() {
      // that.$http.get('/').then((response) => {
      //     let something = response.data;
      // }).catch((error) => {
      //     HandleErrors.HandleErrors(error);
      // });
    },
    find: function() {},
    patch: function() {},
    delete: function() {}
  }
};
</script>
