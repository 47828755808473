<template>
    <!-- <div class="card m-2">
        <div class="card-header">
            <div class="row align-items-center">
                <div class="col-8">
                    <h5 class="h3 mb-0">
                        <slot name="title" />
                    </h5>
                </div>
                <div class="col-4 text-right">
                    <slot name="actions" />
                </div>
            </div>
        </div>
        <div class="card-body">
            <slot />
        </div>
    </div> -->

    <b-card class="mt-3 mb-3">
        <b-card-text>
            <div style="position: absolute; top: -10px; left: -10px;">
                <slot name="left-notification" />
            </div>

            <div style="position: absolute; top: -10px; right: -10px;">
                <slot name="right-notification" />
            </div>

            <h2 style="position: absolute; top: 20px;">
                <slot name="title" />
            </h2>
            <!-- <b-container fluid>
                <b-row no-gutters>
                    <b-col cols="auto">
                        <h4>
                            <slot name="title" />
                        </h4>
                    </b-col>
                    <b-col>
                        <div class="text-right">
                            <slot name="extra-buttons" />
                            <b-link class="mr-4">
                                <i class="fas fa-chevron-down"></i>
                            </b-link>
                            <b-link>
                                <i class="fas fa-expand-arrows-alt"></i>
                            </b-link>
                        </div>
                    </b-col>
                </b-row>
            </b-container>

            <hr /> -->

            <!--  style="min-height: 50px; max-height: 500px; overflow-y: auto;" -->
            <div>
                <slot v-on:setfullscreenmodalwidget="$emit('setfullscreenmodalwidget', $event)" />
            </div>
        </b-card-text>
    </b-card>
</template>

<script>
import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "BaseSimWidget",
    mixins: [WidgetMixin],
    components: {},
    props: {
        fullScreen: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        return {
            show: true,
            fullWidth: false,
            title: "Base Title",
        };
    },
    computed: {},
    created: function () {},
    mounted: function () {},
    methods: {},
};
</script>
