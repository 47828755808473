<template>
    <div>
        <h2 class="text-right">
            <b-badge>
                {{ formBadge }}
            </b-badge>
        </h2>
        <hr>
    </div>
</template>

<script>

export default {
    name: "FormAction",
    mixins: [],
    components: {},
    props: {
        id: {
            type: String,
            required: false,
            default: null,
        },
        copy: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        return {};
    },
    computed: {
        formBadge() {
            if(this.copy) {
                return "Duplicate";
            } else if(this.id) {
                return "Edit";
            } else {
                return "New";
            }
        }},
};
</script>
