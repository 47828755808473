<template>
    <div style="height: 430px; background-color: #ccc;">
        <div style="height: 400px;" :ref="mapRef"></div>

        <div>
            Latitude: <span class="tw-text-gray-400">{{ item.latitude }}</span>, Longitude: <span class="tw-text-gray-400">{{ item.longitude }}</span> <br>
        </div>
    </div>
</template>

<script>

import mapboxgl from "mapbox-gl";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "MapPreviewComponent",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        item: {
            type: Object,
            required: true,
        },
        canEdit: {
            type: Boolean,
            required: false,
            default: false,
        },
        exercise: {
            type: Object,
            required: false,
            default: Object
        },
        icon: {
            type: String,
            required: false,
            default: `url(${location.origin}/simeoc/simeoc-map-icons/black.png)`
        }
    },
    data: function () {
        return {
            mapRef: "",
            map: null,
            marker: null,
            initialLocation: [-1.219482, 47.41322],
            bounds: [
                [47.9, -2],
                [47.0, -0.5],
            ],
        };
    },
    watch: {
        item: function (val) {
            const that = this;
            if (that.map && val.latitude && val.longitude) {
                that.initialLocation = [val.longitude, val.latitude];
                that.addMarker([val.longitude, val.latitude]);
            }
        },
        icon: function (val) {
            const that = this;
            if (that.map && that.marker && val) {
                that.setMarkerIcon(val);
            }
        }
    },
    created: function () {
        this.mapRef = `preview-${Math.random()}`;
    },
    mounted: function () {
        const that = this;

        // Set the defaults.
        if (that.item.longitude && that.item.latitude) {
            that.initialLocation = [that.item.longitude, that.item.latitude];
        } else if(that.exercise._id) {
            that.initialLocation = [that.exercise.longitude, that.exercise.latitude];
        }

        const map = new mapboxgl.Map({
            container: that.$refs[this.mapRef],
            style: "mapbox://styles/mapbox/streets-v12", // Replace with your preferred map style
            center: that.initialLocation,
            zoom: that.exercise.zoom ? Math.ceil(that.exercise.zoom) : 10,
            boxZoom: true
        });

        that.map = map;

        if(that.item.longitude && that.item.latitude) {
            that.addMarker([that.item.longitude, that.item.latitude]);
        }

        that.map.on("click", function (e) {
            if(that.canEdit) {
                if(that.marker) {
                    that.marker.setLngLat(e.lngLat);
                } else {
                    that.addMarker(e.lngLat);
                }

                that.$emit("update:LatLng", e.lngLat);
            }
        });

        // Set the div width.
        that.$refs[this.mapRef].style.width = "100%";
    },
    methods: {
        addMarker(lngLat) {
            const that = this;

            if(that.marker) {
                that.marker.remove();
            }

            const el = document.createElement('div');
            el.className = 'marker';

            if(that.item.icon) {
                el.style.backgroundImage = `url(${location.origin}/${that.item.icon})`;
            } else if(that.icon) {
                el.style.backgroundImage = `url(${location.origin}/${that.icon})`;
            } else {
                el.style.backgroundImage = `url(${location.origin}/simeoc/simeoc-map-icons/black.png)`;
            }

            el.style.width = `50px`;
            el.style.height = `50px`;
            el.style.backgroundSize = '100%';
            el.style.backgroundRepeat = 'no-repeat';

            that.marker = new mapboxgl.Marker(el).setLngLat(lngLat).addTo(that.map);

            // Fly to marker.
            that.map.flyTo({
                center: lngLat,
                essential: true
            });
        },
        setMarkerIcon(icon) {
            const that = this;
            if(that.marker) {
                that.marker.getElement().style.backgroundImage = `url(${location.origin}/${icon})`;
            }
        }
    },
};
</script>
