<template>
    <b-card class="my-2" v-if="isItemVisible(exercise, $store.state.sim.role, item)">
        <b-card-title>
            {{ item.name }}

            <small>
                <i
                    v-if="showNewForm && isItemActionVisible(exercise, $store.state.sim.role, item, widgets.statusBoard.slug)"
                    class="fas fa-plus float-right tw-ml-3 tw-cursor-pointer"
                    @click="editStatus({})"
                ></i>
                <i
                    class="fas fa-info float-right tw-cursor-pointer"
                    :title="item.description"
                ></i>
                <ShowSendTimeBadge
                class="float-right mr-2"
                :exercise="exercise"
                :item="item"
                />
                <b-badge variant="primary" class="mr-2 float-right">
                    {{ item.type }}
                </b-badge>
            </small>
        </b-card-title>

        <b-card-text>
            <hr>
        </b-card-text>

        <b-card-text>
            <StatusBoardCountForm
                v-if="form"
                :exercise="exercise"
                :item="editItem"
                :status-board="item"
                v-on:donestatus="doneStatus()"
            />
            <br>
            <StatusBoardCountStatus
                v-if="['percent', 'count-up', 'count-down'].indexOf(item.type) >= 0 && !form"
                :exercise="exercise"
                :item="item"
                :status-board-status-items="statusBoardStatusItems"
                v-on:editstatus="editStatus($event)"
                v-on:shownewform="handleShowNewForm()"
            />
            <StatusBoardUsedCapacity
                v-if="['out-of', 'used/capacity'].indexOf(item.type) >= 0 && !form"
                :exercise="exercise"
                :item="item"
                :status-board-status-items="statusBoardStatusItems"
                v-on:editstatus="editStatus($event)"
                v-on:shownewform="handleShowNewForm()"
            />
            <StatusBoardInfo
                v-if="['info'].indexOf(item.type) >= 0 && !form"
                :exercise="exercise"
                :item="item"
                :status-board-status-items="statusBoardStatusItems"
                v-on:editstatus="editStatus($event)"
                v-on:shownewform="handleShowNewForm()"
            />
        </b-card-text>
    </b-card>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "StatusBoardStatus",
    mixins: [SocketIOMixin],
    components: {},
    data: function () {
        return {
            form: false,
            showNewForm: true,
            editItem: {}
        };
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
        statusBoardStatusItems: {
            type: Array,
            required: true,
        }
    },
    created() {},
    methods: {
        editStatus(item) {
            this.editItem = item;
            this.form = true;
        },
        doneStatus() {
            this.editItem = {};
            this.form = false;
        },
        handleShowNewForm() {
            this.showNewForm = false;
        }
    },
};
</script>
