<template>
  <div class="mt-2">
    <div class="mb-3">
      <b-form-input
        id="inject-search"
        size="lg"
        placeholder="Search Inventory"
        v-model="keyword"
        @keyup="find()"
      ></b-form-input>
    </div>
    <sort-inject :inject-sort="sortByVal" v-on:sort="setSort($event)"></sort-inject>
    <Inventory
      v-for="item in sortedBySort"
      :key="item._id"
      :input="item"
      :exercise="exercise"
      v-on:details="$emit('details', $event)"
      v-on:edit="$emit('edit', $event)"
      v-on:duplicate="$emit('duplicate', $event)"
      v-on:addtoexercise="$emit('addtoexercise', $event)"
      view="summary"
    />
  </div>
</template>

<script>
import { HTTP } from "@/util/http-common";

import * as _ from "lodash";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "InventoryLibrary",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
  },
  data: function () {
    return {
      socketEventName: "",
      keyword: ""
    };
  },
  computed: {
    // sortedItems() {
    //   const that = this;
    //   return _.sortBy(that.items, [
    //     function (o) {
    //       return o.sendTime;
    //     },
    //   ]);
    // },
  },
  // watch: {
  //   sort: function() {
  //     this.find();
  //   }
  // },
  created() {
    const that = this;

    that.find();

    that.socketIOSubscribeLibraryHelper({
      name: `inventory`,
    });
  },
  destroyed() {
    this.unsubscribe(this.socketEventName);
  },
  mounted() {},
  methods: {
    find: async function () {
      const that = this;
      that.itemList = await that.$http.get("/inventory", {
        params: { keyword: that.keyword},
      }).then((response) => {
        return response.data;
      });
    },
  },
};
</script>
