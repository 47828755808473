<template>
    <b-card class="my-2" v-if="isItemVisible(exercise, $store.state.sim.role, item)">

      <b-card-title>
        <b-img
            v-bind="{width: 20, height: 20}"
            :src="item.icon ? item.icon : '/simeoc/simeoc-map-icons/blue.png'"
        ></b-img>

        {{ item.name }}

        <small
          class="text-muted tw-mx-1 float-right"
          v-if="(isMine(item) || isExerciseController())"
        >
          <i
            class="fas fa-edit tw-cursor-pointer tw-ml-1 tw-mr-2"
            @click="$emit('editinexercise', item)"
          ></i>

          <i
            class="fas fa-times tw-cursor-pointer"
            title="Delete"
            @click="remove(item)"
          ></i>
        </small>

        <small>
          <ShowSendTimeBadge
          class="float-right"
          :exercise="exercise"
          :item="item"
        />
        <MapCategory class="float-right mr-1" :category="item.category" />
        </small>
      </b-card-title>

      <b-card-text>
        <hr />
      </b-card-text>

      <b-card-text v-html="item.description"></b-card-text>

      <b-card-text style="height: 450px" v-if="preview">
        <MapPreview :item="item" />
      </b-card-text>

      <!-- <b-card-text>
        <ShowObject :input="item" :cols="['sendTime', 'actionTime']" />
      </b-card-text> -->

      <b-card-text>
        <b-container fluid>
          <b-row>
            <b-col cols="auto" v-if="!item.exercise">
              <b-button
                variant="outline-primary"
                @click="preview = !preview"
                block
                v-if="!preview"
              >
                Preview
              </b-button>
              <b-button
                variant="outline-primary"
                @click="preview = !preview"
                block
                v-if="preview"
              >
                Hide
              </b-button>
            </b-col>
            <b-col v-if="!exercise._id || (exercise._id && isExerciseController() && !item.exercise || exercise._id && hasPermission(widgets['social-media'].slug) && !item.exercise)">
              <ItemButtonOptions
                :widget="$options.name"
                :item="item"
                :exercise="exercise"
                v-on:details="$emit('details', $event)"
                v-on:edit="$emit('edit', $event)"
                v-on:duplicate="$emit('duplicate', $event)"
                v-on:removefromexercise="remove($event)"
                v-on:editinexercise="$emit('editinexercise', $event)"
                v-on:addtoexercise="$emit('addtoexercise', $event)"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-card-text>

      <b-card-text v-if="item.exercise" class="tw-text-right">
        <b-button
            title="Zoom to Location"
            variant="outline-secondary"
            @click="$emit('zoomtolocation', item)"
        >
            <i class="fas fa-bullseye"></i>
        </b-button>
      </b-card-text>

      
    </b-card>
</template>

<script>

import * as _ from "lodash";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "MapComponent",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    input: {
      type: Object,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
  },
  data: function() {
    return {
      preview: false,
      item: {}
    };
  },
  watch: {
    id: function(val) {
      if (val) {
        this.init();
      }
    },
    input: function(val) {
      if (val) {
        this.item = val;
      }
    },
  },
  created() {
    this.mapRef = `ref-${Math.random()}`;
  },
  mounted: function() {
    this.init();
  },
  methods: {
    async init() {
      const that = this;
      
      // If ID is set, then fetch it.
      // Otherwise, get from input.
      if(that.id) {
        const response = await that.$http.get(`/disaster-map/${this.id}`);
        that.item = response.data;
      } else {
        that.item = that.input;
      }
    },
    remove: async function() {
      const that = this;

      const data = {};

      if(that.isExerciseController()) {
        data.deletedTime = that.exercise.currentTime;
      } else if(that.isMine(that.item)) {
        data.actionTime = that.exercise.currentTime;
      }

      await that.$http.patch(`/disaster-map/${that.item._id}`, data);

      that.$notify({
          message: "Location marker removed!",
          timeout: 5000,
          type: "success",
      });
    },
  },
};
</script>
