<template>
  <span>{{formatted()}}</span>
</template>

<script>
import * as moment from "moment";

import SocketIOMixin from '@/util/socketio';
export default {
  mixins: [SocketIOMixin],
  components: {},
  props: {
    inputDate: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {},
  methods: {
    formatted() {
      if (!this.inputDate) {
        return null;
      } else {
        return moment(this.inputDate).fromNow();
      }
    },
  },
};
</script>
