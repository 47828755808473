<template>
  <div>
    <PositionLog
      v-for="item in sortedItemList"
      :key="item._id"
      :exercise="exercise"
      :input="item"
    />
  </div>
</template>

<script>
import { HTTP } from "@/util/http-common";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "PositionLogList",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      socketEventName: "",
      mainProps: {
        blank: true,
        width: 10,
        height: 10,
        class: "m1",
      },
      logs: [],
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  created: function () {},
  mounted: function () {
    const that = this;

    that.find();

    that.socketIOSubscribe({
      name: `${that.exercise._id}-position-log-list`,
    });
  },
  methods: {
    find: async function () {
      const that = this;
      const response = await that.$http.get(
        `/exercise-log?exercise=${that.exercise._id}&type=position`
      );
      that.itemList = response.data;
    },
  },
  beforeRouteLeave(to, from, next) {
    // this.sockets.unsubscribe(this.socketEventName);
  },
};
</script>
