<template>
    <span>{{ formattedDate }}</span>
</template>

<script>
import * as moment from "moment";

export default {
    name: "ShowSendTime",
    mixins: [],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: {},
        },
        item: {
            type: Object,
            required: true
        },
    },
    data: function () {
        return {};
    },
    computed: {
        formattedDate: function () {
            const that = this;
            const sendTime = (that.item.updateTime) ? that.item.updateTime : that.item.sendTime;

            if (that.exercise.exerciseDate) {
                // Over a week.
                if (sendTime > 604800) {
                    return moment(that.exercise.exerciseDate)
                        .add(that.exercise.currentTime, "seconds")
                        .format("LLLL");
                } else {
                    return moment(that.exercise.exerciseDate)
                        .add(sendTime, "seconds")
                        .from(
                            moment(that.exercise.exerciseDate).add(
                                that.exercise.currentTime,
                                "seconds"
                            )
                        );
                }
            } else {
                return that.secondsToFormattedSendTime(sendTime);
            }
        },
    },
};
</script>
