<template>
    <BaseItemWidget>
        <FormAction :id="id" :copy="copy" />
        <b-form @submit="onSubmit" @reset="onReset">
            <b-form-group
                id="forcast-group"
                label="Forcast:"
                label-for="forcast"
            >
                <b-form-select
                    id="forcast"
                    v-model="form.forecast"
                    :options="[
                        'Clear',
                        'Cloudy',
                        'Windy',
                        'Partially Cloudy',
                        'Raining',
                        'Thunder Storm',
                        'Hail',
                        'Tornado',
                        'Snowing',
                        'Ice',
                    ]"
                ></b-form-select>
            </b-form-group>

            <b-form-group
                id="description-group"
                label="Description:"
                label-for="description"
            >
                <b-form-textarea
                    id="description"
                    v-model="form.description"
                    placeholder="Enter Description"
                    rows="6"
                ></b-form-textarea>
            </b-form-group>

            <b-form-group id="severe-group" label="Severe:" label-for="severe">
                <b-form-checkbox id="severe" v-model="form.severe" class="mb-3">
                    Severe Weather
                </b-form-checkbox>
            </b-form-group>

            <div class="row">
                <div class="col">
                    <b-form-group
                        id="temperature-group"
                        label="Temperature:"
                        label-for="temperature"
                    >
                    <template #label>
                        Temperature (&deg;F):
                    </template>
                        <b-form-input
                            id="temperature"
                            v-model="form.temperature"
                            type="number"
                            required
                            placeholder="Enter Temperature"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group
                        id="windchill-group"
                        label="Windchill:"
                        label-for="windchill"
                    >
                    <template #label>
                        Windchill (&deg;F):
                    </template>
                        <b-form-input
                            id="windchill"
                            v-model="form.windChill"
                            type="number"
                            required
                            placeholder="Enter Windchill"
                        ></b-form-input>
                    </b-form-group>
                </div>
            </div>

            <b-form-group
                id="humidity-group"
                label="Humidity:"
                label-for="humidity"
            >
                <template #label>
                    Humidity (&percnt;):
                </template>
                <b-form-input
                    id="humidity"
                    v-model="form.humidity"
                    type="number"
                    required
                    placeholder="Enter Humidity"
                    min="0"
                ></b-form-input>
            </b-form-group>

            <div class="row">
                <div class="col">
                    <b-form-group
                        id="wind-speed-group"
                        label="Wind Speed:"
                        label-for="wind-speed"
                    >
                        <template #label>
                            Wind Speed (mph):
                        </template>
                        <b-form-input
                            id="wind-speed"
                            v-model="form.windSpeed"
                            type="number"
                            required
                            placeholder="Enter Wind Speed"
                            min="0"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group
                        id="wind-gusts-group"
                        label="Wind Gusts:"
                        label-for="wind-gusts"
                    >
                        <template #label>
                            Wind Gusts (mph):
                        </template>
                        <b-form-input
                            id="wind-gusts"
                            v-model="form.windGust"
                            type="number"
                            required
                            placeholder="Enter Wind Gusts"
                            min="0"
                        ></b-form-input>
                    </b-form-group>
                </div>
            </div>

            <b-form-group
                id="wind-direction-group"
                label="Wind Direction:"
                label-for="wind-direction"
            >
                <b-form-select
                    id="wind-direction"
                    v-model="form.windDirection"
                    :options="['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW']"
                ></b-form-select>
            </b-form-group>

            <b-form-group
                id="send-time-group"
                label="Send Time:"
                label-for="send-time"
            >
                <b-form-input
                    id="send-time"
                    v-model="form.formattedSendTime"
                    placeholder="now"
                    aria-describedby="send-time-input-live-help"
                ></b-form-input>
                <b-form-text id="send-time-input-live-help">
                    Default value is blank now (current time, or 0 seconds,
                    which is on exercise start). Otherwise, use the format
                    HH:MM:SS, MM:SS, SS
                </b-form-text>
            </b-form-group>

            <!-- <b-form-group id="exercise-group" v-if="exercise">
        <b-form-checkbox v-model="form.exercise" :value="exercise._id" unchecked-value="" class="mb-3">
          Add to {{ exercise.name }}
        </b-form-checkbox>
      </b-form-group>

      <div class="row">
        <div class="col">
          <b-button block variant="outline-primary" type="submit">Save</b-button>
        </div>
        <div class="col">
          <b-button block variant="outline-warning" type="reset">Clear</b-button>
        </div>
      </div> -->
            <FormButtonOptions :exercise="exercise" :id="id" :copy="copy" />
        </b-form>
    </BaseItemWidget>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "WeatherForm",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
        id: {
            type: String,
            required: false,
            default: null,
        },
        input: {
            type: Object,
            required: false,
            default: Object,
        },
        copy: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        return {
            item: null,
            form: {
                description: null,
                temperature: null,
                severe: null,
                windChill: null,
                humidity: null,
                windSpeed: null,
                windGusts: null,
                windDirection: null,
                forecast: null,
                inches: null,
                exercise: null,
            },
            formattedSendTime: "",
            formattedSendTimePlaceholder: "now",
        };
    },
    watch: {
        exercise: function (val) {
            if (val._id) {
                this.form.exercise = true;
            } else {
                this.form.exercise = false;
            }
        },
        id: function (val) {
            if (val) {
                this.init();
            } else {
                this.item = {};
                this.form = {};
            }
        },
        input: function (val) {
            this.item = this.input;
            this.form = _.extend({}, this.input);
        },
    },
    computed: {
        ...mapState("sample", ["sample"]),
    },
    created: function () {
        if (this.id) {
            this.init();
        }
    },
    mounted: function () {},
    methods: {
        init() {
            const that = this;
            that.$http.get(`/weather/${that.id}`).then((response) => {
                that.item = response.data;

                that.form = _.extend({}, that.item);
                that.form._id = that.copy ? undefined : that.form._id;
                that.form.formattedSendTime = that.secondsToFormattedSendTime(that.form.sendTime);
            });
        },
        onSubmit: function (evt) {
            evt.preventDefault();

            const that = this;
            const data = _.clone(that.form);

            data.exercise = that.exercise._id ? that.exercise._id : undefined;
            data.role = that.$store.getters['sim/getRoleID'];

            data.sendTime = that.formattedToSecondsSendTime(that.form.formattedSendTime);

            let request = null;
            let url = `/weather`;

            if (data._id) {
                request = that.$http.patch(`${url}/${data._id}`, data);
            } else {
                if(that.exercise._id) {
                    data.createdTime = that.exercise.currentTime;
                }

                request = that.$http.post(url, data);
            }

            request
                .then((response) => {
                    // If exercise, redirect to exercise builder.
                    // if (that.exercise._id) {
                    //     return that.$router.push({
                    //         name: "exercise-builder-widget",
                    //         params: {
                    //             exercise_id: that.exercise._id,
                    //             widget: "Weather",
                    //         },
                    //     });
                    // }

                    // that.$router.push({
                    //     name: "library-detail",
                    //     params: { widget: "weather", id: response.data._id },
                    // });

                    that.$bvToast.toast("Weather Saved!", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                    });

                    that.form = {};
                    that.$emit('form', response.data);

                    // that.onReset(evt);
                })
                .catch((error) => {
                    HandleErrors.HandleErrors(error);
                });
        },
        onReset: function (evt) {
            evt.preventDefault();
            this.$emit('form', (this.item) ? this.item : {});

            // const that = this;
            // if (that.exercise._id) {
            //     return that.$router.push({
            //         name: "exercise-builder-widget",
            //         params: {
            //             exercise_id: that.exercise._id,
            //             widget: "Weather",
            //         },
            //     });
            // }

            // _.each(that.form, (item) => {
            //     that.form[item] = null;
            // });
        },
    },
};
</script>
