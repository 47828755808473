<template>
  <BaseSimWidget>
    <template v-slot:title>
      Global Injects
    </template>

    

    <b-tabs v-model="tabIndex" id="" align="right" pills>
      <b-tab>
          <template #title>
            <i class="fas fa-list"></i>
          </template>

        <GlobalInjectSim v-for="item in itemList" :key="item._id" :input="item" />      
      </b-tab>

      <b-tab>
          <template #title>
            <i class="fas fa-question"></i>
          </template>

          <p>Help will be here.</p>
      </b-tab>
    </b-tabs>

  </BaseSimWidget>
</template>

<script>

import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import GlobalInjectSim from "@/components/SimEOC/global-inject/GlobalInjectSim.vue";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "GlobalInjectSimList",
  mixins: [WidgetMixin],
  components: {
      GlobalInjectSim
  },
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
    fullScreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function() {
    return {
        tabIndex: 0
    };
  },
  computed: {},
  created: function() {
    const that = this;

    that.find();
  },
  mounted: function() {},
  methods: {
    find: function() {
      const that = this;
      that.$http.get(`/inject?exercise=${that.exercise._id}`)
        .then((response) => {
          that.itemList = response.data;
        })
        .catch((error) => {
          HandleErrors.HandleErrors(error);
        });
    },
  },
};
</script>
