<template>
  <BaseSimWidget id="taskWidget">
    <template v-slot:title>
      <i class="fas fa-tasks"></i>
      Tasks
    </template>

    <b-tabs v-model="tabIndex" id="" align="right" pills>
      <template #tabs-end v-if="!fullScreen">
        <!-- <b-nav-item>
          <i class="fas fa-chevron-down"></i>
        </b-nav-item> -->
        <b-nav-item>
          <i class="fas fa-expand-arrows-alt" @click.prevent="goFullScreen()"></i>
        </b-nav-item>
      </template>

      <b-tab>
          <template #title>
            <i class="fas fa-inbox"></i>
          </template>

          <TaskList
            inbox="in"
            :exercise="exercise"
            v-on:editinexercise="editInExercise($event)"
            v-on:selecttask="selectTask($event)"
        />
      </b-tab>
      <b-tab>
          <template #title>
            <i class="fas fa-paper-plane"></i>
          </template>

          <TaskList
            inbox="out"
            :exercise="exercise"
            v-on:editinexercise="editInExercise($event)"
            v-on:selecttask="selectTask($event)"
        />
      </b-tab>
      <b-tab :disabled="exercise.status != 'playing'">
          <template #title>
            <i class="fas fa-plus"></i>
          </template>

          <TaskForm
            :exercise="exercise"
            :copy="copy"
            :id="id"
            v-on:form="formFinished($event)"
        />
      </b-tab>

      <b-tab>
          <template #title>
            <i class="fas fa-question"></i>
          </template>

          <p>Help will be here.</p>
      </b-tab>

    </b-tabs>
    
  </BaseSimWidget>
</template>

<script>
import {
    HTTP
} from '@/util/http-common';
import HandleErrors from '@/util/handle-errors';

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "TaskWidget",
  mixins: [WidgetMixin],
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object
    },
    fullScreen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      show: true,
      fullWidth: false,
      title: "Sample Page",
      addTask: false,
      newTask: false,
      id: null,
      copy: false,
      tabIndex: 0
    };
  },
  computed: {
  },
  created: function() {},
  mounted: function() {
      console.log(this.$store.state.user.user)
  },
  methods: {
    formFinished: function() {
        this.id = null;
        this.copy = false;
        this.tabIndex = 1;
    },
    editInExercise: function(item) {
        this.id = item._id;
        this.copy = false;
        this.tabIndex = 2;
    },
    goFullScreen() {
      this.$emit('setfullscreenmodalwidget', 'TaskSimWidget');
    }
  }
};
</script>
