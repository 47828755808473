<template>
    <div>
        <b-form-textarea
            id="textarea"
            v-model="form.note"
            placeholder="Enter note... Press enter to post."
            rows="3"
            max-rows="6"
            @keydown.enter="post()"
        ></b-form-textarea>
    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "PositionLogForm",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            form: {
                note: "",
            },
        };
    },
    computed: {},
    created: function () {},
    mounted: function () {},
    methods: {
        post: async function () {
            const that = this;
            if (!that.form.note.trim()) {
                return;
            }

            that.form.exercise = that.exercise._id;
            that.form.role = that.$store.getters['sim/getRoleID'];
            that.form.type = "position";
            that.form.exercise = that.exercise._id;
            that.form.sendTime = that.exercise.currentTime;

            await that.$http.post("/exercise-log", that.form);

            that.form.note = "";
        },
    },
};
</script>
