import * as _ from 'lodash';

export default {
  name: "VisibleMixin",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  created: function() {},
  methods: {
    showLog(string, show = false) {
      if(show) {
        console.log(string);
      }
    },
    /**
     * View returns true/false whether a given item is visible.
     * Decide later on where to fetch the exercise and the current role.
     * Assumptions:
     * - If library widget is the parent widget, then incoming items don't have an exercise (i.e. item.exercise == null). In this case, always show the item.
     * - If list widget (i.e. items in exercise), then incoming items match the exercise (i.e. item.exercise == exercise._id).
     * - If looking at deletedTime or actionTime, use '... < deletedTime|actionTime' (i.e. don't include the upper bound). However, use '<=' for lower bound.
     * 
     * @param {*} item 
     */
     isItemVisible(exercise, role, item, show = false) {
      // this.showLog('isItemVisible----------------------------------------------', show);
      // this.showLog('item: ' + JSON.stringify(_.pick(item, ['_id', 'name', 'title', 'exercise', 'sendTime', 'actionTime', 'createdTime', 'deletedTime', 'roles'])), show);
      // this.showLog('exercise: ' + JSON.stringify(_.pick(exercise, ['status', 'currentTime', 'name'])), show);
      // this.showLog('role: ' + JSON.stringify(_.pick(role, ['controller', 'permissionList'])), show);

      // If exercise is not set, then we are most likely in the library view outside of an exercise. Show the item.
      if(!exercise._id || !item.exercise) {
        // console.log('isItemVisible.1. ', item._id, JSON.stringify(item));
        return true;
      }

      // Controller rules.
      if(role && role.controller) {
        // This case would only happen if exercise is being replayed. In conditions after this, we have item.createdTime <= exercise.currentTime.
        if(exercise.currentTime < item.createdTime) {
          // console.log('isItemVisible.2. ', item._id, JSON.stringify(item));
          return false;
        }

        // If item.deletedTime, make sure that createdTime <= currentTime < deletedTime.
        // If !item.deletedTime, show it. If we are here, we have item.createdTime <= exercise.currentTime, so if !deletedTime, then we show it since nothing has happened to this item.
        // Otherwise, don't show it. (i.e. if item.deletedTime < exercise.currentTime).
        if(_.isNumber(item.deletedTime) && item.createdTime <= exercise.currentTime && exercise.currentTime < item.deletedTime) {
          // console.log('isItemVisible.3. ', item._id, JSON.stringify(item));
          return true;
        } else if(!_.isNumber(item.deletedTime)) {
          if(_.isNumber(item.actionTime) && (item.sendTime <= exercise.currentTime && exercise.currentTime < item.actionTime)) {
            // console.log('isItemVisible.4.1 ', item._id, JSON.stringify(item));
            return true;
          } else if(_.isNumber(item.actionTime)) {
            // console.log('isItemVisible.4.2 ', item._id, JSON.stringify(item));
            return false;
          } else {
            // console.log('isItemVisible.4.3 ', item._id, JSON.stringify(item));
            return true;
          }
        } else {
          // console.log('isItemVisible.5. ', item._id, JSON.stringify(item));
          return false;
        }
      }

      // Trainee rules.

      // If this item has a deletedTime, then we hide it after that time. This case would only happen on replay.
      if(_.isNumber(item.deletedTime) && exercise.currentTime > item.deletedTime) {
        // console.log('isItemVisible.6. ', item._id, JSON.stringify(item));
        return false;
      }

      // Use sendTime/actionTime.
      if(_.isNumber(item.actionTime)) {
        if(item.sendTime <= exercise.currentTime && exercise.currentTime < item.actionTime) {
          // console.log('isItemVisible.7. ', item._id, JSON.stringify(item));
          return true;
        } else {
          // console.log('isItemVisible.8. ', item._id, JSON.stringify(item));
          return false;
        }
      } else {
          if(item.sendTime <= exercise.currentTime) {
            // console.log('isItemVisible.9. ', item._id, JSON.stringify(item));
              return true;
          } else {
            // console.log('isItemVisible.10. ', item._id, JSON.stringify(item));
              return false;
          }
      }
    },
    /**
     * Whether to show the item options.
     * NOTE: This is different from actionVisibility for trainee. See isActionVisible
     * @param {*} exercise 
     * @param {*} role 
     * @param {*} item 
     * @returns 
     */
     isItemButtonOptionsVisible(exercise, role, item) {
      // If exercise is not set, then we are most likely in the library view outside of an exercise. Show the buttons.
      if(!exercise._id || !item.exercise) {
        // console.log('isItemButtonOptionsVisible.1. ', item._id, JSON.stringify(item));
        return true;
      }

      if(exercise.status == 'draft' || exercise.status == 'open') {
        return true;
      }

      // Don't show the buttons at all if exercise is replay*.
      if(exercise.status == 'replay' || exercise.status == 'replay-paused') {
        // console.log('isItemButtonOptionsVisible.2. ', item._id, JSON.stringify(item));
        return false;
      }

      // If not controller role, hide them.
      // @note - Add permissions here.
      if(role && !role.controller) {
        // console.log('isItemButtonOptionsVisible.3. ', item._id, JSON.stringify(item));
        return false;
      }

      // If deletedTime has happened, don't show.
      if(_.isNumber(item.deletedTime) && item.deletedTime < exercise.currentTime) {
        // console.log('isItemButtonOptionsVisible.4. ', item._id, JSON.stringify(item));
        return false;
      }

      // If item has been sent.
      if(item.sendTime <= exercise.currentTime) {
        // console.log('isItemButtonOptionsVisible.5. ', item._id, JSON.stringify(item));
        return false;
      }

      // // Not needed, but here for testing. This would only happen on replay (covered above), or testing w/ moving time backwards.
      // // If createdTime <= sendTime.
      // // @remove
      // if(item.createdTime <= exercise.currentTime) {
      //   console.log('isItemButtonOptionsVisible.5. ', item);
      //   return false;
      // }

      // console.log('isItemButtonOptionsVisible.6. ', item._id, JSON.stringify(item));
      return true;
    },
    isItemButtonOptionsVisible2(exercise, role, item) {
      // If exercise is not set, then we are most likely in the library view outside of an exercise. Show the buttons.
      if(!exercise._id || !item.exercise) {
        // console.log('isItemButtonOptionsVisible.1. ', item._id, JSON.stringify(item));
        return true;
      }

      // Don't show the buttons at all if exercise is replay*.
      if(exercise.status == 'replay' || exercise.status == 'replay-paused') {
        // console.log('isItemButtonOptionsVisible.2. ', item._id, JSON.stringify(item));
        return false;
      }

      // If not controller role, hide them.
      // @note - Add permissions here.
      if(role && !role.controller) {
        // console.log('isItemButtonOptionsVisible.3. ', item._id, JSON.stringify(item));
        return false;
      }

      // If deletedTime has happened, don't show.
      if(_.isNumber(item.deletedTime) && item.deletedTime < exercise.currentTime) {
        // console.log('isItemButtonOptionsVisible.4. ', item._id, JSON.stringify(item));
        return false;
      }

      // // Not needed, but here for testing. This would only happen on replay (covered above), or testing w/ moving time backwards.
      // // If createdTime <= sendTime.
      // // @remove
      // if(item.createdTime <= exercise.currentTime) {
      //   console.log('isItemButtonOptionsVisible.5. ', item);
      //   return false;
      // }

      // console.log('isItemButtonOptionsVisible.6. ', item._id, JSON.stringify(item));
      return true;
    },
    /**
     * Normal trainee permissions.
     * NOTE: This assumes that the item is visible. already.
     * @param {*} exercise 
     * @param {*} role 
     * @param {*} item 
     * @returns 
     */
    isItemActionVisible(exercise, role, item, permission = "") {
      // Don't show the buttons at all if exercise is replay*. Don't show if open, paused or stopped.
      if(['open', 'replay', 'replay-paused', 'paused', 'stopped', 'archived'].indexOf(exercise.status) >= 0) {
        return false;
      }
      // if(exercise.status == 'replay' || exercise.status == 'replay-paused' || exercise.status == 'paused') {
      //   return false;
      // }

      // If controller role, hide them.
      if(role && role.controller) {
        return false;
      }

      // If user has not selected a role?
      if(!role || !role.name) {
        return false;
      }

      if(permission.trim() && !this.hasPermission(permission)) {
        return false;
      }

      return true;
    },
    isMine(item) {
      return item.role == this.$store.getters['sim/getRoleID'];
    },
    isFromMeOrToMe(item, role) {
      return item.toRole == role._id || item.fromRole == role._id;
    },
    hasPermission(permission) {
      if(!this.$store.state.sim.role._id) {
        return false;
      }

      return this.$store.state.sim.role.permissionList.indexOf(permission) >= 0;
      // return false;
    },
    isExerciseController() {
      return this.$store.state.sim.role.controller;
    },
    isDebugVisible() {
      return window.location.host == 'localhost:8080';
    },
    isExerciseNotClosed(exercise) {
      return (['draft', 'open', 'playing', 'paused'].indexOf(exercise.status) >= 0);
    },
    isExercisePlaying(exercise) {
      return (['playing', 'paused'].indexOf(exercise.status) >= 0);
    }

  },
};
