<template>
    <!-- <div>
    <b-list-group>
      <b-list-group-item v-for="item in sortedItems" :key="item._id">
        <WeatherListItem :item="item" :exercise="exercise" />
      </b-list-group-item>
    </b-list-group>
  </div> -->

    <div class="mt-2">
        <div class="mb-3">
            <b-form-input
                id="inject-search"
                size="lg"
                placeholder="Search Injects"
                v-model="keyword"
                @keyup="find()"
            ></b-form-input>
        </div>
        <sort-inject :inject-sort="sortByVal" v-on:sort="setSort($event)"></sort-inject>
        <Weather
            v-for="item in sortedBySort"
            :key="item._id"
            :input="item"
            :exercise="exercise"
            v-on:details="$emit('details', $event)"
            v-on:edit="$emit('edit', $event)"
            v-on:duplicate="$emit('duplicate', $event)"
            v-on:addtoexercise="$emit('addtoexercise', $event)"
            view="summary"
        />
    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import * as _ from "lodash";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "WeatherLibrary",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
    },
    data: function () {
        return {
            keyword: ''
        };
    },
    computed: {
        sortedItems() {
            const that = this;
            return _.sortBy(item in that.itemList, [
                function (o) {
                    return o.sendTime;
                },
            ]);
        },
    },
    created() {
        const that = this;

        // that.listenLibraryList('weather')

        that.find();

        that.socketIOSubscribeLibraryHelper({
            name: `weather`
        });
    },
    destroyed() {
        // this.unsubscribe(`${this.customer._id}-weather-library`);
    },
    mounted() {},
    methods: {
        find: function () {
            const that = this;
            that.$http.get("/weather", {params: {keyword: that.keyword}})
                .then((response) => {
                    that.itemList = response.data;
                })
                .catch((error) => {
                    HandleErrors.HandleErrors(error);
                });
        }
    },
    // beforeRouteLeave(to, from, next) {
    //     this.unsubscribe(`${this.customer._id}-weather-library`);
    // },
};
</script>
