<template>
    <!-- <card>
      <div slot="header" class="row align-items-center">
        <div class="col-8">
          <h5 class="h3 mb-0">
            <slot name="title" />
          </h5>
        </div>
        <div class="col-4 text-right">
          <b-button outline type="secondary" v-if="fullScreen">
            <i
              class="fas fa-times"
              @click="exitFullScreen"
            ></i>
          </b-button>
          <b-button outline type="secondary" v-if="!fullScreen">
            <i
              class="fas fa-expand-arrows-alt"
              @click="fullScreenWidget($options.name)"
            ></i>
          </b-button>
          <b-button link type="secondary" v-if="!fullScreen"> 
            <i
              class="fas"
              :class="{ 'fa-chevron-down': show, 'fa-chevron-right': !show }"
              @click="show = !show"
            ></i>
          </b-button>
        </div>
      </div>
      <slot />
    </card> -->

    <div class="card m-2">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-8">
          <h5 class="h3 mb-0">
            <slot name="title" />
          </h5>
        </div>
        <div class="col-4 text-right">
          <slot name="actions" />
        </div>
      </div>
    </div>
    <div class="card-body">
      <slot />
    </div>
  </div>
</template>

<script>
import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "BaseWidget",
  mixins: [WidgetMixin],
  components: {},
  props: {
    fullScreen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      show: true,
      fullWidth: false,
      title: "Base Title"
    };
  },
  computed: {},
  created: function() {},
  mounted: function() {},
  methods: {}
};
</script>
