<template>
  <div class="mt-2">
    <!-- <RoleForm :exercise="exercise" /> -->

    <!-- <ul class="list-group list-group-flush list my--3">
      <li v-for="item in itemList" :key="item._id" class="list-group-item px-0">
        <Role :item="item" :exercise="exercise" />
      </li>
    </ul> -->

    <div class="mb-3">
      <b-form-input
        id="inject-search"
        size="lg"
        placeholder="Search Role"
        v-model="keyword"
        @keyup="find()"
      ></b-form-input>
    </div>
    <sort-inject :inject-sort="sortByVal" v-on:sort="setSort($event)"></sort-inject>
    <Role
      v-for="item in sortedBySort"
      :key="item._id"
      :input="item"
      :exercise="exercise"
      v-on:details="$emit('details', $event)"
      v-on:edit="$emit('edit', $event)"
      v-on:duplicate="$emit('duplicate', $event)"
      v-on:addtoexercise="$emit('addtoexercise', $event)"
      view="summary"
    />

    <!-- <b-list-group>
      <b-list-group-item v-for="item in sortedItems" :key="item._id">
        <RoleListItem :item="item" :exercise="exercise" />
      </b-list-group-item>
    </b-list-group> -->
  </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

// import SocketIO from '@/util/socketio';

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "RoleLibrary",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
  },
  data: function () {
    return {
      socketEventName: "",
      mainProps: {
        blank: true,
        width: 10,
        height: 10,
        class: "m1",
      },
      keyword: "",
    };
  },
  computed: {
    sortedItems() {
      const that = this;
      return _.sortBy(that.itemList, [
        function (o) {
          return o.name;
        },
      ]);
    },
  },
  created() {
    const that = this;

    that.find();

    that.socketIOSubscribeLibraryHelper({
      name: `role`,
    });
  },
  destroyed() {
    this.unsubscribe(`${this.customer._id}-role-library`);
  },
  mounted() {},
  methods: {
    find: async function () {
      const that = this;
      that.itemList = await that.$http.get("/role", {
        params: { keyword: that.keyword },
      }).then((response) => response.data);
    },
  },
};
</script>
