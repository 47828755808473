<template>
  <div>
    <b-card :title="item.title" class="mb-2">
        <b-card-text>
            {{item.resourceType}}
        </b-card-text>

      <template v-slot:footer>
        <ItemButtonOptions :item="item" />
      </template>
    </b-card>

  </div>
</template>

<script>
import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "InjectListItemComponent",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
    exercise: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: function () {
    return {};
  },
  computed: {},
  created: function () {},
  mounted: function () {},
  methods: {
    addToExercise: function () {},
    removeFromExercise: function () {},
    duplicate: function () {},
  },
};
</script>
