<template>
    <b-container fluid>
        <b-row no-gutters>
            <b-col>
                <b-card>
                    <b-card-text>
                        <h3>
                            {{ exercise.name }}

                            <b-link
                                class="float-right"
                                :to="{ name: 'exercise-form', params: {id: exercise._id} }"
                            >
                                <i class="fas fa-pen"></i>
                            </b-link>
                            <b-link
                                class="mr-3 float-right"
                                :to="{ name: 'exercise-builder', params: {exercise_id: exercise._id} }"
                            >
                                <i class="fas fa-tools"></i>
                            </b-link>
                            <b-link
                                class="mr-3 float-right"
                                :to="{ name: 'sim', params: {id: exercise._id} }"
                            >
                                <i class="fas fa-play"></i>
                            </b-link>
                        </h3>

                        <p>
                            <TimeSince :input-date="exercise.createdAt" />&nbsp;
                            <strong>by</strong>
                            James Smith
                        </p>
                    </b-card-text>

                    <b-card-text> </b-card-text>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="4">
                <RoleList :exercise="exercise" v-if="exercise._id" />
            </b-col>
        </b-row>

        <b-row>
            <b-col> <br /><br /><br /> </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { HTTP } from "@/util/http-common.js";
import HandleErrors from "@/util/handle-errors";

export default {
    mixins: [],
    components: {},
    data: function () {
        return {
            exercise: {},
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            const that = this;

            that.$http.get(`/exercise/${that.$route.params.id}`)
                .then((response) => {
                    that.exercise = response.data;
                    //   that.subscribe(`${that.exercise._id}-currentTime`, function(data) {
                    //     that.exercise.currentTime = data;
                    //   });
                })
                .catch((error) => {
                    HandleErrors.HandleErrors(error);
                });
        },
    },
};
</script>
