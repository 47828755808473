<template>
    <b-badge>
        <b-badge variant="info" class="tw-mr-1">
            <span v-if="role.name">{{ role.name }}</span>
        </b-badge>
        <b-badge v-if="simcell" variant="success">
            <span v-if="simcellObj.name">{{ simcellObj.name }}</span>
        </b-badge>
    </b-badge>
    
</template>

<script>

export default {
    name: "RoleInfo",
    mixins: [],
    components: {},
    props: {
        roleId: {
            type: String,
            required: true,
        },
        simcell: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data: function () {
        return {
            role: {},
            simcellObj: {},
        };
    },
    computed: {},
    created: function () {
        this.get();
    },
    mounted: function () {},
    methods: {
        get: async function () {
            const that = this;
            that.role = await that.$http.get(`/role/${that.roleId}`).then(response => response.data);

            if (that.simcell) {
                that.simcellObj = await that.$http.get(`/sim-cell/${that.role.simcell}`).then(response => response.data);
            }
        },
    },
};
</script>
