<template>
  <b-card class="my-2">
    <b-card-title>
      {{ item.title }} - {{ item.snooze}}

      <b-badge class="float-right">
        <i class="fas fa-clock"></i>
        {{ secondsToFormattedSendTime(item.sendTime) }}
      </b-badge>
    </b-card-title>

    <b-card-text class="tw-text-justify">
        {{ item.message }}
    </b-card-text>

    <b-card-text class="text-center" v-if="!snooze">
      <b-button variant="primary" class="mr-2" @click="read()">
        Read
      </b-button>
      <b-button variant="outline-warning" @click="setSnooze(5)">
        Snooze for 5 minutes
      </b-button>
      <b-button variant="outline-warning" @click="setSnooze(10)">
        Snooze for 10 minutes
      </b-button>
    </b-card-text>
  </b-card>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "GlobalInjectSim",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    input: {
      type: Object,
      required: false,
      default: null,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
  },
  data: function() {
    return {
      item: {},
      snooze: false,
    };
  },
  created() {
    if (this.id) {
      this.init();
    } else if (this.input) {
      this.item = this.input;
      this.item.snooze = false;
    }
  },
  watch: {
    id: function(val) {
      if (val) {
        this.init();
      }
    },
    input: function(val) {
      if (val) {
        this.item = val;
      }
    },
  },
  mounted: function() {},
  methods: {
    init() {
      const that = this;
      that.$http.get(`/inject/${this.id}`)
        .then((response) => {
          that.item = response.data;
          that.item.snooze = false;
        })
        .catch((error) => {
          HandleErrors.HandleErrors(error);
        });
    },
    setSnooze(time) {
        const that = this;
        that.snooze = true;

      setTimeout(() => {
          that.snooze = false;

          that.$notify({
            text: that.item.title,
            duration: 10000,
            type: "success",
          });
      }, time * 1000);
    },
    read() {
      const that = this;
      that.$http.delete(`/inject/${that.item._id}`)
        .then(() => {
          that.$notify({
            text: "Deleted.",
            duration: 10000,
            type: "success",
          });

          that.$emit("removefromexercise", that.item);
        })
        .catch((error) => {
          HandleErrors.HandleErrors(error);
        });
    },
    isRead() {
        // return this.item.roles.indexOf()
    }
  },
};
</script>
