<template>
  <b-card class="my-2" :bg-variant="item.severe ? 'danger' : ''" :text-variant="item.severe ? 'white' : ''"  v-if="item">
    <b-card-title>

      <ShowSendTimeBadge
          class="float-right"
          :exercise="exercise"
          :item="item"
        />

      <b-badge variant="info" class="float-right mr-2">
        {{ item.humidity }} <span>&percnt;</span>
      </b-badge>
      <b-badge variant="secondary" class="float-right mr-2">
        {{ item.temperature }} <span>&deg;</span> ({{item.windChill}} <span>&deg;</span>)
      </b-badge>
      <b-badge variant="dark" class="float-right mr-2">
        {{ item.windSpeed }} mph {{item.windDirection}}
      </b-badge>
      <b-badge variant="primary" class="float-right mr-2">
        {{ item.forecast }}
      </b-badge>
    </b-card-title>

    <b-card-text class="tw-text-right">
      <b-img :src="`simeoc/weather/${item.forecast.replace(' ', '')}.png`" fluid alt="Responsive image"></b-img>
    </b-card-text>

    <b-card-text class="tw-pt-2">
      {{ item.description }}
    </b-card-text>

  </b-card>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import * as _ from 'lodash';

// https://www.iconfinder.com/search?q=snow
import SocketIOMixin from '@/util/socketio';
export default {
  name: "WeatherCurrent",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
  },
  data: function() {
    return {
    };
  },
  computed: {
    item: function() {
        const that = this;

        // Return the most recent one that can be shown.
        if(that.sortedItemList.length) {
          return _.find(that.sortedItemList, (it) => {
            return it.sendTime < that.exercise.currentTime && !it.actionTime;
          });
        }


        return null;
      }
  },
  created() {},
  watch: {},
  mounted: function() {
    const that = this;

    that.find();

    that.socketIOSubscribe({
        name: `${that.exercise._id}-weather-current-list`
    });
  },
  methods: {
    async find() {
      const that = this;
      that.itemList = await that.$http.get(`/weather?exercise=${that.exercise._id}`).then((response) => response.data);
    }
  },
};
</script>
