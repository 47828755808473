<template>
  <div>
    <b-form-textarea
      id="textarea"
      v-model="form.note"
      placeholder="Enter note... Press enter to post."
      rows="3"
      max-rows="6"
      @keydown.enter="post()"
    ></b-form-textarea>

    <!-- <div class="tw-text-right tw-my-4">
      <b-btn variant="primary" @click="post()"> Post </b-btn>
    </div> -->
  </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "ScribeForm",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      form: {
        note: "",
      },
    };
  },
  computed: {},
  created: function () {},
  mounted: function () {},
  methods: {
    post: async function () {
      const that = this;
      if (!that.form.note.trim()) {
        return;
      }

      // Update the old one.
      if (that.form._id) {
        await that.$http.put(`/scribe/${that.form._id}`, {
          actionTime: that.exercise.currentTime,
          status: "updated",
        });
      }

      that.form.exercise = that.exercise._id;
      that.form.sendTime = that.exercise.currentTime;

      await that.$http.post("/scribe", that.form);

      that.form.note = "";
    },
  },
};
</script>
