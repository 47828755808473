<template>
  <div>
    <Scribe
      v-for="item in sortedItemList"
      :key="item._id"
      :exercise="exercise"
      :input="item"
    />
  </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "ScribeList",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: true,
      default: Object,
    },
  },
  data: function() {
    return {};
  },
  computed: {
  },
  created: function() {},
  mounted: function() {
    const that = this;

    that.find();

    that.socketIOSubscribe({
      name: `${that.exercise._id}-scribe-list`,
    });
  },
  methods: {
    bgVariant: function(input) {
      return "default";
    },
    textVariant: function(input) {
      return "default";
    },
    find: async function() {
      const that = this;

      that.itemList = await that.$http.get(`/scribe`, {
        params: { exercise: that.exercise._id },
      }).then((response) => response.data);
    },
    patch: async function(item) {
      await that.$http.patch(`/scribe/${item._id}`);
    },
  },
};
</script>
