<template>
  <span>{{ formattedDate }}</span>
</template>

<script>

import { mapState } from "vuex";

import * as _ from "lodash";
import * as moment from "moment";

export default {
  name: "FormatDateTimeComponent",
  mixins: [],
  components: {},
  props: {
    exerciseDate: {
      type: String,
      // required: true
      default: "2020-05-25 13:55:00",
    },
    exercise: {
      type: Object,
      required: true,
    },
    sendTime: {
      type: Number,
      default: 0,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapState("sample", ["sample"]),
    formattedDate: function () {
      const that = this;

      // Over a week.
      if (that.sendTime > 604800) {
        return moment(that.exerciseDate)
          .add(that.exercise.currentTime, "seconds")
          .format("LLLL");
      } else {
        return moment(that.exerciseDate)
          .add(that.sendTime, "seconds")
          .from(
            moment(that.exerciseDate).add(that.exercise.currentTime, "seconds")
          );
      }
    },
  },
  created: function () {},
  mounted: function () {},
  methods: {},
};
</script>
