<template>
    <div class="mt-2">
        <Map
            v-for="item in sortedItemList2"
            :key="item._id"
            :input="item"
            :exercise="exercise"
            v-on:removefromexercise="$emit('removefromexercise', $event)"
            v-on:editinexercise="$emit('editinexercise', $event)"
            v-on:zoomtolocation="$emit('zoomtolocation', $event)"
        />
    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import * as _ from "lodash";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "MapLibrary",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
    },
    data: function () {
        return {
            socketEventName: ""
        };
    },
    computed: {
        sortedItemList2() {
        const that = this;
        return _.filter(that.sortedItemList, (o) => {
          return that.isItemVisible(that.exercise, that.$store.state.sim.role, o);
        });
      },
    },
    created() {
        const that = this;

        // that.listenExerciseList(that.exercise, 'map');

        that.find();

        that.socketIOSubscribe({
            name: `${that.exercise._id}-map-list`
        });

        // that.socketEventName = `${that.exercise._id}-map-list`;
        // that.subscribe(that.socketEventName, function (data) {
        //     let index = _.findIndex(that.items, function (o) {
        //         return o._id == data._id;
        //     });

        //     if (index >= 0) {
        //         that.items[index] = data;
        //     } else {
        //         that.items.push(data);
        //     }
        // });

        that.subscribe(`${that.exercise._id}-remove-map-list`, function (data) {
            that.find();
        });
    },
    mounted() {},
    methods: {
        find: async function () {
            const that = this;
            that.itemList = await that.$http.get(`/disaster-map?exercise=${that.exercise._id}`).then((response) => response.data);
        },
    },
    beforeRouteLeave(to, from, next) {
        // this.sockets.unsubscribe(this.socketEventName);
        // this.sockets.unsubscribe(`${this.exercise._id}-remove-map-list`);
    },
};
</script>
