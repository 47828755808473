<template>
  <BaseWidget>
    <template v-slot:title>
      Broadcast Widget
    </template>

    <BroadcastForm v-if="exercise.name" :exercise="exercise" />
    <BroadcastList v-if="exercise.name" :exercise="exercise" />

    <!-- <tabs tabNavClasses="nav-pills-circle">
      <tab-pane title="Form" active>
        <template v-slot:title>
          <i class="fas fa-plus"></i>
        </template>

        <Form :exercise="exercise" />
      </tab-pane>
      <tab-pane title="Library">
        <template v-slot:title>
          <i class="fas fa-briefcase"></i>
        </template>

        <Library :exercise="exercise" />
      </tab-pane>
      <tab-pane title="List">
        <template v-slot:title>
          <i class="fas fa-list"></i>
        </template>
        
        <List :exercise="exercise" />
      </tab-pane>
      <tab-pane title="Help">
        <template v-slot:title>
          <i class="fas fa-question"></i>
        </template>
        
        <Help />
      </tab-pane>
    </tabs> -->
  </BaseWidget>
</template>

<script>
// import {
//     HTTP
// } from '@/util/http-common';
// import HandleErrors from '@/util/handle-errors';

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "Activity",
  mixins: [WidgetMixin],
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object
    },
    fullScreen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      show: true,
      fullWidth: false,
      title: "Sample Page"
    };
  },
  computed: {
    ...mapState("sample", ["sample"])
  },
  created: function() {
    //console.log(this.$options.name);
  },
  mounted: function() {},
  methods: {
    get: function() {
      // that.$http.get('/').then((response) => {
      //     let something = response.data;
      // }).catch((error) => {
      //     HandleErrors.HandleErrors(error);
      // });
    },
    find: function() {},
    patch: function() {},
    delete: function() {}
  }
};
</script>
