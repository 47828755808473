<template>
  <b-card
    :img-src="item.imageUrl"
    img-alt="Image"
    img-top
    tag="article"
    class="my-2"
    v-if="isItemVisible(exercise, $store.state.sim.role, item)"
  >
    <b-card-title>
      {{ item.title }}

      <ShowSendTimeBadge
          class="float-right"
          :exercise="exercise"
          :item="item"
        />
    </b-card-title>

    <b-card-text>
      <ShowMedia
        v-if="item.mediaLibrary"
        :mediaLibraryId="item.mediaLibrary"
        :thumbnail="false"
      />
    </b-card-text>
    <hr />

    <b-card-text>
      <blockquote class="blockquote" v-html="item.message"></blockquote>
    </b-card-text>

    <b-card-text>
      <blockquote class="blockquote" v-html="item.description"></blockquote>
    </b-card-text>

    <b-card-text>
      <b-badge class="mx-1" v-for="tag in item.tags" :key="tag">
        {{ tag }}
      </b-badge>
    </b-card-text>
    <hr />

    <b-card-text v-if="isItemButtonOptionsVisible(exercise, $store.state.sim.role, item)">
      <ItemButtonOptions
        :widget="$options.name"
        :item="item"
        :exercise="exercise"
        v-on:details="$emit('details', $event)"
        v-on:edit="$emit('edit', $event)"
        v-on:duplicate="$emit('duplicate', $event)"
        v-on:removefromexercise="remove($event)"
        v-on:editinexercise="$emit('editinexercise', $event)"
        v-on:addtoexercise="$emit('addtoexercise', $event)"
      />
    </b-card-text>
  </b-card>
</template>

<script>
import { mapState } from "vuex";
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "Broadcast",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    input: {
      type: Object,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
    inbox: {
        type: String,
        required: false,
        default: "",
        enum: ["", "in", "out"],
    },
  },
  data: function() {
    return {
      item: {},
    };
  },
  created() {
    if (this.id) {
      this.init();
    } else if (this.input) {
      this.item = this.input;
    }
  },
  watch: {
    id: function(val) {
      if (val) {
        this.init();
      }
    },
    input: function(val) {
      if (val) {
        this.item = val;
      }
    },
  },
  mounted: function() {},
  methods: {
    async init() {
      const that = this;
      that.item = await that.$http.get(`/media/${this.id}`).then((response) => response.data);
    },
    remove: async function() {
      const that = this;
      // that.$http.delete(`/media/${that.item._id}`)
      //   .then((response) => {
      //     that.$notify({
      //       text: "Deleted.",
      //       duration: 10000,
      //       type: "success",
      //     });

      //     that.$emit("removefromexercise", that.item);
      //   })
      //   .catch((error) => {
      //     HandleErrors.HandleErrors(error);
      //   });
      await that.$http.patch(`/media/${this.item._id}`, {deletedTime: this.$store.getters['sim/currentTime']});

      this.$notify({
        text: "Deleted.",
        duration: 10000,
        type: "success",
      });
    },
  },
};
</script>
