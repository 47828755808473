<template>
    <div class="text-right" v-if="item.exercise">
        <b-button variant="outline-danger" class="mx-2" @click="remove()">
            <i class="fas fa-times"></i> Delete
        </b-button>
        <b-button
            variant="outline-success"
            class="mx-2"
            :to="{
                name: 'exercise-builder-edit',
                params: { widget: widget.base, id: item._id },
            }"
        >
            <i class="fas fa-edit"></i> Edit
        </b-button>
    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

export default {
    name: "ExerciseItemButtonOptionsComponent",
    mixins: [],
    components: {},
    props: {
        item: {
            type: Object,
            required: true,
        },
        widgetSlug: {
            type: String,
            required: true
        },
    },
    data: function () {
        return {
            widget: {}
        };
    },
    computed: {},
    created: function () {},
    mounted: function () {},
    methods: {
        remove: function () {
            const that = this;

            that.widget = that.widgets[that.widgetSlug];
            let baseUrl = that.widget.slug;
            baseUrl = (that.widget.slug == "map") ? "disaster-map" : baseUrl;

            that.$http.delete(`/${baseUrl}/${that.item._id}`)
                .then((response) => {
                    that.$notify({
                        text: `${that.widget.title} deleted.`,
                        duration: 10000,
                        type: 'success'
                    });
                })
                .catch((error) => {
                    HandleErrors.HandleErrors(error);
                });
        },
    },
};
</script>
