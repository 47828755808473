<template>
  <b-container fluid class="mb-5">
    <b-container fluid class="my-2">
      <b-row>
        <b-col>
          <b-form-tags
            input-id="tags"
            v-model="tags"
            separator=" "
            size="lg"
            class="mb-2"
            placeholder="Enter new tags separated by space, comma or semicolon"
        ></b-form-tags>
        </b-col>
        <b-col cols="auto">
          <b-btn variant="primary" disabled class="tw-mx-3">
            {{endless.length}}
          </b-btn>
          <b-btn @click="find()" variant="secondary">Refresh</b-btn>
        </b-col>
      </b-row>
    </b-container>

    <div v-html="tagHTML" class="tw-bg-red-300"></div>

    <div class="tw-border-4">
      <Editor v-on:itemadded="itemAdded($event)" />
    </div>

    <div
      v-for="(myItem, index) in finalEndless"
      class="tw-bg-gray-200"
      :key="index"
    >
      <Editor
        :read-only="true"
        :inputData="myItem"
        v-on:itemdeleted="itemDeleted($event)"
      />
  </div>
  </b-container>
</template>
<script>
import { HTTP } from "@/util/http-common.js";
import HandleErrors from "@/util/handle-errors";

import * as _ from "lodash";

import Editor from "@/components/endless/EditorJS";

import {Tag} from '@/util/editorjs/tag';

export default {
  mixins: [],
  components: {
    Editor,
  },
  data: function () {
    return {
      editorOption: {
        modules: { toolbar: true },
        placeholder: "Enter media description",
      },
      form: {},
      showForm: false,
      wysiwygForm: false,
      markdownForm: false,
      endless: [],
      items: [],
      keyword: "",
      tags: [],
      validTags: [],
      simplemde: null,
      remarkable: null,
      rendered: "",
      item: null,
      editor: null,
      finalEndlessItems: [],
      tagHTML: ''
    };
  },
  watch: {
    tags() {
      this.find();
    }
  },
  computed: {
    onTagState(valid, invalid, duplicate) {
        this.validTags = valid;
        this.find();
      },
    finalEndless() {
      const that = this;

      return _.chain(that.endless).sortBy("-updatedAt").value();

      // if(that.tags.length) {
      //   return sorted.filter((item) => {
      //     // If intersection matches
      //     return _.intersection(item.tags, that.tags).length == that.tags.length;
      //   }).value();
      // } else {
      //   return sorted.value();
      // }
    },
  },
  async created() {
    this.find();
  },
  mounted() {
    // const that = this;

    // console.log('tag, tag, tag')
    // const tag = new Tag();
    // console.log(tag)
    // const rendered = tag
    //   .tag('div')
    //   .attr('id', '123')
    //   .css(['tw-m-5', 'tw-b-2', 'tw-bg-green-200'])
    //   .text('This is a div with some textddd.')
    //   .children([(new Tag()).tag('p').text('child 1'), (new Tag()).tag('p').text('child 2'), (new Tag()).tag('p').text('child 3')])
    //   .render();

    // console.log('rendered: ', rendered);
    // this.tagHTML = rendered;
  },
  methods: {
    saveQuickNote() {
      console.log(this.form.quickNote);
      console.log(this.editor.blocks.getBlocksCount());
      this.editor.blocks.insert("paragraph", { text: this.form.quickNote });
      this.form.quickNote = "";
    },
    itemAdded(item) {
      // console.log('before: ', this.endless.length);
      // this.endless.push(item);
      // this.endless.sort((it) => {
      //   return it.createdAt;
      // }).reverse();
      
      // console.log('after: ', this.endless.length);
      // this.endless.splice(0, 0, item);
      // this.$set(this.endless, 0, item);

      this.endless = [];
      setTimeout(() => {
        this.find();
      }, 500);
    },
    itemDeleted(id) {
      // const that = this;
      // const index = _.findIndex(that.endless, (it) => {
      //   return it._id = id;
      // });
      // that.endless.splice(index, 1);
      this.endless = [];
      setTimeout(() => {
        this.find();
      }, 500);
    },
    find() {
      const that = this;
      console.log('that.tags: ', that.tags)
      that.$http.get("/editorjs", {
        params: { keyword: that.keyword, tags: that.tags, id: _.random(0, 1000000) },
      }).then((response) => {
        // that.endless = _.chain(response.data).sortBy('-updatedAt').value();
        that.endless = response.data;
        // window.hljs.highlightAll();
        console.log(that.endless)
      });
    },
    saveContent() {
      if (!this.form.type) {
        this.form.type = "comment";
      }

      this.saveForm();
    },
    async saveForm() {
      const that = this;
      let re = /\#\w+/g;

      try {
        that.form.tags = [...that.form.content.match(re)];
      } catch (error) {}

      if (this.form._id) {
        await that.$http.patch(`/endless/${this.form._id}`, that.form);
      } else {
        await that.$http.post(`/endless`, that.form);
      }

      that.cancelForm();

      this.keyword = "";
      this.tags = [];
      that.find();
    },
    filterTag(tag) {
      const that = this;
      const index = _.indexOf(that.tags, tag);

      if (index >= 0) {
        that.tags = _.remove(that.tags, function (t) {
          return t !== tag;
        });
      } else {
        that.tags.push(tag);
      }

      that.find();
    },
  },
};
</script>
