<template>
    <div class="mt-2">
        <!-- <b-container fluid>
            <b-row no-gutters>
                <b-col>
                    <b-form-input
                id="inject-search"
                size="lg"
                placeholder="Search Injects"
                v-model="keyword"
                @keyup="find()"
            ></b-form-input>
            
                </b-col>
                <b-col cols="auto">
                    <b-dropdown size="lg" class="ml-1" right :text="sort">
                        <b-dropdown-item>Sor</b-dropdown-item>
                    </b-dropdown>
                </b-col>
            </b-row>
        </b-container> -->

        <div class="mb-3">
            <b-form-input
                id="inject-search"
                size="lg"
                placeholder="Search Injects"
                v-model="keyword"
                @keyup="find()"
            ></b-form-input>
        </div>
        <sort-inject :inject-sort="sortByVal" v-on:sort="setSort($event)"></sort-inject>
        <Inject
            v-for="item in sortedBySort"
            :key="item._id"
            :input="item"
            :exercise="exercise"
            v-on:details="$emit('details', $event)"
            v-on:edit="$emit('edit', $event)"
            v-on:duplicate="$emit('duplicate', $event)"
            v-on:addtoexercise="$emit('addtoexercise', $event)"
            view="summary"
        />
    </div>
</template>

<script>

import * as moment from 'moment';
import * as _ from 'lodash';

import SocketIOMixin from '@/util/socketio';
export default {
    name: "InjectLibrary",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
    },
    data: function () {
        return {
            socketEventName: "",
            keyword: ''
        };
    },
    computed: {
        // sortedItems() {
        //     const that = this;
        //     return _.sortBy(that.itemList, [
        //         function (o) {
        //             return -moment(o.createdAt).unix();
        //         },
        //     ]);
        // },
    },
    created() {},
    mounted() {
        const that = this;

        that.find();

        that.socketIOSubscribeLibraryHelper({
            name: `inject`
        });
    },
    methods: {
        find: async function () {
            const that = this;
            that.itemList = await that.$http.get(`/inject`, {params: {keyword: that.keyword}}).then((response) => response.data);
        },
    },
};
</script>
