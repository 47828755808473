export default function init ({ next, store }){
    console.log('middleware.init');
    console.log('store.stage.user', store.state.user)
    if(!store.state.user.token) {
        const token = window.localStorage.getItem('token');

        if(token) {
            store.commit('user/setToken', token);

            const user = window.localStorage.getItem('user');
            if(user) {
                store.commit('user/setUser', JSON.parse(user));
            }

            const customer = window.localStorage.getItem('customer');
            if(customer) {
                store.commit('user/setCustomer', JSON.parse(customer));
            }
        }
    }
   
    return next()
   }