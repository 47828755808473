<template>
  <div>
    <div class="row">
      <div class="col">
        <b-form-input alternative>
          <textarea
          placeholder="Significant Event Description"
            class="form-control"
            rows="3"
            v-model="form.note"
          ></textarea>
        </b-form-input>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col">
        <tags-input v-model="form.tags" placeholder="Tags"></tags-input>
      </div>
    </div> -->
    <div class="row">
      <div class="col">
        <b-button
        outline
        block
        type="default"
        @click="post()"
        >
          Save
        </b-button>
      </div>
      <div class="col">
        <b-button
        outline
        block
        type="warning"
        @click="clear()"
        >
          Clear
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "PositionLogFormComponent",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      form: {
        note: "",
        tags: []
      }
    };
  },
  computed: {},
  created: function() {},
  mounted: function() {},
  methods: {
    post: function() {
      const that = this;
      if (!that.form.note.trim()) {
        return;
      }

      that.form.exercise = that.exercise._id;
      that.form.type = 'significant-event';
      that.$http.post("/exercise-log", that.form)
        .then(response => {
          that.clear();
        })
        .catch(error => {
          HandleErrors.HandleErrors(error);
        });
    },
    clear: function() {
      that.form = {};
    }
  }
};
</script>
