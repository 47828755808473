<template>
    <span v-if="role.name">{{ role.name }}</span>
</template>

<script>

export default {
    name: "RoleInfo",
    mixins: [],
    components: {},
    props: {
        roleId: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            role: {},
        };
    },
    computed: {},
    created: function () {
        this.get();
    },
    mounted: function () {},
    methods: {
        get: function () {
            const that = this;
            that.$http.get(`/role/${that.roleId}`).then((response) => {
                that.role = response.data;
            });
        },
    },
};
</script>
