<template>
  <div class="tw-content-center">

    <b-card>
      <b-card-text class="tw-text-center">
        <b-form-group>
          <b-form-radio-group id="btn-radios-1" v-model="selected" :options="['Upload File', 'Typed Document']" buttons
            name="radios-btn-default"></b-form-radio-group>
        </b-form-group>
      </b-card-text>
      <b-card-text>
        <hr>

        <b-form @submit="onSubmit">
          <b-form-group label="File:" label-for="file" v-if="selected == 'Upload File'">
            <b-form-file id="file" v-model="form.file" :state="Boolean(form.file)"
              placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."></b-form-file>
          </b-form-group>

          <b-form-group id="mycontent-group" label="Content:" label-for="mycontent" v-if="selected == 'Typed Document'">
            <!-- <b-form-textarea
                    id="mycontent"
                    v-model="form.content"
                    placeholder="Type in contents of file"
                    rows="6"
                  ></b-form-textarea> -->
            <quill-editor id="mycontent" ref="myQuillEditor" v-model="form.content" :options="editorOption" />
          </b-form-group>

          <b-form-group id="description-group" label="Description:" label-for="description">
            <b-form-textarea id="description" v-model="form.description" placeholder="Enter File Description"
              rows="6"></b-form-textarea>
          </b-form-group>

          <div class="row">
            <div class="col">
              <b-button block variant="outline-success" type="submit">Save</b-button>
            </div>
            <div class="col">
              <b-button block variant="outline-warning" type="reset">Clear</b-button>
            </div>
            <div class="col">
              <b-button block variant="outline-primary" :to="{ name: 'media-library' }">Media Library</b-button>
            </div>
          </div>

        </b-form>

        <!-- <b-container fluid>
          <b-row>
            <b-col md="8" class="text-center">
              
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              
            </b-col>
          </b-row>
        </b-container> -->

      </b-card-text>
    </b-card>
  </div>
</template>
<script>
import { HTTP } from "@/util/http-common.js";
import HandleErrors from "@/util/handle-errors";

export default {
  mixins: [],
  components: {},
  data() {
    return {
      items: [],
      selected: 'Upload File',
      form: {
        file: null,
        description: '',
        content: ''
      },
      editorOption: {
        modules: {},
        placeholder: 'Enter media description'
      },
    };
  },
  mounted() { },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      const that = this;

      if (that.selected === 'Upload File' && !that.form.file) {
        return that.$bvModal.msgBoxOk('Please select a file!', {
          title: 'File Error',
          okVariant: 'danger',
          centered: true
        });
      } else if (that.selected === 'Typed Document' && !that.form.content) {
        return that.$bvModal.msgBoxOk('Please enter document content!', {
          title: 'Document Error',
          okVariant: 'danger',
          centered: true
        });
      }

      const fd = new FormData();
      if (that.form.file) {
        fd.append("file", this.form.file, this.form.file.name);
      }

      fd.append('content', that.form.content);
      fd.append('description', that.form.description);

      that.$http.post("/media-library", fd).then((response) => {
        if (response.data.originalName) {
          that.$notify({
            text: `File '${response.data.originalName}' uploaded!`,
            duration: 10000,
            type: "success"
          });
        } else {
          that.$notify({
            text: `Document Saved!`,
            duration: 10000,
            type: "success"
          });
        }

        that.$router.push({ name: 'media-library' });
      }).catch((error) => {
        HandleErrors.HandleErrors(error);
      });
    },

  },
};
</script>