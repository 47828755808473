<template>
    <h4>
        <b-badge :variant="variant">
            {{ status }}
        </b-badge>
    </h4>
</template>

<script>
import * as moment from "moment";

import SocketIOMixin from '@/util/socketio';
export default {
    mixins: [SocketIOMixin],
    components: {},
    props: {
        status: {
            type: String,
            required: false,
            default: "draft",
        },
    },
    computed: {
        variant() {
            if (this.status === "draft") {
                return "secondary";
            } else if (this.status === "open") {
                return "primary";
            } else if (this.status === "playing") {
                return "success";
            } else if (this.status === "paused") {
                return "warning";
            } else if (this.status === "stopped") {
                return "danger";
            } else if (this.status === "cancelled") {
                return "dark";
            } else if (this.status === "deleted") {
                return "danger";
            } else {
                return "secondary";
            }
        },
    },
};
</script>
