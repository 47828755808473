<template>
    <BaseItemWidget>
        <FormAction :id="id" :copy="copy" />
        <b-form @submit="onSubmit" @reset="onReset">
            <b-form-group id="title-group" label="Title:" label-for="title">
                <b-form-input
                    id="title"
                    v-model="form.title"
                    required
                    placeholder="Enter title"
                ></b-form-input>
            </b-form-group>

            <b-form-group
                id="message-group"
                label="Message:"
                label-for="message"
            >
                <b-form-textarea
                    id="message"
                    v-model="form.message"
                    placeholder="Enter Message"
                    rows="6"
                ></b-form-textarea>
            </b-form-group>

            <b-form-group id="role-group" label="From (optional):" label-for="role">
                <multiselect
                    v-model="form.role"
                    :multiple="false"
                    :options="roles"
                    track-by="_id"
                    label="name"
                ></multiselect>
            </b-form-group>

            <b-form-group id="roles-group" label="Position(s):" label-for="roles">
                <multiselect
                    v-model="form.roles"
                    :multiple="true"
                    :options="validRoles"
                    track-by="_id"
                    label="name"
                ></multiselect>
            </b-form-group>

            <b-form-group
                id="send-time-group"
                label="Send Time:"
                label-for="send-time"
            >
                <b-form-input
                    id="send-time"
                    v-model="form.formattedSendTime"
                    placeholder="now"
                    aria-describedby="send-time-input-live-help"
                ></b-form-input>
                <b-form-text id="send-time-input-live-help">
                    Default value is blank now (current time, or 0 seconds,
                    which is on exercise start). Otherwise, use the format
                    HH:MM:SS, MM:SS, SS
                </b-form-text>
            </b-form-group>

            <FormButtonOptions :exercise="exercise" :id="id" :copy="copy" />
        </b-form>
    </BaseItemWidget>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import * as _ from "lodash";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "InjectForm",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
        id: {
            type: String,
            required: false,
            default: null,
        },
        input: {
            type: Object,
            required: false,
            default: Object,
        },
        copy: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data: function () {
        return {
            item: null,
            form: {
                title: "",
                message: "",
                roles: [],
                exercise: false,
            },
            formattedSendTime: "",
            formattedSendTimePlaceholder: "now",
            roles: [],
        };
    },
    watch: {
        // exercise: function (val) {
        //     if (val._id) {
        //         this.form.exercise = true;
        //         this.find();
        //     } else {
        //         this.form.exercise = false;
        //     }
        // },
        id: function (val) {
            if (val) {
                this.init();
            } else {
                this.item = {};
                this.form = {};
            }
        },
        input: function (val) {
            // if (val._id) {
            //     this.item = this.input;
            //     this.form = _.extend({}, this.input);
            // }
            this.item = this.input;
            this.form = _.extend({}, this.input);
        },
    },
    computed: {
        ...mapState("sample", ["sample"]),
        validRoles: function() {
            return this.roles.filter((item) => {
                return !item.controller && !item.actionTime;
            });
        }
    },
    created: function () {
        //console.log(this.$options.name);
        this.find();

        if (this.id) {
            this.init();
        } else if(this.input) {
            this.item = this.input;
            this.form = _.extend({}, this.input);
        }
    },
    mounted: function () {},
    methods: {
        init() {
            const that = this;
            that.$http.get(`/inject/${that.id}`)
                .then((response) => {
                    that.item = response.data;

                    let form = _.extend({}, that.item);
                    form._id = that.copy ? undefined : form._id;
                    form.formattedSendTime = that.secondsToFormattedSendTime(form.sendTime);
                    form.roles = that.idsToObject(form.roles, that.roles);

                    that.form = form;
                })
                .catch((error) => {
                    HandleErrors.HandleErrors(error);
                });
        },
        async find() {
            const that = this;
            let url = `/role`;

            if (that.exercise._id) {
                url = `/role?exercise=${that.exercise._id}`;
            }

            that.roles = await that.$http.get(url).then((response) => {return response.data});
            // that.form.roles = 

            // that.$http.get(url)
            //     .then((response) => {
            //         that.roles = response.data;
            //         that.form.roles = that.idsToObject(that.form.roles, that.roles);
            //     })
            //     .catch((error) => {
            //         HandleErrors.HandleErrors(error);
            //     });
        },
        onSubmit: function (evt) {
            evt.preventDefault();

            const that = this;
            const data = _.clone(that.form);

            data.exercise = that.exercise._id ? that.exercise._id : undefined;

            if(that.form.formattedSendTime) {
                data.sendTime = that.formattedToSecondsSendTime(that.form.formattedSendTime);
            } else if(that.exercise._id) {
                data.sendTime = that.exercise.currentTime;
            }

            // data.roles = that.objectToIds(data.roles);

            let request = null;
            let url = `/inject`;

            if (data._id) {
                request = that.$http.patch(`${url}/${data._id}`, data);
            } else {
                if(that.exercise._id) {
                    data.createdTime = that.exercise.currentTime;
                }

                request = that.$http.post(url, data);
            }

            request
                .then((response) => {
                    that.$bvToast.toast("Inject saved", {
                        title: "Success",
                        variant: "success",
                        solid: true,
                    });

                    that.form = {};

                    that.$emit('form', response.data);
                })
                .catch((error) => {
                    HandleErrors.HandleErrors(error);
                });
        },
        onReset: function (evt) {
            evt.preventDefault();
            const that = this;

            // if(that.item._id) {
            //     that.$emit('form', that.item);
            // } else {
            //     that.$emit('form', {});
            // }

            that.$emit('form', (that.item) ? that.item : {});
        },
    },
};
</script>
