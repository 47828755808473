<template>
    <BaseSimWidget>
        <template v-slot:title> Personal Injects </template>

        <div
            v-for="x in [1, 3]"
            :key="x"
            class="tw-mb-3 tw-pb-3 tw-border-b tw-border-gray-300"
        >
            <p class="text-center">
                Blanditiis dolorum enim vel veritatis cupiditate. Nisi
                consequatur ut et. Officia atque animi ratione soluta iure id
                aspernatur. Est quae repudiandae at inventore quibusdam aut qui.
                Qui consectetur voluptatem itaque architecto eius sit nihil
                ipsum.
            </p>

            <br />

            <div class="text-center">
                <b-button
                    variant="primary"
                    class="mr-2"
                >
                    Read
                </b-button>
                <b-button variant="outline-warning">
                    Snooze for 5 minutes
                </b-button>
            </div>
        </div>
    </BaseSimWidget>
</template>

<script>
// import {
//     HTTP
// } from '@/util/http-common';
// import HandleErrors from '@/util/handle-errors';

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "GlobalInjectSimComponent",
    mixins: [WidgetMixin],
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
        fullScreen: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        return {
            show: true,
            fullWidth: false,
            title: "Sample Page",
        };
    },
    computed: {
        ...mapState("sample", ["sample"]),
    },
    created: function () {
        //console.log(this.$options.name);
    },
    mounted: function () {},
    methods: {
        get: function () {
            // that.$http.get('/').then((response) => {
            //     let something = response.data;
            // }).catch((error) => {
            //     HandleErrors.HandleErrors(error);
            // });
        },
        find: function () {},
        patch: function () {},
        delete: function () {},
    },
};
</script>
