<template>
    <b-card class="mb-2">
        <b-card-title>
            {{ item.resource }}

            <ShowSendTimeBadge
            class="float-right"
            :exercise="exercise"
            :item="item"
            />
        </b-card-title>

        <b-card-text>
            {{ item.description }}
        </b-card-text>

        <b-card-text>
            {{ item.quantity }} {{ item.units }}

            <b-badge
                v-if="item.returnable"
                variant="primary"
                class="float-right"
                >returnable</b-badge
            >
        </b-card-text>

        <b-card-text v-if="!exercise._id || exercise._id && isExerciseController()">
            <ItemButtonOptions
                widget="Inventory"
                :item="item"
                :exercise="exercise"
            />
        </b-card-text>
    </b-card>
</template>

<script>
import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "InventoryListItemComponent",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        item: {
            type: Object,
            required: true,
        },
        exercise: {
            type: Object,
            required: false,
            default: null,
        },
    },
};
</script>
