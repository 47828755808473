<template>
    <div>
        <b-form-textarea
            id="textarea"
            v-model="form.message"
            placeholder="Enter Message... Press Enter to Send."
            rows="4"
            @keydown.enter="send()"
        ></b-form-textarea>

        <hr>

    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "ChatEntryForm",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: true,
        },
        chat: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            form: {
                message: "",
            },
        };
    },
    computed: {},
    created: function () {},
    mounted: function () {},
    methods: {
        send: async function () {
            const that = this;
            if (!that.form.message.trim()) {
                return;
            }

            that.form.exercise = that.exercise._id;
            that.form.chat = that.chat;
            that.form.sendTime = that.exercise.currentTime;
            that.form.role = that.$store.getters['sim/getRoleID'];

            await that.$http.post("/chat-entries", that.form);
            that.form.message = "";
        },
    },
};
</script>
