<template>
  <BaseSimWidget id="inventoryWidget" v-if="isExerciseController() || hasPermission(widgets.inventory.slug)">
    <template v-slot:title>
      <i class="fas fa-boxes"></i>
      Inventory
    </template>

    <b-tabs v-model="tabIndex" id="" align="right" pills>
      <template #tabs-end v-if="!fullScreen">
        <!-- <b-nav-item>
          <i class="fas fa-chevron-down"></i>
        </b-nav-item> -->
        <b-nav-item>
          <i class="fas fa-expand-arrows-alt" @click.prevent="goFullScreen()"></i>
        </b-nav-item>
      </template>

      <b-tab active>
        <template #title>
          <i class="fas fa-list"></i>
        </template>

        <InventoryList
          v-if="exercise._id"
          :exercise="exercise"
          v-on:editinexercise="editInExercise($event)"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <i class="fas fa-briefcase"></i>
        </template>

        <InventoryLibrary
          :exercise="exercise"
          v-on:addtoexercise="addItemToExercise($event)"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <i class="fas fa-plus"></i>
        </template>

        <InventoryForm
          :exercise="exercise"
          :copy="copy"
          :id="id"
          v-on:form="formFinished($event)"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <i class="fas fa-question"></i>
        </template>

        <p>Help will be here.</p>
      </b-tab>

    </b-tabs>
  </BaseSimWidget>
</template>

<script>
// import {
//     HTTP
// } from '@/util/http-common';
// import HandleErrors from '@/util/handle-errors';

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "InventorySimWidget",
  mixins: [WidgetMixin],
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
    fullScreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      show: true,
      fullWidth: false,
      title: "Sample Page",
      id: null,
      copy: false,
      tabIndex: 0
    };
  },
  computed: {
    ...mapState("sample", ["sample"]),
  },
  created: function () {
    //console.log(this.$options.name);
  },
  mounted: function () {},
  methods: {
    get: function () {
      // that.$http.get('/').then((response) => {
      //     let something = response.data;
      // }).catch((error) => {
      //     HandleErrors.HandleErrors(error);
      // });
    },
    addItemToExercise: function (item) {
      this.id = item._id;
      this.copy = true;
      this.tabIndex = 2;
    },
    formFinished: function () {
      this.id = null;
      this.copy = false;
      this.tabIndex = 0;
    },
    editInExercise: function(item) {
        this.id = item._id;
        this.copy = false;
        this.tabIndex = 2;
    },
    patch: function () {},
    delete: function () {},
    goFullScreen() {
      this.$emit('setfullscreenmodalwidget', 'InventorySimWidget');
    }
  },
};
</script>
