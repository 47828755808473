// import { _ } from "core-js";

// import * as _ from 'lodash';

export default {
  name: "WidgetMixin",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      widgets: {
        activity: {
          slug: "activity",
          library: false,
          title: "Activity List",
          base: "Activity",
          baseUrl: '/activity'
        },
        broadcast: {
          slug: "broadcast",
          library: true,
          title: "Broadcast",
          base: "Broadcast",
          baseUrl: '/broadcast?type=broadcast'
        },
        control: {
          slug: "control",
          library: false,
          title: "Control",
          base: "Control",
          baseUrl: '/exercise'
        },
        // "global-inject": {
        //   slug: "global-inject",
        //   library: false,
        //   title: "Global Injects",
        //   base: "GlobalInject",
        //   baseUrl: '/global-inject'
        // },
        inject: {
          slug: "inject",
          library: true,
          title: "Injects",
          base: "Inject",
          baseUrl: '/inject'
        },
        inventory: {
          slug: "inventory",
          library: true,
          title: "Inventory",
          base: "Inventory",
          baseUrl: '/inventory'
        },
        service: {
          slug: "service",
          library: true,
          title: "Service",
          base: "Service",
          baseUrl: '/service'
        },
        disasterMap: {
          slug: "disaster-map",
          library: true,
          title: "Map",
          base: "Map",
          baseUrl: '/disaster-map'
        },
        news: {
          slug: "news",
          library: false,
          title: "News",
          base: "News",
          baseUrl: '/news'
        },
        // "personal-inject": {
        //   slug: "personal-inject",
        //   library: false,
        //   title: "Personal Injects",
        //   base: "PersonalInject",
        //   baseUrl: '/personal-inject'
        // },
        positionLog: {
          slug: "position-log",
          library: false,
          title: "Position Log",
          base: "PositionLog",
          baseUrl: '/position-log'
        },
        resourceRequest: {
          slug: "resource-request",
          library: false,
          title: "Resource Requests",
          base: "ResourceRequest",
          baseUrl: '/resource-request'
        },
        resourceRequested: {
          slug: "resource-requested",
          permission: 'logistics',
          library: false,
          title: "Resource Requested",
          base: "ResourceRequested",
          baseUrl: '/resource-request'
        },
        role: {
          slug: "role",
          library: true,
          title: "Roles",
          base: "Role",
          baseUrl: '/role'
        },
        scribe: {
          slug: "scribe",
          library: false,
          title: "Notes",
          base: "Scribe",
          baseUrl: '/scribe'
        },
        // "significant-event": {
        //   slug: "significant-event",
        //   library: false,
        //   title: "Significant Events",
        //   base: "SignificantEvent",
        //   baseUrl: '/significant-event'
        // },
        simcell: {
          slug: "simcell",
          library: true,
          title: "Simcell",
          base: "Simcell",
          baseUrl: '/simcell'
        },
        // socialMedia: {
        //   slug: "social-media",
        //   library: true,
        //   title: "Social Media",
        //   base: "SocialMedia",
        //   baseUrl: '/broadcast?type=social-media'
        // },
        "social-media": {
          slug: "social-media",
          library: true,
          title: "Social Media",
          base: "SocialMedia",
          baseUrl: '/broadcast?type=social-media'
        },
        spreadsheet: {
          slug: "spreadsheet",
          library: true,
          title: "Spreadsheet",
          base: "Spreadsheet",
          baseUrl: '/spreadsheet'
        },
        statusBoard: {
          slug: "status-board",
          library: true,
          title: "Status Board",
          base: "StatusBoard",
          baseUrl: '/status-board'
        },
        task: {
          slug: "task",
          library: false,
          title: "Tasks",
          base: "Task",
          baseUrl: '/task'
        },
        weather: {
          slug: "weather",
          library: true,
          title: "Weather",
          base: "Weather",
          baseUrl: '/weather'
        },
      },
    };
  },
  computed: {},
  created: function() {},
  methods: {
      getWidget(slug) {
          return this.widgets[slug];
      }
  },
};
