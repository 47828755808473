<template>
  <div>
    <div class="tw-text-right tw-mt-5">
      <b-button :to="{ name: 'media-library-upload' }">Upload Media</b-button>
    </div>

    <hr />

    <b-container fluid class="tw-border tw-my-3 tw-p-3 tw-rounded-lg">
      <b-row>
        <b-col>
          <h2>{{ media.originalName }}</h2>
          <p class="text-muted font-italic">
            <TimeSince :input-date="media.createdAt" />
          </p>
          <!-- <p class="text-justify">
            {{ media.description }}
          </p> -->

          <ShowMedia v-if="media._id" :media="media" :thumbnail="false" />

          <hr>

          <b-card bg-variant="light">
            <b-card-text>
              {{ media.description }}
            </b-card-text>
          </b-card>

          <div class="tw-text-right tw-my-5">
            <b-button variant="danger" @click="updateStatus()">
            Archive
          </b-button>
          <b-button class="tw-mx-2" variant="secondary" @click="$router.go(-1)">
            Back To Library
          </b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <!-- <div style="max-height: 50%; max-width: 30%;">
        
    </div> -->
  </div>
</template>
<script>
import { HTTP } from "@/util/http-common.js";
import HandleErrors from "@/util/handle-errors";

export default {
  mixins: [],
  components: {},
  data() {
    return {
      media: {},
      mainProps: {
        center: true,
        fluid: true,
        blank: true,
        rounded: true,
        thumbnail: true,
        blankColor: "#bbb",
        width: 250,
        height: 250,
        class: "my-5",
      },
    };
  },
  created() {
    this.get();
  },
  mounted() {},
  methods: {
    async get() {
      const that = this;

      that.media = await that.$http.get(
        `/media-library/${that.$route.params.id}`
      ).then((response) => response.data);
    },
    getImageUrl(item) {
      const { width, height } = this.mainProps;
      let extention = item.name.split(".")[1];

      if (item.thumbnail) {
        return `${process.env.VUE_APP_AXIOS_BASE_URL}/uploads/${item.thumbnail}`;
      } else if (!item.name) {
        return `https://via.placeholder.com/${width}x${height}.png?text=Document`;
      } else {
        return `https://via.placeholder.com/${width}x${height}.png?text=${extention}`;
      }
    },
    async updateStatus() {
      const that = this;

      if (!confirm("Are you sure you want to archive media item?")) {
        return;
      }

      await that.$http.patch(`/media-library/${that.media._id}`, {
        status: "deleted",
      });
    },
  },
};
</script>
