<template>
    <b-badge :variant="variant" class="float-right mr-1">
        {{ category }}
    </b-badge>
</template>

<script>
import SocketIOMixin from '@/util/socketio';
export default {
    name: "MapCategoryComponent",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        category: {
            type: String,
            required: false,
            default: "info",
        },
    },
    computed: {
        variant() {
            if (this.category == "information") {
                return "info";
            } else if (this.category == "warning") {
                return "warning";
            } else if (this.category == "danger") {
                return "danger";
            } else {
                return "primary";
            }
        },
    },
};
</script>
