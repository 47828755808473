import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import landingRoutes from './landingRoutes';

import store from '@/store/store';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  // routes: process.env.VUE_APP_LANDING == "1" ? landingRoutes : routes, // short for routes: routes
  routes: routes,
  linkActiveClass: 'active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  to.matched.some(async (record) => {
    if (record.meta.middleware) {
      const context = {
        to,
        from,
        next,
        store
      }

      await record.meta.middleware[0]({ ...context });
    }
  })

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['user/isAuthenticated']) {
      next();
    } else {
      next({
        name: 'Login',
        query: {
          next: to.fullPath
        }
      });
    }
  } else {
    next();
  }
  next();
});

export default router;
