<template>
  <b-card v-if="isItemVisible(exercise, $store.state.sim.role, item)" class="mb-2">

    <b-card-title>
      {{ item.service }}

      <ShowSendTimeBadge
          class="float-right"
          :exercise="exercise"
          :item="item"
        />
    </b-card-title>

    <b-card-text>
      {{ item.description }}
    </b-card-text>

    <b-card-text> {{ item.teams }} {{ item.units }} </b-card-text>

    <b-card-text v-if="!exercise._id || exercise._id && isExerciseController()">
      <ItemButtonOptions
        :widget="$options.name"
        :item="item"
        :exercise="exercise"
        v-on:details="$emit('details', $event)"
        v-on:edit="$emit('edit', $event)"
        v-on:duplicate="$emit('duplicate', $event)"
        v-on:removefromexercise="remove($event)"
        v-on:editinexercise="$emit('editinexercise', $event)"
        v-on:addtoexercise="$emit('addtoexercise', $event)"
      />
    </b-card-text>
  </b-card>
</template>

<script>
import { mapState } from "vuex";
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "Service",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    input: {
      type: Object,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
  },
  data: function() {
    return {
      item: {},
    };
  },
  created() {
    if (this.id) {
      this.init();
    } else if (this.input) {
      this.item = this.input;
    }
  },
  watch: {
    id: function(val) {
      if (val) {
        this.init();
      }
    },
    input: function(val) {
      if (val) {
        this.item = val;
      }
    },
  },
  mounted: function() {},
  methods: {
    init() {
      const that = this;
      that.$http.get(`/service/${this.id}`)
        .then((response) => {
          that.item = response.data;
        })
        .catch((error) => {
          HandleErrors.HandleErrors(error);
        });
    },
    remove: function() {
      const that = this;
      that.$http.delete(`/service/${that.item._id}`)
        .then((response) => {
          that.$notify({
            text: "Deleted.",
            duration: 10000,
            type: "success",
          });

          that.$emit("removefromexercise", that.item);
        })
        .catch((error) => {
          HandleErrors.HandleErrors(error);
        });
    },
  },
};
</script>
