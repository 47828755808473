<template>
  <div v-if="isItemVisible(exercise, $store.state.sim.role, item)">
    <b-card class="my-2">
      <b-card-title>
        {{ item.name }}
      </b-card-title>

      <b-card-text>
        {{ item.description }}
      </b-card-text>

      <b-card-text>
        <span
          class="badge badge-pill badge-primary tw-mx-1"
          v-for="role in item.roles"
          :key="role"
        >
          <RoleInfo :roleId="role" />
        </span>
      </b-card-text>

      <!-- <b-card-text class="small text-muted">Sent 3 mins ago</b-card-text> -->

      <b-card-text v-if="isItemButtonOptionsVisible(exercise, $store.state.sim.role, item)">
        <ItemButtonOptions
          :widget="$options.name"
          :item="item"
          :exercise="exercise"
          v-on:details="$emit('details', $event)"
          v-on:edit="$emit('edit', $event)"
          v-on:duplicate="$emit('duplicate', $event)"
          v-on:removefromexercise="remove($event)"
        v-on:editinexercise="$emit('editinexercise', $event)"
          v-on:addtoexercise="$emit('addtoexercise', $event)"
        />
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import * as _ from 'lodash';

import SocketIOMixin from '@/util/socketio';
export default {
  name: "Simcell",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    input: {
      type: Object,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
  },
  data: function() {
    return {
      item: {},
    };
  },
  created() {
    if (this.id) {
      this.init();
    } else {
      this.item = this.input;
    }
  },
  watch: {
    id: function(val) {
      if (val) {
        this.init();
      }
    },
    input: function(val) {
      if (val) {
        this.item = val;
      }
    },
  },
  mounted: function() {},
  methods: {
    init() {
      const that = this;
      that.$http.get(`/sim-cell/${this.id}`)
        .then((response) => {
          that.item = response.data;
        })
        .catch((error) => {
          HandleErrors.HandleErrors(error);
        });
    },
    remove: async function() {
      const that = this;

      await that.$http.patch(`/sim-cell/${that.item._id}`, {status: 'deleted'});

      _.each(that.item.roles, async (role) => {
        await that.$http.patch(`/role/${role._id}`, {status: 'deleted'});
      });

      that.$notify({
        text: "Deleted.",
        duration: 10000,
        type: "success",
      });
    },
  },
};
</script>
