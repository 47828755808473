import SimEOCDashboardLayout from '@/layouts/SimEOCDashboardLayout.vue';
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
// import AuthLayout from '@/views/Pages/AuthLayout.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import SimLayout from '@/layouts/SimLayout.vue';
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

import initMiddleware from '@/middleware/init.js';

import simEOCRoutes from './simeoc';

import Sim from '@/views/SimEOC/Sim.vue'

// Calendar
const Calendar = () => import(/* webpackChunkName: "extra" */ '@/views/Calendar/Calendar.vue');
// Charts
const Charts = () => import(/* webpackChunkName: "dashboard" */ '@/views/Charts.vue');

// Components pages
const Buttons = () => import(/* webpackChunkName: "components" */ '@/views/Components/Buttons.vue');
const Cards = () => import(/* webpackChunkName: "components" */ '@/views/Components/Cards.vue');
const GridSystem = () => import(/* webpackChunkName: "components" */ '@/views/Components/GridSystem.vue');
const Notifications = () => import(/* webpackChunkName: "components" */ '@/views/Components/Notifications.vue');
const Icons = () => import(/* webpackChunkName: "components" */ '@/views/Components/Icons.vue');
const Typography = () => import(/* webpackChunkName: "components" */ '@/views/Components/Typography.vue');

// Dashboard pages
const SimEOCDashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/SimEOCDashboard.vue');
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard.vue');
const AlternativeDashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/AlternativeDashboard.vue');
const Widgets = () => import(/* webpackChunkName: "dashboard" */ '@/views/Widgets.vue');

// Forms pages
const FormElements = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormElements.vue');
const FormComponents = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormComponents.vue');
const FormValidation = () => import(/* webpackChunkName: "forms" */ '@/views/Forms/FormValidation.vue');

// Maps pages
const GoogleMaps = () => import(/* webpackChunkName: "extra" */ '@/views/Maps/GoogleMaps.vue');
const VectorMaps = () => import(/* webpackChunkName: "extra" */ '@/views/Maps/VectorMaps.vue');

// Pages
const User = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/UserProfile.vue');
const Pricing = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Pricing.vue');
const TimeLine = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/TimeLinePage.vue');
const Login = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Login.vue');
const Home = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Home.vue');
const Register = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Register.vue');
const Lock = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Lock.vue');

// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/RegularTables.vue');
const SortableTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/SortableTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ '@/views/Tables/PaginatedTables.vue');

let componentsMenu = {
  path: '/showcase/components',
  component: DashboardLayout,
  redirect: '/showcase/components/buttons',
  name: 'Components',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'cards',
      name: 'Cards',
      component: Cards
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }
  ]
};
let formsMenu = {
  path: '/showcase/forms',
  component: DashboardLayout,
  redirect: '/showcase/forms/elements',
  name: 'Forms',
  children: [
    {
      path: 'elements',
      name: 'Form elements',
      component:  FormElements
    },
    {
      path: 'components',
      name: 'Form components',
      component:  FormComponents
    },
    {
      path: 'validation',
      name: 'Form validation',
      component:  FormValidation
    }
  ]
};

let tablesMenu = {
  path: '/showcase/tables',
  component: DashboardLayout,
  redirect: '/showcase/table/regular',
  name: 'Tables menu',
  children: [
    {
      path: 'regular',
      name: 'Tables',
      component: RegularTables
    },
    {
      path: 'sortable',
      name: 'Sortable',
      component: SortableTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }
  ]
};

let mapsMenu = {
  path: '/showcase/maps',
  component: DashboardLayout,
  name: 'Maps',
  redirect: '/showcase/maps/google',
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'vector',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
};

let pagesMenu = {
  path: '/showcase/pages',
  component: DashboardLayout,
  name: 'Pages',
  redirect: '/showcase/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
};

let mainPages = {
  path: '/',
  component: MainLayout,
  name: 'Main',
  children: [
    {
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
        noBodyBackground: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing
    },
    {
      path: '/lock',
      name: 'Lock',
      component: Lock
    },
    { path: '*', component: NotFound }
  ]
};

const simEOCChildrenRoutes = simEOCRoutes.concat([
  {
    path: 'dashboard',
    name: 'Dashboard',
    component: SimEOCDashboard
  }
]);

const routes = [
  {
    path: '/',
    redirect: '/home',
    name: 'Home-Redirect'
  },
  {
    path: '/showcase',
    component: DashboardLayout,
    name: 'Main',
    children: [
      {
        path: '/showcase/dashboard',
        name: 'Showcase-Main',
        component: Dashboard
      },
      {
        path: '/showcase/alternative',
        name: 'Alternative',
        component: AlternativeDashboard,
        meta: {
          navbarType: 'light'
        }
      },
      {
        path: '/showcase/calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: '/showcase/charts',
        name: 'Charts',
        component: Charts
      },
      {
        path: '/showcase/widgets',
        name: 'Widgets',
        component: Widgets
      },
    ]
  },
  
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  {
    path: '/',
    component: SimEOCDashboardLayout,
    // redirect: '',
    name: 'SimEOCDashboardLayout',
    meta: {
      requiresAuth: true,
      middleware: [initMiddleware]
    },
    children: simEOCChildrenRoutes
  },
  {
    path: '/',
    component: SimLayout,
    name: 'SimLayout',
    meta: {
      requiresAuth: true,
      middleware: [initMiddleware]
    },
    children: [
      {
          path: '/sim/:id',
          name: 'sim',
          component: Sim
      }]
  },
  mainPages,
];

export default routes;
