<template>
  <div></div>
</template>

<script>
import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "InjectComponent",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    item: {
      type: Object,
      required: true
    },
    exercise: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: function() {
    return {};
  },
  computed: {},
  created: function() {},
  mounted: function() {},
  methods: {
    addToExercise: function() {

    },
    removeFromExercise: function() {

    },
    duplicate: function() {

    },

  }
};
</script>
