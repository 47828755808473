<template>
  <div>
    <b-list-group>
      <b-list-group-item v-for="item in sortedItems" :key="item._id">
        <ResourceRequestListItem :item="item" :exercise="exercise" />
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import {
    HTTP
} from '@/util/http-common';
import HandleErrors from '@/util/handle-errors';

import { mapState } from "vuex";

import * as _ from 'lodash';

import SocketIOMixin from '@/util/socketio';
export default {
  name: "ResourceRequestLibrary",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object
    }
},
  data: function() {
    return {
      items: []
    };
  },
  computed: {
    sortedItems() {
      const that = this;
      return _.sortBy(that.items, [function(o) {
        return o.sendTime;
      }]);
    }
  },
  created() {
    const that = this;

    that.find();

    that.subscribe(`${that.customer._id}-resource-request-library`, function(data) {
      let index = _.findIndex(that.items, function(o) { 
        return o._id == data._id; 
      });

      if(index >= 0) {
        that.items[index] = data;
      } else {
        that.items.push(data);
      }
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  mounted() {},
  methods: {
    find: function() {
      const that = this;
      that.$http.get('/resource-request').then((response) => {
          that.items = response.data;
      }).catch((error) => {
          HandleErrors.HandleErrors(error);
      });
    }
  }
};
</script>
