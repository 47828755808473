<template>
    <div class="mt-2">
        <ResourceRequest
            v-for="item in sortedItemList"
            :key="item._id"
            :input="item"
            :exercise="exercise"
            v-on:removefromexercise="$emit('removefromexercise', $event)"
            v-on:editinexercise="$emit('editinexercise', $event)"
        />
    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";

import * as _ from "lodash";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "ResourceRequestList",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        }
    },
    data: function () {
        return {};
    },
    computed: {},
    created() {
        const that = this;

        that.find();

        that.socketIOSubscribe({
            name: `${that.exercise._id}-resource-request-list`
        });

        that.subscribe(
            `${that.exercise._id}-remove-resource-request-list`,
            function (data) {
                that.find();
            }
        );
    },
    mounted() {},
    methods: {
        find: async function () {
            const that = this;
            that.itemList = await that.$http.get(`/resource-request`, {
                params: {
                    exercise: that.exercise._id
                },
            }).then((response) => response.data);
        }
    },
};
</script>
