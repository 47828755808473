<template>
  <div class="my-3">

    <Spreadsheet
      v-for="item in sortedItemList"
      :key="item._id"
      :input="item"
      :exercise="exercise"
      v-on:editinexercise="$emit('editinexercise', $event)"
      v-on:selectedspreadsheet="$emit('selectedspreadsheet', $event)"
    />
  </div>
</template>

<script>

import SocketIOMixin from '@/util/socketio';
export default {
  name: "SpreadsheetList",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      socketEventName: "",
    };
  },
  computed: {},
  created: function () {},
  mounted: function () {
    const that = this;

    that.find();

    that.socketIOSubscribe({
      name: `${that.exercise._id}-spreadsheet-list`,
    });

    that.subscribe(
      `${that.exercise._id}-remove-spreadsheet-list`,
      function (data) {
        that.find();
      }
    );
  },
  methods: {
    find: async function () {
      const that = this;
      that.itemList = await that.$http.get(`/spreadsheet?exercise=${that.exercise._id}`).then((response) => response.data);
      console.log(that.itemList);
    },
  },
  beforeRouteLeave(to, from, next) {
    // this.sockets.unsubscribe(this.socketEventName);
    // this.sockets.unsubscribe(`${this.exercise._id}-remove-spreadsheet-list`);
  },
};
</script>
