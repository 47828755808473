<template>
    <b-container fluid>
        <div class="tw-p-2" :class="{ 'tw-border': border }">
            <b-card>
                <slot />
            </b-card>
        </div>
    </b-container>
</template>

<script>
import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "BaseItemWidget",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        border: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        return {};
    },
    computed: {},
    created: function () {},
    mounted: function () {},
    methods: {},
};
</script>
