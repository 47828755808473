<template>
  <BaseSimWidget id="simcellWidget">
    <template v-slot:title>
      <i class="fas fa-layer-group"></i>
      Simcell
    </template>

    <b-tabs v-model="tabIndex" align="right" pills>
      <template #tabs-end v-if="!fullScreen">
        <!-- <b-nav-item>
          <i class="fas fa-chevron-down"></i>
        </b-nav-item> -->
        <b-nav-item>
          <i class="fas fa-expand-arrows-alt" @click.prevent="goFullScreen()"></i>
        </b-nav-item>
      </template>

      <b-tab title="Form" :disabled="!isExerciseController()">
        <template v-slot:title>
          <i class="fas fa-plus"></i>
        </template>
        
        <SimcellForm 
          :exercise="exercise"
          :copy="copy"
          :id="id"
          v-on:form="formFinished($event)"
        />
      </b-tab>
      <b-tab title="Library" :disabled="!isExerciseController()">
        <template v-slot:title>
          <i class="fas fa-briefcase"></i>
        </template>
        
        <SimcellLibrary
          :exercise="exercise"
          v-on:addtoexercise="addItemToExercise($event)"
        />
      </b-tab>
      <b-tab title="List">
        <template v-slot:title>
          <i class="fas fa-list"></i>
        </template>
        
        <SimcellList :exercise="exercise" />
      </b-tab>
      <b-tab title="Help">
        <template v-slot:title>
          <i class="fas fa-question"></i>
        </template>
        
        <SimcellHelp />
      </b-tab>

    </b-tabs>
  </BaseSimWidget>
</template>

<script>
// import {
//     HTTP
// } from '@/util/http-common';
// import HandleErrors from '@/util/handle-errors';

import WidgetMixin from "@/components/SimEOC/WidgetMixin.vue";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "SimcellSimWidget",
  mixins: [WidgetMixin],
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object
    },
    fullScreen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      tabIndex: 2,
      show: true,
      fullWidth: false,
      title: "Sample Page",
      id: null,
      copy: false,
    };
  },
  computed: {
    ...mapState("sample", ["sample"])
  },
  created: function() {},
  mounted: function() {},
  methods: {
    get: function() {
      // that.$http.get('/').then((response) => {
      //     let something = response.data;
      // }).catch((error) => {
      //     HandleErrors.HandleErrors(error);
      // });
    },
    find: function() {},
    patch: function() {},
    delete: function() {},
    formFinished: function() {
        this.id = null;
        this.copy = false;
        this.tabIndex = 2;
    },
    addItemToExercise: function(item) {
        this.id = item._id;
        this.copy = true;
        this.tabIndex = 0;
    },
    goFullScreen() {
      this.$emit('setfullscreenmodalwidget', 'SimcellSimWidget');
    }
  }
};
</script>
